import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    deviceData: [{ id: 'tab-0', device: 'X-Sight 5', sensor: '320', lens: '3-15X', width: 1280, height: 960 },],
    currentDevice: 'X-Sight 5',
    currentLens: '3-15X',
    currentDeviceSensor: '320',
    currentDeviceWidth: 1280,
    currentDeviceHeight: 960
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        addDeviceData: (state, deviceObj) => {
            state.deviceData = [...state.deviceData, {id: deviceObj.payload.id, device: deviceObj.payload.device, sensor: deviceObj.payload.sensor, lens: deviceObj.payload.lens, width: deviceObj.payload.width, height: deviceObj.payload.height}];
            state.currentDevice = 'X-Sight 5';
            state.currentLens = '3-15X';
            state.currentDeviceSensor = '320';
            state.currentDeviceWidth = 1280;
            state.currentDeviceHeight = 960;
        },
        deleteDeviceData: (state, id) => {
            let newDeviceData = state.deviceData.filter(el => el.id !== id.payload);
            state.deviceData = newDeviceData;
            state.currentDevice = state.deviceData[state.deviceData.length - 1].device;
            state.currentLens = state.deviceData[state.deviceData.length - 1].lens;
            state.currentDeviceSensor = state.deviceData[state.deviceData.length - 1].sensor;
            state.currentDeviceWidth = state.deviceData[state.deviceData.length - 1].width;
            state.currentDeviceHeight = state.deviceData[state.deviceData.length - 1].height;
        },
        editDeviceData: (state, deviceData) => {
            let editedDeviceArr = state.deviceData.map(deviceEl => {
                if (deviceEl.id === deviceData.payload.id) {
                    deviceEl.device = deviceData.payload.device;
                    deviceEl.sensor = deviceData.payload.sensor;
                    deviceEl.lens = deviceData.payload.lens;
                    deviceEl.width = deviceData.payload.width;
                    deviceEl.height = deviceData.payload.height;

                    return deviceEl;
                } else {
                    return deviceEl;
                }
            })
            state.deviceData = editedDeviceArr;
            state.currentDevice = deviceData.payload.device;
            state.currentLens = deviceData.payload.lens;
            state.currentDeviceSensor = deviceData.payload.sensor;
            state.currentDeviceWidth = deviceData.payload.width;
            state.currentDeviceHeight = deviceData.payload.height;
        },
        updateAfterRefrefh: (state, arr) => {
            state.deviceData = arr.payload
        }
    }
})

export const {
    addDeviceData,
    deleteDeviceData,
    editDeviceData,
    updateAfterRefrefh,
} = deviceSlice.actions

export default deviceSlice.reducer;