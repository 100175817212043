import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Return from './return';
import Devices from './Preview_devices';
import Reticles from '../tools/create_section/Reticles';
import Export from './export';
import './preview_menu.scss';

export default function Menu({ activeTab, handlerReticleRename, onExportingTool, focus, blur, isFocused }) {

  const [fileName, setName] = useState('')

  // useEffect(() => {
  //   axios.get('/preview')
  //     .then(response => {
  //       setName(response.data.fileName)
  //     });
  // }, [fileName]);

  return (
    <section className='preview_menu-section'>
      <Reticles activeTab={activeTab} handlerReticleRename={handlerReticleRename} focus={focus} blur={blur} />
      <Devices activeTab={activeTab} focus={focus} blur={blur} isFocused={isFocused} />
      <div className='preview_menu-importing'>
        <Return />
        <Export onExportingTool={onExportingTool} activeTab={activeTab} />
      </div>
    </section>
  );
}
