import { createAction } from '@reduxjs/toolkit';

export const fetchReticlesRequest = createAction('reticles/fetchReticlesRequest');
export const fetchReticlesSuccess = createAction('reticles/fetchReticlesSuccess');
export const fetchReticlesError = createAction('reticles/fetchReticlesError');

export const setIsFetchingUser = createAction('reticles/setIsFetchingUser');

export const setSkipAdd = createAction('reticles/setSkipAdd');

export const setSkipRemove = createAction('reticles/setSkipRemove');



export const fetchPublicReticlesRequest = createAction('reticles/fetchPublicReticlesRequest');
export const fetchPublicReticlesSuccess = createAction('reticles/fetchPublicReticlesSuccess');
export const fetchPublicReticlesError = createAction('reticles/fetchPublicReticlesError');

export const setIsFetchingPublic= createAction('reticles/setIsFetchingPublic');

export const setSkipAddPublic = createAction('reticles/setSkipAddPublic');

export const setSkipRemovePublic = createAction('reticles/setSkipRemovePublic');



export const fetchAdminReticlesRequest = createAction('reticles/fetchAdminReticlesRequest');
export const fetchAdminReticlesSuccess = createAction('reticles/fetchAdminReticlesSuccess');
export const fetchAdminReticlesError = createAction('reticles/fetchAdminReticlesError');

export const setIsFetchingAdmin = createAction('reticles/setIsFetchingAdmin');

export const setSkipAddAdmin = createAction('reticles/setSkipAddAdmin');

export const setSkipRemoveAdmin = createAction('reticles/setSkipRemoveAdmin');



export const fetchFolderReticlesRequest = createAction('reticles/fetchFolderReticlesRequest');
export const fetchFolderReticlesSuccess = createAction('reticles/fetchFolderReticlesSuccess');
export const fetchFolderReticlesError = createAction('reticles/fetchFolderReticlesError');

export const setIsFetchingFolderReticles = createAction('reticles/setIsFetchingFolderReticles');

export const setSkipAddFolderReticles = createAction('reticles/setSkipAddFolderReticles');

export const setSkipRemoveFolderReticles = createAction('reticles/setSkipRemoveFolderReticles');



export const addReticlesRequest = createAction('reticles/addReticlesRequest');
export const addReticlesSuccess = createAction('reticles/addReticlesSuccess');
export const addReticlesError = createAction('reticles/addReticlesError');

export const resaveReticlesRequest = createAction('reticles/resaveReticlesRequest');
export const resaveReticlesSuccess = createAction('reticles/resaveReticlesSuccess');
export const resaveReticlesError = createAction('reticles/resaveReticlesError');

export const copyReticleRequest = createAction('reticles/copyReticleRequest');
export const copyReticleSuccess = createAction('reticles/copyReticleSuccess');
export const copyReticleError = createAction('reticles/copyReticleError');

export const resaveReticleInfoRequest = createAction('reticles/resaveReticleInfoRequest');
export const resaveReticleInfoSuccess = createAction('reticles/resaveReticleInfoSuccess');
export const resaveReticleInfoError = createAction('reticles/resaveReticleInfoError');

export const resaveAdminReticleInfoRequest = createAction('reticles/resaveAdminReticleInfoRequest');
export const resaveAdminReticleInfoSuccess = createAction('reticles/resaveAdminReticleInfoSuccess');
export const resaveAdminReticleInfoError = createAction('reticles/resaveAdminsReticleInfoError');

export const deleteReticlesRequest = createAction('reticles/deleteReticlesRequest');
export const deleteReticlesSuccess = createAction('reticles/deleteReticlesSuccess');
export const deleteReticlesError = createAction('reticles/deleteReticlesError');

export const savePublicReticleRequest = createAction('reticles/savePublicReticleRequest');
export const savePublicReticleSuccess = createAction('reticles/savePublicReticleSuccess');
export const savePublicReticleError = createAction('reticles/savePublicReticleError');

export const deletePublicReticlesRequest = createAction('reticles/deletePublicReticlesRequest');
export const deletePublicReticlesSuccess = createAction('reticles/deletePublicReticlesSuccess');
export const deletePublicReticlesError = createAction('reticles/deletePublicReticlesError');

export const changeUserFilterRequest = createAction('reticles/changeUresFilterRequest');
export const changeUserFilterSuccess = createAction('reticles/changeUresFilterSuccess');
export const changeUserFilterError = createAction('reticles/changeUresFilterError');

// export const changeUserStatusRequest = createAction('reticles/changeUserStatusRequest');
// export const changeUserStatusSuccess = createAction('reticles/changeUserStatusSuccess');
// export const changeUserStatusError = createAction('reticles/changeUserStatusError');

export const changeUserStatusReticleInPublicRequest = createAction('reticles/changeUserStatusReticleInPublicRequest');
export const changeUserStatusReticleInPublicSuccess = createAction('reticles/changeUserStatusReticleInPublicSuccess');
export const changeUserStatusReticleInPublicError = createAction('reticles/changeUserStatusReticleInPublicError');

export const changeStatusRequest = createAction('reticles/changeStatusRequest');
export const changeStatusSuccess = createAction('reticles/changeStatusSuccess');
export const changeStatusError = createAction('reticles/changeStatusError');

export const changeStatusInReticleInfo = createAction('reticles/changeStatusInReticleInfo');

export const changeAdminStatusRequest = createAction('reticles/changeAdminStatusRequest');
export const changeAdminStatusSuccess = createAction('reticles/changeAdminStatusSuccess');
export const changeAdminStatusError = createAction('reticles/changeAdminStatusError');

// export const changeAdminShareStatusRequest = createAction('reticles/changeAdminShareStatusRequest');
// export const changeAdminShareStatusSuccess = createAction('reticles/changeAdminShareStatusSuccess');
// export const changeAdminShareStatusError = createAction('reticles/changeAdminShareStatusError');
export const changeAdminStatusReticleInPublicRequest = createAction('reticles/changeAdminStatusReticleInPublicRequest');
export const changeAdminStatusReticleInPublicSuccess = createAction('reticles/changeAdminStatusReticleInPublicSuccess');
export const changeAdminStatusReticleInPublicError = createAction('reticles/changeAdminStatusReticleInPublicError');


export const changeCurrentReticle = createAction('reticles/changeCurrentReticle');

export const changeCalculationRequest = createAction('reticles/changeCalculationRequest');
export const changeCalculationSuccess = createAction('reticles/changeCalculationSuccess');
export const changeCalculationError = createAction('reticles/changeCalculationError');

// export const exportSuccess = createAction('reticles/exportSuccess');
// export const exportError = createAction('reticles/exportError');

export const clearError = createAction('reticles/clearError');

export const openReticle = createAction('reticles/openReticle');

export const closeReticle = createAction('reticles/closeReticle');

export const fetchPublicFoldersRequest = createAction('reticles/fetchPublicFoldersRequest');
export const fetchPublicFoldersSuccess = createAction('reticles/fetchPublicFoldersSuccess');
export const fetchPublicFoldersError = createAction('reticles/fetchPublicFoldersError');

export const clearPublicFolders = createAction('reticles/clearPublicFolders');

export const publishReticleRequest = createAction('reticles/publishReticleRequest');
export const publishReticleSuccess = createAction('reticles/publishReticleSuccess');
export const publishReticlesError = createAction('reticles/fetchFolderReticlesError');


export const fetchShareReticleRequest = createAction('reticles/fetchShareReticleRequest');
export const fetchShareReticleSuccess = createAction('reticles/fetchShareReticleSuccess');
export const fetchShareReticleError = createAction('reticles/fetchShareReticleError');

export const fetchDownloadReticleRequest = createAction('reticles/fetchDownloadReticleRequest');
export const fetchDownloadReticleSuccess = createAction('reticles/fetchDownloadReticleSuccess');
export const fetchDownloadReticleError = createAction('reticles/fetchDownloadReticleError');

export const clearDownloadReticle = createAction('reticles/clearDownloadReticle');
