import {ReactComponent as InputIcon} from '../../../img/filterIcon.svg';
import { ReactComponent as InputCloseIcon } from '../../../img/filterCloseIcon.svg';


const Search = ({ value, onChange, focus, blur, handleClearFilter, mobileClass }) => (
    <div style={{ position: 'relative', marginLeft: 'auto', marginRight: 'auto' }} className={ mobileClass? 'mobile_class' : ''}>
    <input type="text" placeholder="Search" className='search__input' value={value} onChange={onChange} onFocus={focus} onBlur={blur}/>

        {value === '' ? null : (
            <button className='search__inputCloseIcon' onClick={() => handleClearFilter()}><InputCloseIcon/></button>
        )}

        <button className='search__inputIcon'><InputIcon/></button>
    </div>
    )

export default Search;
