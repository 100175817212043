import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tabs: [{ id: 'tab-0', name: 'My Reticle', savingId: '', svg: '', shapeList: [], isActiveTab: true, status: '', isLibOpen: false, publicId: '' }],
  reticlesID: [{id: `tab-0`, class: 'canvas'},],
  activeTabId: `tab-0`,
}

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    addTab: (state, reticalObj) => {
      let resetTabs = state.tabs.map(tab => {
        if (tab.isActiveTab) {
          tab.isActiveTab = !tab.isActiveTab
        }

        return tab;
      });
      resetTabs.push({ id: `tab-${reticalObj.payload.id}`, name: reticalObj.payload.name, savingId: reticalObj.payload.savingId, svg: reticalObj.payload.svg, isActiveTab: true, status: reticalObj.payload.status, isLibOpen: reticalObj.payload.isLibOpen, publicId: reticalObj.payload.publicId });
      state.tabs = resetTabs;
      state.activeTabId = `tab-${reticalObj.payload.id}`;
    },
    closeTab: (state, id) => {
      if (state.tabs.length > 1) {
        state.tabs = state.tabs.filter(el => el.id !== id.payload)
      }
    },
    changeReticlesID: (state, idData) => {
      switch (idData.payload.action) {
        case 'ADD':
          let newIDs = state.reticlesID.map(el => {
            el.class = 'canvas canvas-disabled'
    
            return el;
          });
    
          state.reticlesID = newIDs;
          state.reticlesID.push({ id: `tab-${idData.payload.id}`, class: 'canvas' });
          break
        case 'REMOVE':
          let resetID = state.reticlesID.filter(el => el.id !== idData.payload.id);
          let a = resetID.map(el => {
            el.class = 'canvas canvas-disabled'

            return el;
          })

          a[a.length - 1].class = 'canvas'
    
          state.reticlesID = a;
          break
        case 'SELECT':
          let IDs = state.reticlesID.map(el => {
            if (el.id === idData.payload.id) {
                el.class = 'canvas'
            } else {
                el.class = 'canvas canvas-disabled'
            }

            return el;
          })
    
          state.reticlesID = IDs;
          break
        default:
          console.log('H')
      }
    },
    addSavingID: (state, tabID) => {
      state.tabs = state.tabs.map(tab => {
        if (tab.id === tabID.payload.id) {
          tab.savingId = tabID.payload.savId
          tab.status = tabID.payload.status
          tab.publicId = tabID.payload.publicId
          return tab;
        }

        return tab;
      })
    },
    setUpdateData : (state, newData) => {
      let updatedTabs = state.tabs.map(tab => {
        if (tab.id === newData.payload.id) {
          tab.svg = newData.payload.svg
          tab.shapeList = newData.payload.shapeList

          tab.name = newData.payload.name ? newData.payload.name : tab.name
        }
        return tab;
      })
      state.tabs = updatedTabs
    },
    setNewName: (state, newName) => {
      let updatedName = state.tabs.map(tab => {
        if (tab.id === newName.payload.id) {
          tab.name = newName.payload.name
        }
        return tab;
      })
      state.tabs = updatedName
    },
    setTabsAfterReload: (state, sessionArr) => {
      state.tabs = sessionArr.payload
    },
    setTabsIDAfterReload: (state, sessionArr) => {
      state.reticlesID = sessionArr.payload
    },
    setActiveTabAfterReload: (state, tabID) => {
      state.activeTabId = tabID.payload
    },
    setOpenStatus: (state, statusObj) => {
      let changedArr = state.tabs.map(tab => {
        if (tab.id === statusObj.payload.id) {
          tab.isLibOpen = false
        }
        return tab;
      })
      state.tabs = changedArr
    },
    setShareStatus: (state, shareStatus) => {
      let newStatus = state.tabs.map( tab => {
        if (tab.id === shareStatus.payload.id) {
          tab.isShared = shareStatus.payload.status
        }
        return tab;
      })
      state.tabs = newStatus
    },
    getTabs: state => state.tabs,
    selectActiveTab: (state, dataObj) => {
      switch (dataObj.payload.action) {
        case 'SELECT':
          state.activeTabId = dataObj.payload.id
          break
          case 'REMOVE':
          let newIDs = state.reticlesID.filter(el => el.id !== dataObj.payload.id);
          state.activeTabId = newIDs[newIDs.length - 1].id
          break
        case 'ADD':
          state.activeTabId = state.reticlesID[state.reticlesID.length - 1].id
          break
        default:
          state.activeTabId = state.reticlesID[0].id
      }
    },
    incrementByAmount: (state, action) => {
      state.tabs += action.payload
    },
  },
})

export const {
  addTab,
  closeTab,
  changeReticlesID,
  selectActiveTab,
  getTabs,
  addSavingID,
  setUpdateData,
  setNewName,
  setTabsAfterReload,
  setTabsIDAfterReload,
  setActiveTabAfterReload,
  incrementByAmount,
  setOpenStatus,
  setShareStatus } = tabsSlice.actions

export default tabsSlice.reducer;
