import LibMenuSection from './LibMenuSection';
import { useSelector} from 'react-redux';

import LibParameters from './LibParameters';
import Libraries from './Libraries';
import UserAccount from '../authentication/UserAccount';
import { getAuthLoading, getIsAuthenticated } from '../../../../redux/auth/auth-selectors';
import LayoutAuth from '../authentication/LayoutAuth';



import './lib_section.scss';

const Lib_section = ({ 
    handlerActivateButton, 
    activeLink, 
    shapeData, 
    getFocalPlaneName, 
    getSelectedLayerID, 
    shapes, 
    getNewShapeName, 
    activeTabId, 
    focus, 
    blur, 
    onExportingTool, 
    showFilterModal, 
    handleToggleFilterModal, 
    openHeaderButton, 
    headerSearchValue,
    clearHeaderFilter,
    handleUseFilters,
    isUseFilters,
    onChangeHeaderSearchValue,
    layerMoveSwitcher,
    handleReticleCardOpen,
    isReticleCardOpen,
    handlerAddTab,
    deviceWidth,
    handleOpenCard,
    reticle,
    openCard,
    closeOpenCard,
    handleCloseCard,
    handleActiveLib,
    handleActiveLibWithChangeFolder,
    activeButton ,
    activeLib,
    burgerButtonName,
    handleClearActiveLib,
    handleChangeSelectedCardStatus,
    isSelectedCard,
    checkBoxChange,
    selectedReticles,
 }) => {

    const loginStatus = useSelector(getIsAuthenticated);
    const authLoading = useSelector(getAuthLoading);


    return (
        <section className='lib-section' style={{width: activeLink === '2'? '100%' : ''}}>
            <section className='menu-section-block'>
                <LibMenuSection handlerActivateButton={handlerActivateButton} activeLink={activeLink} />
            </section>
            {activeLink === '2' ? (
                <section className='libraries-block'>
                    {authLoading ? (
                        null
                    ) : (
                        <>
                            {!loginStatus ?
                                (
                                    <div className='library'>
                                        <LayoutAuth />
                                    </div>
                                )
                                : (
                                    <Libraries
                                        activeLink={activeLink}
                                        activeTabId={activeTabId}
                                        focus={focus}
                                        blur={blur}
                                        onExportingTool={onExportingTool}
                                        handlerActivateButton={handlerActivateButton}
                                        showFilterModal={showFilterModal}
                                        handleToggleFilterModal={handleToggleFilterModal}
                                        openHeaderButton={openHeaderButton}
                                        headerSearchValue={headerSearchValue}
                                        clearHeaderFilter={clearHeaderFilter}
                                        handleUseFilters={handleUseFilters}
                                        isUseFilters={isUseFilters}
                                        onChangeHeaderSearchValue={onChangeHeaderSearchValue}
                                        handleReticleCardOpen={handleReticleCardOpen}
                                        isReticleCardOpen={isReticleCardOpen}
                                        handlerAddTab={handlerAddTab}
                                        deviceWidth={deviceWidth}
                                        handleOpenCard={handleOpenCard}
                                        reticle={reticle}
                                        openCard={openCard}
                                        closeOpenCard={closeOpenCard}
                                        handleCloseCard={handleCloseCard}
                                        handleActiveLib={handleActiveLib}
                                        handleActiveLibWithChangeFolder={handleActiveLibWithChangeFolder}
                                        activeButton={activeButton}
                                        activeLib={activeLib}
                                        burgerButtonName={burgerButtonName}
                                        handleClearActiveLib={handleClearActiveLib}
                                        handleChangeSelectedCardStatus={handleChangeSelectedCardStatus}
                                        isSelectedCard={isSelectedCard}
                                        checkBoxChange={checkBoxChange}
                                        selectedReticles={selectedReticles}
                                    />
                                )}
                        </>
                    )}
                </section>
            ) : (
                <>
                    {activeLink === '1' ? (
                        <section className='lib-edit-section-block'>
                            <LibParameters
                                shapeData={shapeData}
                                shapes={shapes}
                                getFocalPlaneName={getFocalPlaneName}
                                getSelectedLayerID={getSelectedLayerID}
                                getNewShapeName={getNewShapeName}
                                focus={focus}
                                blur={blur}
                                layerMoveSwitcher={layerMoveSwitcher}
                            />
                        </section>)
                        : null}

                    {activeLink === '3' ? (
                        <section className='lib-edit-section-block'>
                            <UserAccount />
                        </section>
                    ) : null}

                </>
                )}

        </section>
    )
}

export default Lib_section;
