import React from 'react';
import Presets from './Presets'
import Fill from './Fill';
import Stroke from './Stroke';
import Axis from './Axis';
import Focal from './Focal';
import Navigation from './Navigation';
import Question from './Question';
import Version from './Version';

import './edit_section.scss';

class Edit_section extends React.Component {
    constructor(props) {
        super(props)
    }

    render () {
        return (
            <section className='edit-section'>
                <div className='edit-section_wrapper'>
                    <Presets
                        activeShapeButton={this.props.activeShapeButton}
                        shapeData={this.props.shapeData}
                        presetData={this.props.presetData}
                        handlerEditParams={this.props.handlerEditParams}
                        turnEditMode={this.props.turnEditMode}
                        focus={this.props.focus}
                        blur={this.props.blur}
                        focusedInput={this.props.focusedInput}
                        btnRightRotater={this.props.btnRightRotater}
                        btnLeftRotater={this.props.btnLeftRotater}
                    />
                    <Fill
                        shapeData={this.props.shapeData.selectedShape}
                        isAllShapesFill={this.props.shapeData.isAllShapesFill}
                        fillShapeColor={this.props.fillShapeColor}
                        fillShapeOpacity={this.props.fillShapeOpacity}
                        focus={this.props.focus}
                        blur={this.props.blur}
                        fillColor={this.props.fillColor}
                        opacity={this.props.opacity}
                        activeShapeButton={this.props.activeShapeButton}
                        selectedShapes={this.props.shapeData.selectedShapes}
                    />
                    <Stroke
                        shapeData={this.props.shapeData.selectedShape}
                        selectedShapes={this.props.shapeData.selectedShapes}
                        selectedStroke={this.props.shapeData.strokeWidth}
                        selectedStrokeColor={this.props.shapeData.prevStrokeColor}
                        setStrokeColor={this.props.setStrokeColor}
                        setStrokeWidth={this.props.setStrokeWidth}
                        focus={this.props.focus}
                        blur={this.props.blur}
                        getSelectedShapeWidth={this.props.getSelectedShapeWidth}
                        instruments={this.props.instruments}
                        activeShapeButton={this.props.activeShapeButton}
                        focusedInput={this.props.focusedInput}
                        setStrokeType={this.props.setStrokeType}
                    />
                    {/* <Axis
                        shapeData={this.props.shapeData.selectedShape}
                        axisSwitchProp={this.props.shapeData.axisSwitch}
                        activeShapeButton={this.props.activeShapeButton}
                        setAxisLine={this.props.setAxisLine}
                        switch={this.props.shapeData.axisSwitch}
                    /> */}
                    <Focal
                        getFocalPlanesData={this.props.getFocalPlanesData}
                        shapeData={this.props.shapeData}
                    />
                    <Navigation
                        shapeData={this.props.shapeData}
                        activeTabId={this.props.activeTabId}
                    />
                </div>
                <Question />
                <Version appVersion={this.props.appVersion}/>
            </section>
        )
    }
}

export default Edit_section;
