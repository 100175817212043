import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import {
    // fetchBackgroundsRequest,
    fetchBackgroundsSuccess,
    // fetchBackgroundsError,
    clearBackgrounds,
} from './backgrounds-actions';

const defaultBackgroundsState = {
    items: [],
    // isFetching: true,
    count: 0,
    // skip: 0,
};

const publicBackgrounds = createReducer(defaultBackgroundsState, {
    [fetchBackgroundsSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: [...payload.background],
            count: payload.count,
        }
    },
    [clearBackgrounds]: (_,__) => {
        return {
            items: [],
            count: 0,
        }
    },
});

export default combineReducers({
    publicBackgrounds,
});
