import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './auth/auth-reducer';
import reticlesReducer from './reticles/reticles-reducer';
import tabsReducer from './reticles/tabs-reducer';
import zoomReducer from './zoom/zoom-reducer';
import gridReducer from './grid/grid-reducer';
import deviceReducer from './devices/device-reducer';
import shapeReducer from './shape/shape-reducer';
import colorThemeReducer from './colorTheme/colorTheme-reducer';
import backgroundsReducer from './backgrounds/backgrounds-reducer';

import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const middleware = [
	...getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
];

const authPersistConfig = {
	key: 'auth',
	storage,
	whitelist: ['token'],
};

export const store = configureStore({
	reducer: {
		auth: persistReducer(authPersistConfig, authReducer),
		reticles: reticlesReducer,
		tabs: tabsReducer,
		zoom: zoomReducer,
		grid: gridReducer,
		device: deviceReducer,
		shape: shapeReducer,
		colorTheme: colorThemeReducer,
		backgrounds: backgroundsReducer,
	},
	devTools: process.env.NODE_ENV === 'development',
	middleware: middleware,
});

export const persistor = persistStore(store);
