import React, { useState } from "react";
import ColorPickerTooltip from "./ColorPickerTooltip";
import { createUseStyles } from "react-jss";

const useStales = createUseStyles({
    colorPickerLabel: {
        display: 'flex',
        alignItems: 'center'
    },
    colorPickerContent: {
        width: 24,
        height: 24,
        margin: 4,
        border: '1px solid #737582',
        cursor: 'pointer'
    },
    colorPickerNotColorButton: {
        backgroundImage: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
        width: 24,
        height: 24,
        margin: 4,
        border: '1px solid #737582',
    },
    colorPickerNotColorButtonLine: {
        position: 'absolute',
        bottom: 4,
        left: 4,
        width: 24,
    }
})


const ColorPicker = ({ handlerColorButton, color, selectedShapeType }) => {
    const classes = useStales()
    const [colorPicerOptions, setColorPicerOptions] = useState([
        { label: 'white', color: '#ffffff', },
        { label: 'black', color: '#000000', },
        { label: 'gray', color: '#666666', },
        { label: 'red', color: '#ff0000', },
        { label: 'orange', color: '#ffaa00', },
        { label: 'yellow', color: '#ffff00', },
        { label: 'green', color: '#00ff00', },
        { label: 'blue', color: '#0000ff', },
    ]);


    const colorPickerContent = () => {
        return (
            <div className="ColorPicker">
                {colorPicerOptions.map(({ label, color }, index) => (
                        <button
                            key={label}
                            className={classes.colorPickerContent}
                            style={{ backgroundColor: color }}
                            onClick={() => { handlerColorButton(color) }}
                        ></button>
                ))}
                        <button
                            key='notColor'
                            className={classes.colorPickerNotColorButton}
                            onClick={() => { handlerColorButton('#333') }}
                        ></button>
            </div>
        )
    }

    return (
        <div className='color-wraper'>
            <label className={classes.colorPickerLabel}>
                <span style={{ marginRight: 20 }}>Color</span>
                <div className="example-wrapper">
                    <ColorPickerTooltip
                        content={colorPickerContent()}
                        direction="bottom"
                        selectedShapeType={selectedShapeType}
                    >
                        {color === '#333' || color === '#333333' ? (
                            <div style = {{ position: 'relative' }}>
                                <button
                                    key='notColor'
                                    className={classes.colorPickerNotColorButton}
                                    style={{ width: 24, height: 24, border: '2px solid #737582', cursor: 'pointer', margin: 8 }}
                                ></button>
                            </div>
                        ) : (
                            <button style={{ backgroundColor: selectedShapeType === 'line' || selectedShapeType === 'num-rect' ? '#FFF' : color, width: 24, height: 24, border: '2px solid #737582', cursor: 'pointer', margin: 8 }}></button>
                        )}

                    </ColorPickerTooltip>
                </div>
            </label>
        </div>
    );
};

export default ColorPicker;
