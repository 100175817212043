import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from "react-jss";
import LayoutAuth from '../authentication/LayoutAuth';
import defaultUserPhoto from '../../../img/log-in-active.svg';


import { getIsAuthenticated, getUserEmail, getUserUrl, getUserFirstName, getUserLastName, getUserPhoneNumber, getUserCountry } from '../../../../redux/auth/auth-selectors';
import { logOut, updateUser } from '../../../../redux/auth/auth-operations';


const useStales = createUseStyles({
    userAccount: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    logout: {
        width: 264,
        height: 40,
        backgroundColor: 'transparent',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

        '&:hover': {
            backgroundColor: '#225A7C',
            border: '1px solid #225A7C',
        },

        '&:active': {
            backgroundColor: '#16405A',
            border: '1px solid #16405A'
        }
    },
    userAccountInputs: {
        width: 264,
        textAlign: 'start',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    userAccountInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#AAAAAA',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,

        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#337AA6',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',

        },
    },
    userAccountBtn: {
        width: 264,
        height: 40,
        backgroundColor: '#337AA6',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

            '&:hover': {
                backgroundColor: '#225A7C',
                border: '1px solid #225A7C',
	        },

	        '&:active': {
                 backgroundColor: '#16405A',
                border: '1px solid #16405A'
            }
    },
    userPhoto: {
        display: 'inline-block',
        width: 50,
        height: 50,
        borderRadius: '50%'
    },

})

const UserAccount = () => {
    const dispatch = useDispatch();
    const classes = useStales();
    const loginStatus = useSelector(getIsAuthenticated);
    const userEmail = useSelector(getUserEmail);
    const profPic = useSelector(getUserUrl);
    const userFirstName = useSelector(getUserFirstName);
    const userLastName = useSelector(getUserLastName);
    const userPhoneNumber = useSelector(getUserPhoneNumber);
    const userCountry = useSelector(getUserCountry);

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [number, setNumber] = useState("");
    const [country, setCountry] = useState("");

    useEffect(() => {
        setName(userFirstName ? userFirstName : "")
        setSurname(userLastName ? userLastName : "")
        setNumber(userPhoneNumber ? `+${userPhoneNumber}` : "")
        setCountry(userCountry ? userCountry : "")
    }, [loginStatus]);

    const onLogout = useCallback(() => dispatch(logOut()), [dispatch]);

    const handleSubmit = useCallback(e => {
        e.preventDefault();
        if (name !== userFirstName || surname !== userLastName || number !== userPhoneNumber || country !== userCountry){
            const payload = {
                firstName: name,
                lastName: surname,
                phoneNumber: number,
                country: country
            };
            dispatch(updateUser(payload));
        }

    }, [dispatch, name, surname, number, country, userFirstName, userLastName, userPhoneNumber, userCountry]);

    const handleChangeName = (e) => { setName(e.target.value) };

    const handleChangeSurname = (e) => { setSurname(e.target.value) };

    const handleChangeNumber = (e) => { 
        // console.log(e.target.value)
        setNumber(e.target.value) };

    const handleChangeCountry = (e) => { setCountry(e.target.value) };


    return (
        <>
            {!loginStatus ? (
                <LayoutAuth />
            ) : (
            <div className={classes.userAccount}>
                <img src={profPic ? profPic : defaultUserPhoto} alt='User avatar' className={classes.userPhoto} />
                    <p style={{marginTop:8}}>{userEmail}</p>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.userAccountInputs}>
                                <label htmlFor='name'>Name</label>
                                <input id='name' className={classes.userAccountInput}
                                    type="text"
                                    value={name}
                                    onChange={handleChangeName}
                                // onFocus={this.props.focus}
                                // onBlur={this.props.blur}
                                />

                                <label htmlFor='surname'>Surname</label>
                                <input id='surname' className={classes.userAccountInput}
                                    type="text"
                                    value={surname}
                                    onChange={handleChangeSurname}
                                // onFocus={this.props.focus}
                                // onBlur={this.props.blur}
                                />
                                <label htmlFor='number'>Phone number</label>
                                <input id='number' className={classes.userAccountInput}
                                    type="tel" name="phone" placeholder="+18008066453" min="2"
                                    value={number}
                                    onChange={handleChangeNumber}
                                // onFocus={this.props.focus}
                                // onBlur={this.props.blur}
                                />
                                <label htmlFor='country'>Country</label>
                                <input id='country' className={classes.userAccountInput}
                                    type="text" placeholder="US" pattern="[A-Z]{2}" 
                                    value={country}
                                    onChange={handleChangeCountry}
                                // onFocus={this.props.focus}
                                // onBlur={this.props.blur}
                                />
                            </div>

                            <button type="submit" className={classes.userAccountBtn}>Save</button>
                        </form>

                        <button type="button" onClick={onLogout} className={classes.logout}>Sign out</button>
            </div>
            )
            }
        </>
    )
}


export default UserAccount;
