import React from 'react';
import ColorPicker from "../edit_section/ColorPicker";

import './stroke.scss';
import './fill.scss';

const inputValidator = require('./utils/inputValidator');

class Stroke extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedShape: '',
            strokeColor: '#000',
            strokeWidth: 1,
            pxStrokeWidth: 0,
            mil: false,
            moa: true,
            shapeClassNum: false,
            strokeDasharray: 'strokeBtn1',
            shapeClass: 'stroke_button stroke_button-'
        }

        this.handlerSetIcon = (e) => {
            if (e.target.id !== this.state.strokeDasharray) {
                this.setState({ strokeDasharray: e.target.id });

                if (e.target.id === 'strokeBtn1') {
                    this.props.setStrokeType('null')
                } if (e.target.id === 'strokeBtn2') {
                    this.props.setStrokeType('10,5')
                } if (e.target.id === 'strokeBtn3') {
                    this.props.setStrokeType('3,3')
                } if (e.target.id === 'strokeBtn4') {
                    this.props.setStrokeType('10,3,3,3')
                }
            }
        }


        this.handlerSetStrokeWidth = (e) => {
            let inputValue = !isNaN(e.target.value) ? e.target.value : '';

            if (inputValue && inputValue.length > 4) {
                inputValue.length = 4
            }

            if (inputValue > 30) {
                inputValue = 30
            }

            // if (inputValue < 0.3) {
            //     inputValue = 0.3
            // }

            this.setState({
                strokeWidth: this.state.strokeWidth = inputValue,
                pxStrokeWidth: this.state.pxStrokeWidth = this.state.moa ? inputValue * 3 : inputValue * 10
            })

            if (inputValue) {
                this.props.setStrokeWidth(this.state);
            }
        }

        this.handlerColorButton = (col) => {
            this.setState({
                strokeColor: this.state.strokeColor = col
            });

            this.props.setStrokeColor(col);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.instruments.moa !== prevState.moa || prevProps.instruments.mil !== prevState.mil) {
            this.setState({
                moa: this.state.moa = prevProps.instruments.moa,
                mil: this.state.mil = prevProps.instruments.mil,
                // strokeWidth: this.state.strokeWidth = this.state.strokeWidth,
                // pxStrokeWidth: this.state.pxStrokeWidth = this.state.moa ? this.state.strokeWidth * 3 : this.state.strokeWidth * 10
            })
            // this.props.setStrokeWidth(this.state)
            this.props.setStrokeColor(this.state.strokeColor)
        }

        // if (prevProps.shapeData.selectedShape !== prevState.selectedShape) {
        //     this.setState({
        //         selectedShape: this.state.selectedShape = prevProps.shapeData,
        //     })
        // }

        if (this.state.selectedShape && (this.state.selectedShape.hasClass('number-group') ||
            this.state.selectedShape.hasClass('num-line'))) {
                if (!this.state.shapeClassNum) {
                    this.setState({
                        shapeClassNum: this.state.shapeClassNum = true,
                    })
                }
        } else {
            if (this.state.shapeClassNum) {
                this.setState({
                    shapeClassNum: this.state.shapeClassNum = false,
                })
            }
        }

        if (prevProps.shapeData !== this.state.selectedShape) { 
            this.setState({
                selectedShape: this.state.selectedShape = prevProps.shapeData,
                strokeWidth: this.state.strokeWidth = prevProps.selectedStroke,
                // strokeColor: this.state.strokeColor = prevProps.selectedStrokeColor,
            })

            if (prevProps.shapeData && !prevProps.shapeData.hasClass('mouseSelect') && prevProps.shapeData.stroke() !== this.state.strokeColor) {
                this.setState({
                    strokeColor: this.state.strokeColor = prevProps.shapeData.stroke()
                })
            }

            let shapeDataAttributes
            if (prevProps.shapeData.node?.nodeName === 'rect') {
                shapeDataAttributes = prevProps.shapeData.node?.attributes[11]
            } else if (prevProps.shapeData.node?.nodeName === 'polygon') {
                shapeDataAttributes = prevProps.shapeData.node?.attributes[9]
            } else shapeDataAttributes = prevProps.shapeData.node?.attributes[10]

            if (!shapeDataAttributes && prevProps.shapeData !== '') {
                if (prevProps.shapeData.node.nodeName !== 'g') {
                    this.setState({
                        strokeDasharray: 'strokeBtn1'
                    })
                    this.props.setStrokeType('null')
                }
            } else if (shapeDataAttributes) {
                if (shapeDataAttributes.nodeValue === "10,5") {
                    this.setState({
                        strokeDasharray: 'strokeBtn2'
                    })
                    this.props.setStrokeType("10,5")
                } else if (shapeDataAttributes.nodeValue === "3,3") {
                    this.setState({
                        strokeDasharray: 'strokeBtn3'
                    })
                    this.props.setStrokeType("3,3")

                } else if (shapeDataAttributes.nodeValue === "10,3,3,3") {
                    this.setState({
                        strokeDasharray: 'strokeBtn4'
                    })
                    this.props.setStrokeType("10,3,3,3")
                }
            }
        }
        
        if (!this.state.strokeColor) {
            this.setState({
                strokeColor: '#000'
            })
        }

        if (this.state.selectedShape.type === 'g') {
            let gEls = this.state.selectedShape.children();

            let gElsColor = gEls.length && gEls[1] ? gEls[1].stroke() : '#000000';

            this.state.selectedShape.stroke(gElsColor);

            if (this.state.strokeColor !== gElsColor) {
                this.setState({
                    strokeColor: this.state.strokeColor = gElsColor,
                })
            }
        }

        // if (prevProps.shapeData && prevProps.shapeData.stroke() !== this.state.strokeColor) {
        //     this.setState({
        //         strokeColor: this.state.strokeColor = prevProps.shapeData.stroke()
        //     })
        // }

        // if (prevProps.shapeData && prevProps.shapeData.stroke() !== this.state.strokeColor && prevProps.selectedStrokeColor !== this.state.strokeColor) {
        //     this.setState({
        //         strokeColor: this.state.strokeColor = prevProps.selectedStrokeColor
        //     })
        // }

        if (prevProps.shapeData && prevProps.shapeData.hasClass('mouseSelect') && this.props.selectedShapes.length) {
            if (this.props.selectedShapes[0].type === 'g') {
                if (this.props.selectedShapes[0].children()[0].stroke() !== this.state.strokeColor) {
                    this.setState({
                        strokeColor: this.state.strokeColor = this.props.selectedShapes[0].stroke()
                    })
                }
            } else {
                if (this.props.selectedShapes[0].stroke() !== this.state.strokeColor) {
                    this.setState({
                        strokeColor: this.state.strokeColor = this.props.selectedShapes[0].stroke()
                    })
                }
            }
        }

        if (this.props.selectedShapes && this.props.selectedShapes.length > 1) {
            if (this.props.selectedShapes[0].type === 'g') {
                const gChild = this.props.selectedShapes[0].first().stroke();

                if (gChild !== this.state.strokeColor) {
                    this.setState({
                        strokeColor: this.state.strokeColor = gChild
                    })
                }
            } else {
                if (this.props.selectedShapes[0].stroke() !== this.state.strokeColor) {
                    this.setState({
                        strokeColor: this.state.strokeColor = this.props.selectedShapes[0].stroke()
                    })
                }
            }
        }

        if (!this.props.focusedInput) {
            let strokeW = inputValidator(`${this.state.strokeWidth}`, this.state.moa ? 0.3 : 0.1, 30);

            if (this.state.strokeWidth !== strokeW) {
                this.setState({
                    strokeWidth: this.state.strokeWidth = strokeW,
                    pxStrokeWidth: this.state.pxStrokeWidth = this.state.moa ? strokeW * 3 : strokeW * 10
                })

                this.props.setStrokeWidth(this.state);
            }
        }
    }

    render () {
        const buttonSelect = this.props.activeShapeButton.name === 'move';
        // const selectedShape = this.state.selectedShape;
        const shapeButton = this.props.activeShapeButton.name !== 'hand' &&
            this.props.activeShapeButton.name !== 'move' &&
            this.props.activeShapeButton.name !== '';
        const numberGroupSelect = buttonSelect && this.state.selectedShape ? !this.state.selectedShape.hasClass('number-group') : true;
        const numberTool = this.props.activeShapeButton.name !== 'number' && this.props.activeShapeButton.name !== 'arabic-number';
        const selectedShapes = this.props.selectedShapes.length >= 1;
        const isNumSelected = selectedShapes && this.props.selectedShapes[0] !== null && this.props.selectedShapes[0].type === 'g' ? true : false;

        return (
            <>
                {(shapeButton || selectedShapes || (selectedShapes && buttonSelect)) &&
                    <section className='edit-section-block stroke-section'>
                        <div  className='stroke-section_title-block'>
                            <h4 className='edit-section-title'>STROKE</h4>
                        </div>
                        <div className='stroke-block_wrapper'>
                            <div className='stroke-block status-block'>
                                {numberTool && !isNumSelected ? (
                                    <div className='stroke_buttons'>
                                        <p style={{ margin: 0 }}>Type</p>

                                        <button id='strokeBtn1' className={`stroke_button_line1 ${this.state.shapeClass + '-1'} ${this.state.strokeDasharray === 'strokeBtn1' ? 'stroke_button-active' : ''}`} onClick={this.handlerSetIcon}></button>
                                        <button id='strokeBtn2' className={`stroke_button_line2 ${this.state.shapeClass + '-2'} ${this.state.strokeDasharray === 'strokeBtn2' ? 'stroke_button-active' : ''}`} onClick={this.handlerSetIcon}></button>
                                        <button id='strokeBtn3' className={`stroke_button_line3 ${this.state.shapeClass + '-3'} ${this.state.strokeDasharray === 'strokeBtn3' ? 'stroke_button-active' : ''}`} onClick={this.handlerSetIcon}></button>
                                        <button id='strokeBtn4' className={`stroke_button_line4 ${this.state.shapeClass + '-4'} ${this.state.strokeDasharray === 'strokeBtn4' ? 'stroke_button-active' : ''}`} onClick={this.handlerSetIcon}></button>
                                    </div>
                                ) : null}
                                <ColorPicker handlerColorButton={this.handlerColorButton} color={this.state.strokeColor} />
                                {(numberGroupSelect && numberTool && !isNumSelected) &&
                                    <div>
                                        <label htmlFor='strokeWidth' style={{ marginRight: 17 }}>Thickness </label>
                                        <input id='strokeWidth' className='stroke stroke-input'
                                            value={this.state.strokeWidth}
                                            onChange={this.handlerSetStrokeWidth}
                                            onFocus={this.props.focus}
                                            onBlur={this.props.blur}
                                        />
                                        <label htmlFor='strokeWidth'>{this.state.moa ? ' MOA' : ' MIL'}</label>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                }
            </>
        )
    }
}

export default Stroke;
