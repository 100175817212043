import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './reticleCardNew.scss';
import defaultUserPhoto from '../../../img/default-user-photo.svg';
import { ReactComponent as VerticalDots } from '../../../img/verticalDotsIcon.svg';
import { addSavingID, getTabs } from '../../../../redux/reticles/tabs-reducer';
import { deleteReticles, copyReticle } from '../../../../redux/reticles/reticles-operations';
import { changeCurrentReticle } from '../../../../redux/reticles/reticles-actions';
import Modal from '../../../components/Modal/Modal';
import { ReactComponent as DeleteIcon } from '../../../img/deleteIcon.svg';

const ReticleCardNew = ({ reticle, activeButton, activeTabId, handleOpenCard, deviceWidth, selectedReticles, checkBoxChange, isSelectedCard, handleChangeSelectedCardStatus }) => {
    const dispatch = useDispatch();
    const openedTabs = useSelector(getTabs);
    const [showModal, setShowModal] = useState(false);
    const [openModal, setOpenModal] = useState(null);
    const [activeSaveNotification, setActiveSaveNotification] = useState(false);

    const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);


    let ref = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log('ref.current', ref.current)
                // console.log('event.target', event.target)

                setIsActionDropdownOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    const createMarkup = () => {
        return { __html: reticle.reticleImg };
    };

    const description = () => {
        if(reticle.user?.firstName === 'null' && reticle.user?.lastName === 'null') {
            return reticle.user?.email.split('@', 2)[0]
        } else if (reticle.user?.firstName !== null && reticle.user?.lastName === null) {
            return reticle.user?.firstName.slice(0, 50)
        } else if (reticle.user?.firstName === null && reticle.user?.lastName !== null) {
            return reticle.user?.lastName.slice(0, 50)
        } else if (reticle.user?.firstName !== null && reticle.user?.lastName !== null) {
            return `${reticle.user?.firstName} ${reticle.user?.lastName}`.slice(0, 50)
        } 
    };

    const openCard = (e) => {
        if (e.target.id === `${reticle.id}-checkbox`) {
            return
        } else if (e.target.id){
            handleOpenCard(reticle);
        } else setIsActionDropdownOpen(true);
    };

    const handleToggleModal = (value) => {
        if (value) {
            setOpenModal(value);
        }

        setShowModal(!showModal);
    };


    const handleDeleteReticle = () => {
        const reticleInTabs = openedTabs.payload.tabs.tabs.find(el => el.savingId === reticle.id);

        const payload = {
            headers: {},
            data: {
                reticleId: [reticle.id]
            }
        };


        if (reticleInTabs) {
            if (activeTabId !== reticleInTabs.id) {

                dispatch(deleteReticles(payload));
                dispatch(addSavingID({ id: reticleInTabs.id, savId: '', status: '', publicId: '' }));

                let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: '', svg: reticle.reticleImg, name: reticle.name, isActiveTab: false, status: '', publicId: '', });
                sessionStorage.setItem(reticleInTabs.id, jsonData);


            } else if (activeTabId === reticleInTabs.id) {

                dispatch(deleteReticles(payload))
                dispatch(changeCurrentReticle({ status: '', savingId: '', publicId: '' }));
                dispatch(addSavingID({ id: reticleInTabs.id, savId: '', status: '', publicId: '' }));

                let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: '', svg: reticle.reticleImg, name: reticle.name, isActiveTab: true, status: '', publicId: '', });
                sessionStorage.setItem(reticleInTabs.id, jsonData);

            }

        } else if (!reticleInTabs) {
            dispatch(deleteReticles(payload))
        }
        handleToggleModal();
    };

    const handleSaveReticle = () => {
        var newSvg = reticle.reticleImg.replace(/data-reticleId="([^"]*)"/, '');
        var newSvg2 = newSvg.replace(/data-reticleid="([^"]*)"/, '');

        const payload = {
            name: `Copy of ${reticle.name}`,
            reticleImg: newSvg2,
            shapesList: reticle.shapesList
        };

        dispatch(copyReticle(payload));
        setActiveSaveNotification(true);

        setTimeout(() => {
            setActiveSaveNotification(false)
        }, 5000);

        setIsActionDropdownOpen(() => {if(isActionDropdownOpen === true){ setIsActionDropdownOpen(false)}})
    };

    const handleCloseReticleNotification = () => {
        setActiveSaveNotification(false)
    };

    const handleSelectReticle = () => {
        handleChangeSelectedCardStatus(true);
        setIsActionDropdownOpen(() => { if (isActionDropdownOpen === true) { setIsActionDropdownOpen(false) } })
    };


    return(
        <>

        <li className='reticleCard' onClick={openCard}>
            
            <div id={`${reticle.id}-${reticle.name}`} className='reticleCard__body'>

                <div className='reticleCard__body-vsg' id={`${reticle.id}`} style={{ backgroundColor: '#e5e5e5' }} dangerouslySetInnerHTML={createMarkup()}>
                </div>


                    {activeButton === '1' && deviceWidth >= 1020 ? (
                        <>
                            {isSelectedCard ? (
                                <input
                                    id={`${reticle.id}-checkbox`}
                                    type='checkbox'
                                    value={reticle.id}
                                    checked={selectedReticles.includes(reticle.id)}
                                    onChange={(e) => checkBoxChange(e)}
                                    className='reticleCard__body-checkbox'
                                >
                                </input>
                            ) : (<button className='reticleCard__body-actions'><VerticalDots /></button>)}

                            {isActionDropdownOpen ? (
                                <div ref={ref} id={`${reticle.id}-actionsTooltip`} className='reticleCard__body-actionsTooltip'>
                                    <p className='reticleCard__body-actionsTooltipButton' onClick={() => handleToggleModal('delete')}>Delete</p>
                                    <p className='reticleCard__body-actionsTooltipButton' onClick={handleSaveReticle}>Copy</p>
                                    <p className='reticleCard__body-actionsTooltipButton' onClick={handleSelectReticle}>Select</p>
                                </div>) : null}
                        </>
                    ) : null }

                <div id={`${reticle.id}-container`} className='reticleCard__container'>

                    {activeButton === '2' || activeButton === '3' ? (
                        <img src={reticle.user?.profPic ? reticle.user?.profPic : defaultUserPhoto} alt='User avatar' className='reticleCard__user-photo' />
                    ) : null}

                    <div className='reticleCard__info'>
                        <h3 className='reticleCard__title'>{reticle.name}</h3>
                        {activeButton === '2' || activeButton === '3' ?
                            (
                                <>
                                    <p className='reticleCard__description'>{description()}</p>
                                </>
                            )
                            :
                            (<p className='reticleCard__description'>{reticle.createdAt.split('T')[0]}</p>)
                        }

                        {activeButton === '2' ?
                            (
                                <p className='reticleCard__description'>{reticle.sumDownloads} downloads | {reticle.createdAt.split('T')[0]}</p>
                            ) : null
                        }
                        {activeButton === '3' &&
                            (
                                <p className='reticleCard__description'>{reticle.createdAt.split('T')[0]}</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </li>

            {activeSaveNotification ? (
                <div className='reticleCardInfo__saveNotification-block'>
                    <p className='reticleCardInfo__saveNotification-text'>The reticle has been successfully saved to My Reticles.</p>
                        <button className='notification_close-btn' onClick={handleCloseReticleNotification}></button>
                </div>

            ) : null}

        {showModal && (
            <Modal toggleModal={handleToggleModal} width={openModal}>
                <div className='reticleCardInfo__modal-section'>
                    {openModal === 'delete' && (
                        <div className='modal-delete-block'>
                            <div style={{ textAlign: 'start' }}>
                                <h1 style={{ fontSize: 20 }}>Confirm Delete</h1>
                                <p>Are you sure you want to delete this item? This action cannot be undone.</p>
                            </div>
                            <div className='modal-delete-actionButton'>
                                <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-delete' onClick={handleDeleteReticle}><DeleteIcon className='reticleCardInfo__button-icon' />Permanently delete the item</button>
                                <button className='modal-delete-button reticleCardInfo__button reticleCardInfo__button-active' onClick={handleToggleModal}>Keep the item</button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
        </>
    )
}

export default ReticleCardNew;
