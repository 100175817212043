import axios from 'axios';
import {
    fetchBackgroundsRequest,
    fetchBackgroundsSuccess,
    fetchBackgroundsError,
} from './backgrounds-actions';

export const fetchBackgrounds = (value) => async dispatch => {
    dispatch(fetchBackgroundsRequest());

    try {
        const { data } = await axios.get(`/reticle/background/get?${value}&take=50`);
        dispatch(fetchBackgroundsSuccess(data));
    } catch (error) {
        dispatch(fetchBackgroundsError(error.response.data.message));
    }
};
