import React from 'react';

import './question.scss';

class Question extends React.Component {
    constructor(props) {
        super(props)
    }

    render () {
        return (
            <div className='question-block_wrapper'>
                <div className='question-block'>
                    <a href='#' className='question-link'>?</a>        
                </div>
            </div>
        )
    }
}

export default Question;
