import React from 'react';
import './previewNotifications.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../../../../../redux/reticles/reticles-actions';
import { getError } from '../../../../../redux/reticles/reticles-selectors';


export default function PreviewNotifications({ activeLink, message }) {
    const dispatch = useDispatch();
    const errorMessage = useSelector(getError);


    const handleCloseNotification = () => {
        dispatch(clearError());
    };

    return (
        <section className={`previewNotificationsSection ${activeLink === '' ? 'previewNotificationsNotActiveLink' : 'previewNotificationsActiveLink'}`} style={{ left: activeLink === '' ? 80 : 414.5 }}>
            <p style={{ margin: 'auto', fontWeight: 500, fontSize: 20 }}>{errorMessage}</p>
            <button className='notification_close-btn' onClick={handleCloseNotification}></button>
        </section>
    );
}
