import './reticleCardNew.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const CardSkeleton = ({cards}) => {
    return (
        Array(cards).fill(0).map((_, i) => 
            <li key={i} className='reticleCard'>
                <SkeletonTheme 
                    baseColor="#e5e5e5"
                    highlightColor="#959595"
                    borderRadius="32px"
                >

                    < Skeleton height={184} />
                    <div style={{ display: 'flex', marginTop: '1rem' }}>
                        <div style={{ marginRight: '1rem' }}>
                            < Skeleton circle width={40} height={40} containerClassName="avatar-skeleton" />
                        </div>

                        <div style={{ flex: '1' }}>
                            < Skeleton count={2} height={13} />
                            < Skeleton count={2} width='50%' height={13} />
                        </div>
                    </div>
                </SkeletonTheme>
            </li>
        )
    )
}

export default CardSkeleton;
