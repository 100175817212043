import React from 'react';
import './create_tools.scss';

class Tools extends React.Component {

    handlerBtnMove = (e) => {
        let btnRuler = document.querySelector('#btnRuler');
        btnRuler.classList.remove('measure-btn-active');

        this.clearClassBtn(e)
        let move = this.props.instruments.move;
        move ? e.currentTarget.classList.add('active_btn') : e.currentTarget.classList.remove('active_btn')
    }

    // handlerBtnHand = (e) => {
    //     let btnRuler = document.querySelector('#btnRuler');
    //     btnRuler.classList.remove('measure-btn-active');

    //     this.clearClassBtn(e);
    //     let hand = this.props.instruments.hand;
    //     hand ? e.currentTarget.classList.add('active_btn') : e.currentTarget.classList.remove('active_btn')
    // }

    handlerBtnCircle = (e) => {
        let btnRuler = document.querySelector('#btnRuler');
        btnRuler.classList.remove('measure-btn-active');

        this.clearClassBtn(e)
        let circle = this.props.instruments.circle;
        circle ? e.currentTarget.classList.add('active_btn') : e.currentTarget.classList.remove('active_btn')
    }

    handlerBtnLine = (e) => {
        let btnRuler = document.querySelector('#btnRuler');
        btnRuler.classList.remove('measure-btn-active');

        this.clearClassBtn(e)
        let line = this.props.instruments.line;
        line ? e.currentTarget.classList.add('active_btn') : e.currentTarget.classList.remove('active_btn')
    }

    handlerBtnSimpleLine = (e) => {
        let btnRuler = document.querySelector('#btnRuler');
        btnRuler.classList.remove('measure-btn-active');

        this.clearClassBtn(e)
        let sline = this.props.instruments.simpleLine;
        sline ? e.currentTarget.classList.add('active_btn') : e.currentTarget.classList.remove('active_btn')
    }

    clearClassBtn = (e) => {
        for (let i = 0; i <= e.target.closest('section').children.length - 1; i++) {
            e.target.closest('section').children[i].classList.remove('active_btn')
        }
    }

    // handlerToolsShortcut = (e) => {
    //     let btnMove = document.querySelector('#btnMove')
    //     let btnCircle = document.querySelector('#btnCircle')
    //     let btnLine = document.querySelector('#btnLine')
    //     let btnSimpleLine = document.querySelector('#btnSimpleLine')
    //     let btnRuler = document.querySelector('#btnRuler');

        // btnRuler.classList.remove('measure-btn-active');

    //     if (e.key === 'v') {
    //         btnCircle.classList.remove('active_btn')
    //         btnLine.classList.remove('active_btn')
    //         btnMove.classList.add('active_btn')
    //         btnSimpleLine.classList.remove('active_btn')
    //         this.props.onSelectMove()
    //     }

    //     if (e.key === 'c') {
    //         btnLine.classList.remove('active_btn')
    //         btnMove.classList.remove('active_btn')
    //         btnCircle.classList.add('active_btn')
    //         btnSimpleLine.classList.remove('active_btn')
    //         this.props.onSelectCircle()
    //     }

    //     if (e.key === 'm') {
    //         btnMove.classList.remove('active_btn')
    //         btnCircle.classList.remove('active_btn')
    //         btnLine.classList.add('active_btn')
    //         btnSimpleLine.classList.remove('active_btn')
    //         this.props.onSelectLine()
    //     }

    //     if (e.key === 'l') {
    //         btnMove.classList.remove('active_btn')
    //         btnCircle.classList.remove('active_btn')
    //         btnLine.classList.remove('active_btn')
    //         btnSimpleLine.classList.add('active_btn')
    //         this.props.onSelectSimpleLine()
    //     }
    // }

    // componentDidMount() {
    //     document.addEventListener('keydown', this.handlerToolsShortcut);
    // }

    // switchBackToMove = () => {

    // }

    render() {
        return (
            <section id='instruments' className='shapes_section'>
                {/* <button id='btnMove' className={this.props.instruments.move? 'shapes-block move active_btn'  : 'shapes-block move'}
                    onClick={(e) => {this.props.onSelectMove(); this.handlerBtnMove(e)}}
                >
                    <span className='button_text'>Move (V)</span>
                </button>
                <button id='btnCircle' className={this.props.instruments.circle ? 'shapes-block circle active_btn' : 'shapes-block circle' }
                    onClick={(e) => {this.props.onSelectCircle(); this.handlerBtnCircle(e)}}
                >
                    <span className='button_text'>Circle (C)</span>
                </button>
                <button id='btnLine' className={this.props.instruments.line ? 'shapes-block line active_btn' : 'shapes-block line' }
                    onClick={(e) => {this.props.onSelectLine(); this.handlerBtnLine(e)}}
                >
                    <span className='button_text'>Poly (M)</span>
                </button>
                <button id='btnSimpleLine' className={this.props.instruments.simpleLine ? 'shapes-block simpleLine active_btn' : 'shapes-block simpleLine' }
                    onClick={(e) => {this.props.onSelectSimpleLine(); this.handlerBtnSimpleLine(e)}}
                >
                    <span className='button_text'>Line (L)</span>
                </button> */}
            </section>
        )
    }
}

export default Tools;
