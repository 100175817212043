import React from 'react';
import Modal from '../../Modal/Modal';


function FilterModal({ 
    handleToggleFilterModal, 
    handlerModalFilterSetSort, 
    isActiveType,
    setIsActiveType,
    isActiveRange,
    setIsActiveRange,
    isActiveMeasurements,
    setIsActiveMeasurements,
    isActiveRangefinder,
    setIsActiveRangefinder,
    selectedType,
    selectedRange,
    selectedMeasurements,
    selectedRangefinder,
    handleModalFilterChange,
    isActiveSort,
    setIsActiveSort,
    selectedSort ,
}) 
{
    return (
        <>
            <Modal toggleModal={handleToggleFilterModal} width={'filter'}>
                <div className='header__modal-section'>

                    <h1 style={{ fontSize: 20 }}>Filters</h1>

                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Type'}>
                            <p style={{ margin: 0, color: '#AAAAAA' }}>Type</p>

                            <div className="dropdown">

                                <div onClick={(e) => { setIsActiveType(!isActiveType); }} style={{ backgroundColor: isActiveType ? '#131318' : 'transparent' }} className="dropdown-btn">
                                    {selectedType}

                                    <span className={isActiveType ? "vectorTop" : "vectorBottom"} />
                                </div>

                                <div className="dropdown-content" style={{ display: isActiveType ? "block" : "none" }}>
                                    {selectedType !== 'Any' && (
                                        <div key={'Any'} onClick={() => { handleModalFilterChange('Type', 'Any'); setIsActiveType(!isActiveType); }} className="item">
                                            Any
                                        </div>
                                    )}
                                    {selectedType !== 'FFP behavior' && (
                                        <div key={'FFP behavior'} onClick={() => { handleModalFilterChange('Type', 'FFP behavior'); setIsActiveType(!isActiveType); }} className="item">
                                            FFP behavior
                                        </div>
                                    )}
                                    {selectedType !== 'SFP behavior' && (
                                        <div key={'SFP behavior'} onClick={() => { handleModalFilterChange('Type', 'SFP behavior'); setIsActiveType(!isActiveType); }} className="item">
                                            SFP behavior
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Range'}>
                            <p style={{ margin: 0, color: '#AAAAAA' }}>Range</p>

                            <div className="dropdown">

                                <div onClick={(e) => { setIsActiveRange(!isActiveRange); }} style={{ backgroundColor: isActiveRange ? '#131318' : 'transparent' }} className="dropdown-btn">
                                    {selectedRange}

                                    <span className={isActiveRange ? "vectorTop" : "vectorBottom"} />
                                </div>

                                <div className="dropdown-content" style={{ display: isActiveRange ? "block" : "none" }}>
                                    {selectedRange !== 'Any' && (
                                        <div key={'Any'} onClick={() => { handleModalFilterChange('Range', 'Any'); setIsActiveRange(!isActiveRange); }} className="item">
                                            Any
                                        </div>
                                    )}
                                    {selectedRange !== 'Close range' && (
                                        <div key={'Close range'} onClick={() => { handleModalFilterChange('Range', 'Close range'); setIsActiveRange(!isActiveRange); }} className="item">
                                            Close range
                                        </div>
                                    )}
                                    {selectedRange !== 'Mid-long range' && (
                                        <div key={'Mid-long range'} onClick={() => { handleModalFilterChange('Range', 'Mid-long range'); setIsActiveRange(!isActiveRange); }} className="item">
                                            Mid-long range
                                        </div>
                                    )}
                                    {selectedRange !== 'Standard' && (
                                        <div key={'Standard'} onClick={() => { handleModalFilterChange('Range', 'Standard'); setIsActiveRange(!isActiveRange); }} className="item">
                                            Standard
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Measurements'}>
                            <p style={{ margin: 0, color: '#AAAAAA' }}>Measurements</p>

                            <div className="dropdown">

                                <div onClick={(e) => { setIsActiveMeasurements(!isActiveMeasurements); }} style={{ backgroundColor: isActiveMeasurements ? '#131318' : 'transparent' }} className="dropdown-btn">
                                    {selectedMeasurements}

                                    <span className={isActiveMeasurements ? "vectorTop" : "vectorBottom"} />
                                </div>

                                <div className="dropdown-content" style={{ display: isActiveMeasurements ? "block" : "none" }}>
                                    {selectedMeasurements !== 'Any' && (
                                        <div key={'Any'} onClick={() => { handleModalFilterChange('Measurements', 'Any'); setIsActiveMeasurements(!isActiveMeasurements); }} className="item">
                                            Any
                                        </div>
                                    )}
                                    {selectedMeasurements !== 'Ballistic' && (
                                        <div key={'Ballistic'} onClick={() => { handleModalFilterChange('Measurements', 'Ballistic'); setIsActiveMeasurements(!isActiveMeasurements); }} className="item">
                                            Ballistic
                                        </div>
                                    )}
                                    {selectedMeasurements !== 'Non-ballistic' && (
                                        <div key={'Non-ballistic'} onClick={() => { handleModalFilterChange('Measurements', 'Non-ballistic'); setIsActiveMeasurements(!isActiveMeasurements); }} className="item">
                                            Non-ballistic
                                        </div>
                                    )}
                                    {selectedMeasurements !== 'With angular measurements' && (
                                        <div key={'With angular measurements'} onClick={() => { handleModalFilterChange('Measurements', 'With angular measurements'); setIsActiveMeasurements(!isActiveMeasurements); }} className="item">
                                            With angular measurements
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Rangefinder'}>
                            <p style={{ margin: 0, color: '#AAAAAA' }}>Rangefinder</p>

                            <div className="dropdown">

                                <div onClick={(e) => { setIsActiveRangefinder(!isActiveRangefinder); }} style={{ backgroundColor: isActiveRangefinder ? '#131318' : 'transparent' }} className="dropdown-btn">
                                    {selectedRangefinder}

                                    <span className={isActiveRangefinder ? "vectorTop" : "vectorBottom"} />
                                </div>

                                <div className="dropdown-content" style={{ display: isActiveRangefinder ? "block" : "none" }}>
                                    {selectedRangefinder !== 'Any' && (
                                        <div key={'Any'} onClick={() => { handleModalFilterChange('Rangefinder', 'Any'); setIsActiveRangefinder(!isActiveRangefinder); }} className="item">
                                            Any
                                        </div>
                                    )}
                                    {selectedRangefinder !== 'With stadiametric rangefinder' && (
                                        <div key={'With stadiametric rangefinder'} onClick={() => { handleModalFilterChange('Rangefinder', 'With stadiametric rangefinder'); setIsActiveRangefinder(!isActiveRangefinder); }} className="item">
                                            With stadiametric rangefinder
                                        </div>
                                    )}
                                    {selectedRangefinder !== 'Without rangefinding capability' && (
                                        <div key={'Without rangefinding capability'} onClick={() => { handleModalFilterChange('Rangefinder', 'Without rangefinding capability'); setIsActiveRangefinder(!isActiveRangefinder); }} className="item">
                                            Without rangefinding capability
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }} key={'Sort'}>
                            <p style={{ margin: 0, color: '#AAAAAA' }}>Sort</p>

                            <div className="dropdown">

                                <div onClick={(e) => { setIsActiveSort(!isActiveSort); }} style={{ backgroundColor: isActiveSort ? '#131318' : 'transparent' }} className="dropdown-btn">
                                    {selectedSort}

                                    <span className={isActiveSort ? "vectorTop" : "vectorBottom"} />
                                </div>

                                <div className="dropdown-content" style={{ display: isActiveSort ? "block" : "none" }}>
                                    {selectedSort !== 'Any' && (
                                        <div key={'Any'} onClick={() => { handleModalFilterChange('Sort', 'Any'); setIsActiveSort(!isActiveSort); }} className="item">
                                            Any
                                        </div>
                                    )}
                                    {selectedSort !== 'Upload date' && (
                                        <div key={'Upload date'} onClick={() => { handleModalFilterChange('Sort', 'Upload date'); setIsActiveSort(!isActiveSort); }} className="item">
                                            Upload date
                                        </div>
                                    )}
                                    {selectedSort !== 'Download count' && (
                                        <div key={'Download county'} onClick={() => { handleModalFilterChange('Sort', 'Download count'); setIsActiveSort(!isActiveSort); }} className="item">
                                            Download count
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button key={'cancel'} className='libraries_modalDCancelButton' onClick={() => handleToggleFilterModal()}>Cancel</button>
                        <button key={'apply'} className='libraries_modalDCancelButton' onClick={() => { handlerModalFilterSetSort(); handleToggleFilterModal() }}>Apply</button>
                    </div>

                </div>
            </Modal>
        </>
    )
}

export default FilterModal;
