import { useCallback, useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'
import ReticleInfo from './ReticleInfo';
import Search from './Search';
import Filters from './Filters';
import FilterModal from './FilterModal';
import ReticleCardNew from './ReticleCardNew';
import UserAccount from '../authentication/UserAccount';
import CardSkeleton from './CardSkeleton';

import { ReactComponent as EmptyCollectionIcon } from '../../../img/emptyCollectionIcon.svg';

import { getIsAuthenticated } from '../../../../redux/auth/auth-selectors';
import { fetchPublicReticles, fetchFolderReticles } from '../../../../redux/reticles/reticles-operations';
import { getAllPublicReticles } from '../../../../redux/reticles/reticles-selectors';

import { getSkipPublicReticles, getFetchingPublicReticles, getFetchError, getFetchReticlesLoading } from '../../../../redux/reticles/reticles-selectors';
import { setSkipAddPublic, setSkipRemovePublic, setIsFetchingPublic } from '../../../../redux/reticles/reticles-actions';


import './library.scss';

const PublicLib = ({ 
    activeButton, 
    activeTabId, 
    focus, 
    blur, 
    handleOpenCard, 
    openCard, 
    reticle, 
    onExportingTool, 
    handlerActivateButton, 
    handleToggleFilterModal, 
    showFilterModal, 
    openHeaderButton,
    headerSearchValue,
    handleUseFilters,
    isUseFilters,
    onChangeHeaderSearchValue,
    deviceWidth,
    activeFolderId,
    handleCloseCard,
 }) => {
    const dispatch = useDispatch();
    const reticles = useSelector(getAllPublicReticles);
    const loginStatus = useSelector(getIsAuthenticated);
    const skip = useSelector(getSkipPublicReticles);
    const fetching = useSelector(getFetchingPublicReticles);
    const fetchingError = useSelector(getFetchError);
    const fetchReticleLoading = useSelector(getFetchReticlesLoading);

    const [value, setValue] = useState('');
    const [sortValue, setSortValue] = useState('');
    const [selected, setIsSelected] = useState('Upload date');
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [activeSort, setActiveSort] = useState('');
    const [firstRender, setFirstRender] = useState(false);


    const [isActiveType, setIsActiveType] = useState(false);
    const [isActiveRange, setIsActiveRange] = useState(false);
    const [isActiveMeasurements, setIsActiveMeasurements] = useState(false);
    const [isActiveRangefinder, setIsActiveRangefinder] = useState(false);
    const [isActiveSort, setIsActiveSort] = useState(false);


    const [selectedType, setIsSelectedType] = useState('Any');
    const [selectedRange, setIsSelectedRange] = useState('Any');
    const [selectedMeasurements, setIsSelectedMeasurements] = useState('Any');
    const [selectedRangefinder, setIsSelectedRangefinder] = useState('Any');
    const [selectedSort, setIsSelectedSort] = useState('Any');

    useEffect(() => {
        setFirstRender(true)
    }, []);
    
    const onChange = (e) => {

        setValue(e.target.value);
        onChangeHeaderSearchValue(e)

        if (firstRender) {
            setActiveSort('');
            setSortValue('');
            dispatch(setSkipRemovePublic(0));

            if (activeFolderId !== '') {
                fetchReticlesActiveFolder(activeFolderId, 0, `searchQuery=${e.target.value}`);
            } else if (activeFolderId === '') {
                fetchReticles(0, `searchQuery=${e.target.value}`);
            }
        }
    };


    useEffect(() => {
        if (activeFolderId !== '' && firstRender) {

            setValue('')
            setActiveSort('');
            setSortValue('');
            dispatch(setSkipRemovePublic(0));

            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

            dispatch(fetchFolderReticles(activeFolderId, 0, ''))
        } else 
        if (activeFolderId === '' && firstRender) {

            setValue('')
            setActiveSort('');
            setSortValue('');
            dispatch(setSkipRemovePublic(0));

            dispatch(fetchPublicReticles(0, ''));
        }
    }, [activeFolderId]);


    useEffect(() => {
        if (headerSearchValue !== '' && !isUseFilters && firstRender) {

            setValue(headerSearchValue);
            setActiveSort('');
            setSortValue('');
            dispatch(setSkipRemovePublic(0));

            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')


            if (activeFolderId !== '') {
                fetchReticlesActiveFolder(activeFolderId, 0, `searchQuery=${headerSearchValue}`)
            } else if (activeFolderId === '') {
                fetchReticles(0, `searchQuery=${headerSearchValue}`);
            }
        }
        else if (headerSearchValue === '' && !isUseFilters && firstRender && openHeaderButton === 'search') {
            handleClearFilter()
        } 
    }, [headerSearchValue]);


    const fetchReticlesActiveFolder = useCallback(
        _.debounce((folderId, skip, value) => dispatch(fetchFolderReticles(folderId, skip, value)), 600)
        , []
    );

    const fetchReticles = useCallback(
        _.debounce((skip, value) => dispatch(fetchPublicReticles(skip, value)), 600)
        , []
    );

    useEffect(() => {
        if (loginStatus && fetching) {
            if (value !== '') {
                if (activeFolderId !== '') {
                    fetchReticlesActiveFolder(activeFolderId, skip, `searchQuery=${value}`);
                } else if (activeFolderId === '') {
                    fetchReticles(skip, `searchQuery=${value}`);
                }
            } else if (value === '') {
                if (activeFolderId !== '') {
                    dispatch(fetchFolderReticles(activeFolderId, skip, sortValue));
                } else if (activeFolderId === '') {
                    dispatch(fetchPublicReticles(skip, sortValue));
                }
            }
        } else return
    }, [loginStatus, fetching]);


    useEffect(() => {
        if (loginStatus && !fetching) {
            let scrollBlock = document.querySelector('.libraries_card-block');
            scrollBlock.addEventListener('scroll', scrollHendler);

            return function () {
                scrollBlock.removeEventListener('scroll', scrollHendler);
            }
        }
    }, [loginStatus, fetching]);

    const scrollHendler = (e) => {
        if (e.target.scrollTop === 0) {
            return
        }

        if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
            dispatch(setSkipAddPublic(16));
            dispatch(setIsFetchingPublic(true));
        }
    };

    const handleClearFilter = () => {
        setValue('');
        setActiveSort('')
        setSortValue('')
        dispatch(setSkipRemovePublic(0));

        if (activeFolderId !== '') {
            fetchReticlesActiveFolder(activeFolderId, 0, '')
        } else if (activeFolderId === '') {
            fetchReticles(0, '');
        }
    };

    useLayoutEffect(() => {
        return () => {
            dispatch(setSkipRemovePublic(0));
            setValue('')
            setIsSelected('')
            setSortValue('')
            dispatch(setIsFetchingPublic(true));
        }
    }, []);

    const handleModalFilterChange = (title, textContent) => {
        setSortValue(textContent)
        if (title === 'Type') {
            setIsSelectedType(textContent)
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Range') {
            setIsSelectedRange(textContent)
            setIsSelectedType('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Measurements') {
            setIsSelectedMeasurements(textContent)
            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Rangefinder') {
            setIsSelectedRangefinder(textContent)
            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Sort') {
            setIsSelectedSort(textContent)
            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
        }
    };

    const handlerModalFilterSetSort = useCallback(() => {
        setValue('')
        dispatch(setSkipRemovePublic(0));
        handleUseFilters();

        if (sortValue === 'Any' || sortValue === '') {
            setSortValue('')
                if (activeFolderId !== '') {
                    dispatch(fetchFolderReticles(activeFolderId, 0, ''))
                } else dispatch(fetchPublicReticles(0, ''))

        } else if (sortValue === 'Download count') {
            setSortValue('byNumDownloads=DESC')
                if (activeFolderId !== '') {
                    dispatch(fetchFolderReticles(activeFolderId, 0, 'byNumDownloads=DESC'))
                } else dispatch(fetchPublicReticles(0, 'byNumDownloads=DESC'))

        } else if (sortValue === 'Upload date') {
            setSortValue('byCreated=DESC')

                if (activeFolderId !== '') {
                    dispatch(fetchFolderReticles(activeFolderId, 0, 'byCreated=DESC'))
                } else dispatch(fetchPublicReticles(0, 'byCreated=DESC'))

        } else {
            activeFolderId === '' ? setSortValue(`searchQuery=${sortValue}`) : setSortValue(`tags[]=${sortValue}`)
                if (activeFolderId !== '') {
                    dispatch(fetchFolderReticles(activeFolderId, 0, `tags[]=${sortValue}`))
                } else dispatch(fetchPublicReticles(0, `searchQuery=${sortValue}`))
        }
    });



    const handlerSetSort = useCallback((title, textContent) => {
        setValue('')
        setActiveSort(textContent)
        dispatch(setSkipRemovePublic(0));


        if (textContent === '') {
            setSortValue('')
            activeFolderId === '' ? dispatch(fetchPublicReticles(0, '')) : dispatch(fetchFolderReticles(activeFolderId, 0, ''))
        
        } else if (textContent === 'Download count') {
                setSortValue('byNumDownloads=DESC')
                activeFolderId === '' ? dispatch(fetchPublicReticles(0, 'byNumDownloads=DESC')) : dispatch(fetchFolderReticles(activeFolderId, 0, 'byNumDownloads=DESC'))

        } else if (textContent === 'Upload date') {
                setSortValue('byCreated=DESC')
                activeFolderId === '' ? dispatch(fetchPublicReticles(0, 'byCreated=DESC')) : dispatch(fetchFolderReticles(activeFolderId, 0, 'byCreated=DESC'))
        } else {
            activeFolderId === '' ? setSortValue(`searchQuery=${textContent}`) : setSortValue(`tags[]=${textContent}`)
            activeFolderId === '' ? dispatch(fetchPublicReticles(0, `searchQuery=${textContent}`)) : dispatch(fetchFolderReticles(activeFolderId, 0, `tags[]=${textContent}`))
        }
    }, [dispatch, activeFolderId]);


    return (
        <>
            {openCard ? (
                <div className='card_info-block'>
                    <ReticleInfo reticle={reticle} activeButton={activeButton} onExportingTool={onExportingTool} handlerActivateButton={handlerActivateButton} deviceWidth={deviceWidth} handleCloseCard={handleCloseCard}/>
                </div>
            ) : null}

            {openHeaderButton === 'userProfile' && deviceWidth < 1020 ? (
                <div className='card_info-block'>
                    <UserAccount />
                </div>
            ) : null}


            <div className='libraries__filters-block'>
                <Search value={value} onChange={onChange} focus={focus} blur={blur} handleClearFilter={handleClearFilter}/>
                <button className={`libraries_filterButton ${isFiltersOpen ? 'libraries_filterButton-active' : ''}`} onClick={() => setIsFiltersOpen(!isFiltersOpen)}>Filters</button>
            </div>

            {isFiltersOpen ? (
                <Filters handlerSetSort={handlerSetSort} activeSort={activeSort} />
            ) : null}

            {(fetchReticleLoading && !reticles.length) &&
                <ul className='libraries_card-list'>
                    <CardSkeleton cards={16}
                    />
                </ul>
            }

            <div className={`libraries_card-block ${isFiltersOpen ? 'libraries_card-block-openFilter' : 'libraries_card-block-closeFilter'}`}>
                {(reticles.length !== 0) && (
                    <ul className='libraries_card-list'>
                        {reticles.map(reticle => (
                            <ReticleCardNew key={reticle.id} reticle={reticle} activeButton={activeButton} activeTabId={activeTabId} handleOpenCard={handleOpenCard} />
                        ))}
                    </ul>
                )}

                {((!reticles.length && fetchingError === 'RETICLE_NOT_FOUND' && value && !fetchReticleLoading) || (!reticles.length && fetchingError === 'RETICLE_NOT_FOUND' && sortValue && !fetchReticleLoading)) && (
                    <div className='libraries_collection-empty'>
                        <EmptyCollectionIcon />
                        <h2 className='libraries_collection-empty-title'>Reticle Not Found</h2>
                        <p className='libraries_collection-empty-text'>Sorry, we couldn't find a matching reticle for your search.
                            Please try a different term.</p>
                    </div>
                )} 
            </div>

            {showFilterModal ? (
                <FilterModal 
                    handleToggleFilterModal={handleToggleFilterModal} 
                    handleModalFilterChange={handleModalFilterChange} 
                    handlerModalFilterSetSort={handlerModalFilterSetSort} 
                    isActiveType={isActiveType}
                    setIsActiveType={setIsActiveType}
                    isActiveRange={isActiveRange}
                    setIsActiveRange={setIsActiveRange}
                    isActiveMeasurements={isActiveMeasurements}
                    setIsActiveMeasurements={setIsActiveMeasurements}
                    isActiveRangefinder={isActiveRangefinder}
                    setIsActiveRangefinder={setIsActiveRangefinder}
                    selectedType={selectedType}
                    selectedRange={selectedRange}
                    selectedMeasurements={selectedMeasurements}
                    selectedRangefinder={selectedRangefinder}
                    isActiveSort={isActiveSort}
                    setIsActiveSort={setIsActiveSort}
                    selectedSort={selectedSort}
                />
            ) : null}
        </>
    )
}

export default PublicLib;

