import axios from 'axios';
import {
    addReticlesRequest,
    addReticlesSuccess,
    addReticlesError,
    resaveReticlesRequest,
    resaveReticlesSuccess,
    resaveReticlesError,
    copyReticleRequest,
    copyReticleSuccess,
    copyReticleError,
    resaveReticleInfoRequest,
    resaveReticleInfoSuccess,
    resaveReticleInfoError,
    resaveAdminReticleInfoRequest,
    resaveAdminReticleInfoSuccess,
    resaveAdminReticleInfoError,
    deleteReticlesRequest,
    deleteReticlesSuccess,
    deleteReticlesError,
    savePublicReticleRequest,
    savePublicReticleSuccess,
    savePublicReticleError,
    deletePublicReticlesRequest,
    deletePublicReticlesSuccess,
    deletePublicReticlesError,
    fetchReticlesRequest,
    fetchReticlesSuccess,
    fetchReticlesError,
    fetchPublicReticlesRequest,
    fetchPublicReticlesSuccess,
    fetchPublicReticlesError,
    fetchFolderReticlesRequest,
    fetchFolderReticlesSuccess,
    fetchFolderReticlesError,
    fetchAdminReticlesRequest,
    fetchAdminReticlesSuccess,
    fetchAdminReticlesError,
    // changeUserStatusRequest,
    // changeUserStatusSuccess,
    // changeUserStatusError,
    changeUserStatusReticleInPublicRequest,
    changeUserStatusReticleInPublicSuccess,
    changeUserStatusReticleInPublicError,
    changeStatusRequest,
    changeStatusSuccess,
    changeStatusError,
    changeAdminStatusRequest,
    changeAdminStatusSuccess,
    changeAdminStatusError,
    // changeAdminShareStatusRequest,
    // changeAdminShareStatusSuccess,
    // changeAdminShareStatusError,
    changeAdminStatusReticleInPublicRequest,
    changeAdminStatusReticleInPublicSuccess,
    changeAdminStatusReticleInPublicError,
    changeCalculationRequest,
    changeCalculationSuccess,
    changeCalculationError,
    fetchPublicFoldersRequest,
    fetchPublicFoldersSuccess,
    fetchPublicFoldersError,
    publishReticleRequest,
    publishReticleSuccess,
    publishReticlesError,
    fetchShareReticleRequest,
    fetchShareReticleSuccess,
    fetchShareReticleError,
    fetchDownloadReticleRequest,
    fetchDownloadReticleSuccess,
    fetchDownloadReticleError,
} from './reticles-actions';

//User

export const fetchUserReticles = (skip, value) => async dispatch => {
    dispatch(fetchReticlesRequest());

    try {
        const { data } = await axios.get(`/reticle/get-all/user?skip=${skip}&take=16&${value}`);
        dispatch(fetchReticlesSuccess(data));
    } catch (error) {
        dispatch(fetchReticlesError(error.response.data.message));
    }
};

export const addReticles = (reticle) => async dispatch => {
    dispatch(addReticlesRequest());

    try {
        const { data } = await axios.post('/reticle/save', reticle);
        dispatch(addReticlesSuccess(data));
    } catch (error) {
        dispatch(addReticlesError(error.response.data.message));
    }
};

export const resaveReticles = (reticle) => async dispatch => {
    dispatch(resaveReticlesRequest());

    try {
        const { data } = await axios.post('/reticle/save', reticle);
        dispatch(resaveReticlesSuccess(data));
    } catch (error) {
        dispatch(resaveReticlesError(error.response.data.message));
    }
};

export const copyReticle = (reticle) => async dispatch => {
    dispatch(copyReticleRequest());

    try {
        const { data } = await axios.post('/reticle/save', reticle);
        dispatch(copyReticleSuccess(data));
    } catch (error) {
        dispatch(copyReticleError(error.response.data.message));
    }
};

export const deleteReticles = (id) => async dispatch => {
    dispatch(deleteReticlesRequest());

    try {
        await axios.delete(`/reticle/delete`, id);
        dispatch(deleteReticlesSuccess(id.data.reticleId));
    } catch (error) {
        dispatch(deleteReticlesError(error.response.data.message));
    }
};

// export const changeUserStatusReticle = (reticle) => async dispatch => {
//     dispatch(changeUserStatusRequest());

//     try {
//         const { data } = await axios.post('/reticle/change-status', reticle);
//         dispatch(changeUserStatusSuccess(data));
//     } catch (error) {
//         dispatch(changeUserStatusError(error.response.data.message));
//     }
// };

export const changeUserStatusReticleInPublic = (reticle) => async dispatch => {
    dispatch(changeUserStatusReticleInPublicRequest());

    try {
        const { data } = await axios.post('/reticle/change-status', reticle);
        dispatch(changeUserStatusReticleInPublicSuccess(data));
    } catch (error) {
        dispatch(changeUserStatusReticleInPublicError(error.response.data.message));
    }
};

export const changeStatusReticle = (reticle) => async dispatch => {
    dispatch(changeStatusRequest());

    try {
        const { data } = await axios.post('/reticle/change-status', reticle);
        dispatch(changeStatusSuccess(data));
    } catch (error) {
        dispatch(changeStatusError(error.response.data.message));
    }
};


//Public

export const fetchPublicReticles = (skip, value) => async dispatch => {
    dispatch(fetchPublicReticlesRequest());

    try {
        const { data } = await axios.get(`/reticle/get-all?skip=${skip}&take=16&${value}`);
        dispatch(fetchPublicReticlesSuccess(data));
    } catch (error) {
        dispatch(fetchPublicReticlesError(error.response.data.message));
    }
};

export const fetchFolderReticles = (id, skip, value) => async dispatch => {
    dispatch(fetchFolderReticlesRequest());

    try {
        const { data } = await axios.get(`/reticle/folder/get-reticles/${id}?skip=${skip}&take=16&${value}`);
        dispatch(fetchFolderReticlesSuccess(data));
    } catch (error) {
        dispatch(fetchFolderReticlesError(error.response.data.message));
    }
};

export const savePublicReticle = (reticle) => async dispatch => {
    dispatch(savePublicReticleRequest());

    try {
        const { data } = await axios.post('/reticle/save', reticle);
        dispatch(savePublicReticleSuccess(data));
    } catch (error) {
        dispatch(savePublicReticleError(error.response.data.message));
    }
};

export const deletePublicReticle = (id) => async dispatch => {
    dispatch(deletePublicReticlesRequest());

    try {
        const { data } = await axios.post(`/reticle/delete-public/${id}`);
        dispatch(deletePublicReticlesSuccess(data));
    } catch (error) {
        dispatch(deletePublicReticlesError(error.response.data.message));
    }
};

//ReticleInfo

export const resaveReticleInfo = (reticle) => async dispatch => {
    dispatch(resaveReticleInfoRequest());

    try {
        const { data } = await axios.post('/reticle/save', reticle);
        dispatch(resaveReticleInfoSuccess(data));
    } catch (error) {
        dispatch(resaveReticleInfoError(error.response.data.message));
    }
};


//Admin
export const fetchAdminReticles = (skip, value) => async dispatch => {
    dispatch(fetchAdminReticlesRequest());

    try {
        const { data } = await axios.get(`/reticle/admin/get-reticle?skip=${skip}&take=16&${value}&status=PENDING`);
        dispatch(fetchAdminReticlesSuccess(data));
    } catch (error) {
        dispatch(fetchAdminReticlesError(error.response.data.message));
    }
};


export const resaveAdminReticleInfo = (reticle) => async dispatch => {
    dispatch(resaveAdminReticleInfoRequest());

    try {
        const { data } = await axios.post('/reticle/admin/reticle-edit', reticle);
        dispatch(resaveAdminReticleInfoSuccess(data));
    } catch (error) {
        dispatch(resaveAdminReticleInfoError(error.response.data.message));
    }
};

export const changeAdminStatusReticle = (reticle) => async dispatch => {
    dispatch(changeAdminStatusRequest());

    try {
        const { data } = await axios.post('/reticle/admin/change-status', reticle);
        dispatch(changeAdminStatusSuccess(data));
    } catch (error) {
        dispatch(changeAdminStatusError(error.response.data.message));
    }
};

// export const changeAdminShareStatusReticle = (reticle) => async dispatch => {
//     dispatch(changeAdminShareStatusRequest());

//     try {
//         const { data } = await axios.post('/reticle/admin/change-status', reticle);
//         dispatch(changeAdminShareStatusSuccess(data));
//     } catch (error) {
//         dispatch(changeAdminShareStatusError(error.response.data.message));
//     }
// };

export const changeAdminStatusReticleInPublic = (reticle) => async dispatch => {
    dispatch(changeAdminStatusReticleInPublicRequest());

    try {
        const { data } = await axios.post('/reticle/admin/change-status', reticle);
        dispatch(changeAdminStatusReticleInPublicSuccess(data));
    } catch (error) {
        dispatch(changeAdminStatusReticleInPublicError(error.response.data.message));
    }
};


export const publishReticle = (statusPayload, folderPayload) => async dispatch => {
    dispatch(publishReticleRequest());

    try {
        const { data } = await axios.post('/reticle/admin/change-status', statusPayload);

        await axios.post(`/reticle/admin/folder/put-drop`, folderPayload);
        dispatch(publishReticleSuccess(data));
    } catch (error) {
        dispatch(publishReticlesError(error.response.data.message));
    }
};



//
export const changeCalculationReticle = (id) => async dispatch => {
    dispatch(changeCalculationRequest());
    try {
        await axios.get(`/reticle/cal/${id}`);
        const { data } = await axios.get(`/reticle/getDownloaded/${id}`);
        const payload = {
            id: id,
            num: data
        }
        dispatch(changeCalculationSuccess(payload));
    } catch (error) {
        dispatch(changeCalculationError(error.response.data.message));
    }
};


export const fetchPublicFolders = () => async dispatch => {
    dispatch(fetchPublicFoldersRequest());

    try {
        const { data } = await axios.get(`/reticle/folder/get`);
        dispatch(fetchPublicFoldersSuccess(data));
    } catch (error) {
        dispatch(fetchPublicFoldersError(error.response.data.message));
    }
};

export const fetchShareReticle = (id) => async dispatch => {
    dispatch(fetchShareReticleRequest());

    try {
        const { data } = await axios.get(`/reticle/shared/${id}`);
        
        dispatch(fetchShareReticleSuccess(data));
    } catch (error) {
        dispatch(fetchShareReticleError(error.response.data.message));
    }
};

export const fetchDownloadReticle = (exportObj) => async dispatch => {
    dispatch(fetchDownloadReticleRequest());

    try {
        const { data } = await axios.post('/reticle/export', exportObj);

        dispatch(fetchDownloadReticleSuccess(data));
    } catch (error) {
        dispatch(fetchDownloadReticleError(error.response.data.message));
    }
};
