import React from 'react';
import './reticles.scss';
import Tooltip from "../../tooltip/Tooltip";

class Reticles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            reticleName: 'My Reticle',
            isRenamingActive: false,
            reticleNameErr: false,
            reticleNameErrValue: ''
        }
    }

    handlerReticleNameChange = (e) => {
        const regex = new RegExp(/^[A-Za-z0-9 _.,|)("-]*$/);

        if (regex.test(e.target.value) !== true) {
            this.setState({ reticleNameErr: true, reticleNameErrValue: e.target.value })

            setTimeout(() => {
                this.setState({ reticleNameErr: false })
            }, 5000);

        }
        if (this.state.reticleName !== e.target.value && regex.test(e.target.value) !== false) {
            this.setState({ reticleName: this.state.reticleName = e.target.value })
            this.setState({ reticleNameErr: false })

        }

        this.props.handlerReticleRename(this.state.reticleName)
    }

    handlerBlur = async (e) => {
        if (!e.target.value) {
            this.setState({ reticleName: this.state.reticleName = 'My Reticle' })
        }
        await this.props.handlerReticleRename(this.state.reticleName)
        this.setState({
            isRenamingActive: false,
            reticleNameErr: false
        })
        this.props.blur(this.props.activeTab.svg)
    }

    componentDidUpdate() {
        if (this.state.reticleName !== this.props?.activeTab?.name) {
            this.setState({
                reticleName: this.state.reticleName = this.props?.activeTab?.name
            })
        }
        // console.log('RETICLE', this.props.name)
    }

    componentDidMount() {

        if (this.state.reticleName !== this.props?.activeTab?.name) {
            this.setState({
                reticleName: this.state.reticleName = this.props?.activeTab?.name
            })
        }

    }

    reticleNameContent() {
        return (
            <p style={{ margin: 0 }}>Rename</p>
        )
    }

    reticleButtonContent() {
        return (
            <p style={{ margin: 0 }}>{this.state.reticleName}</p>
        )
    }

    handleActivateRenaming = () => {
        this.setState({
            isRenamingActive: true
        })
    }

    handleSliceRenaming() {
        const reticleName = this.state.reticleName
        if (reticleName && reticleName.length > 10) {
            return (reticleName.slice(0, 10) + "...")
        } else return (reticleName)
    }

    render() {
        return (
            <section className='reticles_section'>

                {/*
                <select name='select' id='reticle_slct' className='select'>
                    <option value='my_reticle1'>Reticle 1</option>
                    <option value='my_reticle2'>Reticle 2</option>
                </select>
            */}
                {/* <div> */}
                {this.state.isRenamingActive ? (
                    <div style={{ width: '190px', backgroundColor: '#131318' }}>
                        <Tooltip
                            content={this.reticleNameContent()}
                            direction="bottom"
                        >
                            {/* <label htmlFor='RegisterName' className='reticles_name'>
                                Name: */}
                            <input type='text' className='reticles_section-input' value={this.state.reticleName} style={{ width: '170px', marginBottom: this.state.reticleNameErr ? 0 : 20 }}
                                placeholder='My Reticle'
                                onChange={this.handlerReticleNameChange}
                                onFocus={this.props.focus}
                                onBlur={this.handlerBlur}
                                autoFocus={true}
                                maxLength="50"
                            />
                            {this.state.reticleNameErr ? (
                                <p style={{ margin: 0, color: '#FF5050', fontSize: 12, marginLeft: 5 }}>Invalid character</p>
                            ) : null}

                            {/* </label> */}
                        </Tooltip>
                    </div>

                ) : (
                    <Tooltip
                        content={this.reticleButtonContent()}
                        direction="bottom"
                    >

                        <button className='reticles-btn-icon reticles-btn' onClick={this.handleActivateRenaming}
                        >Name: {this.handleSliceRenaming()}</button>
                    </Tooltip>

                )}
                {/* </div> */}

            </section>
        )
    }
}

export default Reticles;
