export const getIsAuthenticated = state => state.auth.isAuthenticated;

export const getUserFirstName = state => state.auth.user.firstName;

export const getUserLastName = state => state.auth.user.lastName;

export const getUserPhoneNumber = state => state.auth.user.phoneNumber;

export const getUserCountry = state => state.auth.user.country;

export const getUserEmail = state => state.auth.user.email;

export const getUserRole = state => state.auth.user.role;

export const getUserUrl = state => state.auth.user.profPic;

export const authError = state => state.auth.error;

export const authLoginError = state => state.auth.loginErrorMessage;

export const getAuthLoading = state => state.auth.loading;
