const inputValidator = (valueStr, minVal='1', maxVal=999) => {
    let num = valueStr.match(/^[\d.]{0,2}[.|,]{0,1}[\d]{0,1}/).join('').replace(/w/gi, '');
    let validVal = num.trim();
    let numVal = 0;

    if (validVal === '0' || validVal === '' || validVal === '.' || validVal === '0.0') {
        validVal = minVal;
    } else if (validVal[validVal.length - 1] === '.') {
        // validVal += '0';
        validVal = minVal;
    }

    numVal = +validVal;

    if (numVal > maxVal) {
        numVal = maxVal;
    }

    if (numVal < minVal) {
        numVal = minVal;
    }

    if (numVal > 55) {
        numVal = 55
    }

    return numVal;
}

module.exports = inputValidator;
