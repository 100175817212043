import React from 'react';
import { useState, useEffect } from "react";
// import Dot from '../../../img/preset-circle-dot.svg'
import { useSelector } from 'react-redux';
import './presets.scss';

const inputValidator = require('./utils/inputValidator');

export default function Presets(props) {
	let [selectedShape, setSelectedShape] = useState('');
	let [selectedObj, setSelectedObj] = useState('')
	let [currentTool, setCurrentTool] = useState('move');
	let [direction, setDirection] = useState('');
	let [radius, setRadius] = useState(1);
	let [selectedRadius, setSelectedRadius] = useState(1);
	let [width, setWidth] = useState(30);
	let [selectedWidth, setSelectedWidth] = useState(1);
	let [height, setHeight] = useState(30);
	let [selectedHeight, setSelectedHeight] = useState(1);
	let [length, setLength] = useState(1);
	let [selectedLength, setSelectedLength] = useState(1);
	let [shapeClass, setShapeClass] = useState('preset_button preset_button-');
	let [gridType, setGridType] = useState('moa');
	let [isEdit, setIsEdit] = useState(false);
	let [isMultiSelectActive, setIsMultiSelectActive] = useState(false);
	const [active, setActive] = useState('');

	const shapeDimensionsData = useSelector((state) => state.shape);

	let handlerSetDot = (e) => {
		setActive(e.target.id);
		setRadius(1);

		props.presetData(1, width, height, length);
	}

	let handlerSetSizeS = (e) => {
		setActive(e.target.id);

		switch (currentTool) {
			case 'circle':
				setRadius(3);

				props.presetData(3, width, height, length);
				break
			case 'square':
				setWidth(3);
				setHeight(3);

				props.presetData(radius, 3, 3, length);
				break
			case 'rectangle':
				setWidth(3);
				setHeight(1.5);

				props.presetData(radius, 3, 1.5, length);
				break
			case 'triangle':
				setWidth(3);
				setHeight(3);

				props.presetData(radius, 3, 3, length);
				break
			default:
				setRadius(1);
				setWidth(1);
				setHeight(1);
				break
		}
	}

	let handlerSetSizeM = (e) => {
		setActive(e.target.id);
		switch (currentTool) {
			case 'circle':
				setRadius(10);

				props.presetData(10, width, height, length);
				break
			case 'square':
				setWidth(10);
				setHeight(10);

				props.presetData(radius, 10, 10, length);
				break
			case 'rectangle':
				setWidth(10);
				setHeight(5);

				props.presetData(radius, 10, 5, length);
				break
			case 'triangle':
				setWidth(10);
				setHeight(10);

				props.presetData(radius, 10, 10, length);
				break
			default:
				setRadius(1);
				setWidth(1);
				setHeight(1);
				break
		}
	}

	let handlerSetSizeL = (e) => {
		setActive(e.target.id);
		switch (currentTool) {
			case 'circle':
				setRadius(20);

				props.presetData(20, width, height, length);
				break
			case 'square':
				setWidth(20);
				setHeight(20);

				props.presetData(radius, 20, 20, length);
				break
			case 'rectangle':
				setWidth(20);
				setHeight(15);

				props.presetData(radius, 20, 15, length);
				break
			case 'triangle':
				setWidth(20);
				setHeight(20);

				props.presetData(radius, 20, 20, length);
				break
			default:
				setRadius(1);
				setWidth(1);
				setHeight(1);
				break
		}
	}

	let handlerSetInputHeightData = (e) => {
		if (e.key === 'Enter') {
			setSelectedHeight(inputValidator(e.target.value));

			props.handlerEditParams('height', inputValidator(e.target.value));
		}
	}

	let handlerChangeInputData = (e) => {
		switch (currentTool) {
			case 'circle':
				setRadius(integerChecker(e.target.value));
				break
			case 'square':
				setWidth(integerChecker(e.target.value));
				break
			case 'rectangle':
				// e.target.className === 'dimesions_input-width' ? setWidth(e.target.value) : setHeight(e.target.value)
				setWidth(integerChecker(e.target.value));
				break
			case 'triangle':
				setWidth(integerChecker(e.target.value));
				// setHeight(integerChecker(e.target.value));
				break
			// case 'line':
			// 	setLength(integerChecker(e.target.value));
			// 	break
			case 'number':
				setWidth(integerChecker(e.target.value));
				break;
			case 'arabic-number':
				setWidth(integerChecker(e.target.value));
				break;
			case 'move':
				switch (selectedShape) {
					case 'circle':
						setSelectedRadius(integerChecker(e.target.value));
						break
					case 'square':
						setSelectedWidth(integerChecker(e.target.value));
						break
					case 'polygon':
						setSelectedWidth(integerChecker(e.target.value))
						break
					case 'number':
						setSelectedWidth(integerChecker(e.target.value))
						break
					case 'rect':
						setSelectedWidth(integerChecker(e.target.value))
						break
					case 'line':
						setSelectedLength(integerChecker(e.target.value));
						break
					default:
						setSelectedRadius(1);
						setSelectedWidth(1);
						setSelectedLength(10);
				}
				break
			default:
				setRadius(1);
				setWidth(1);
				setHeight(1);
				break
		}
	}

	let handlerSetInputData = (e) => {
		if (e.key === 'Enter') {
			let validValue = `${inputsValidator(e.target.value)}`;

			if (gridType === 'mil' && validValue > 30) {
				validValue = 30;
			}

			switch (currentTool) {
				case 'circle':
					// let r = parseFloat(radius).toFixed(1);

					// setRadius(setCorrectValue(r));

					if (gridType === 'mil' && validValue > 20) {
						validValue = 20;
					}

					setRadius(validValue);

					props.presetData(validValue, width, height, length);
					break
				case 'square':
					// let w = parseFloat(width).toFixed(1);

					// setWidth(setCorrectValue(w));
					setWidth(validValue);
					// setHeight(w);
					props.presetData(radius, validValue, height, length);
					break
				case 'rectangle':
					// let rw = parseFloat(width).toFixed(1);

					// setWidth(setCorrectValue(rw));
					setWidth(validValue);

					props.presetData(radius, validValue, height, length);
					break
				case 'triangle':
					// let tw = parseFloat(width).toFixed(1);

					// setWidth(setCorrectValue(tw));
					setWidth(validValue);
					// setHeight(tw);
					props.presetData(radius, validValue, height, length);
					break
				// case 'line':
				// 	props.presetData(radius, width, height, length);
				// 	break
				case 'number':
					// let nw = parseFloat(width).toFixed(1);

					// setWidth(setCorrectValue(nw));
					setWidth(validValue);

					props.presetData(radius, validValue, height, length);
					break;
				case 'arabic-number':
					// let anw = parseFloat(width).toFixed(1);

					// setWidth(setCorrectValue(anw));
					setWidth(validValue);

					props.presetData(radius, validValue, height, length);
					break;
				case 'move':
					switch (selectedShape) {
						case 'circle':
							if (gridType === 'mil' && validValue > 20) {
								validValue = 20;
							}

							if (gridType === 'moa' && validValue > 55) {
								validValue = 55;
							}

							setSelectedRadius(validValue);

							props.handlerEditParams('radius', inputValidator(`${validValue}`));
							break
						case 'square':
							setSelectedWidth(validValue);
							// setSelectedHeight(integerChecker(e.target.value));
							props.handlerEditParams('width', inputValidator(`${validValue}`));
							break
						case 'polygon':
							setSelectedWidth(validValue);
							// setSelectedHeight(integerChecker(e.target.value));
							props.handlerEditParams('width', inputValidator(`${validValue}`))
							break
						case 'number':
							setSelectedWidth(validValue);

							// setSelectedHeight(integerChecker(e.target.value));
							props.handlerEditParams('width', inputValidator(`${validValue}`))
							break
						case 'rect':
							setSelectedWidth(validValue);
							props.handlerEditParams('width', inputValidator(`${validValue}`));
							break
						case 'line':
							// setSelectedLength(integerChecker(e.target.value))
							setSelectedLength(validValue);
							setLength(validValue);
							props.handlerEditParams('length', inputValidator(`${validValue}`));
							break
						default:
							setSelectedRadius(1);
							setSelectedWidth(1);
							setSelectedHeight(1);
					}
					break
				default:
					setRadius(1);
					setWidth(1);
					setHeight(1);
					break
			}

			// handlerTurnOffEditing();
		}
	}

	let handlerChangeInputHeightData = (e) => {
		// setSelectedHeight(integerChecker(e.target.value));
		setHeight(integerChecker(e.target.value))
	}

	let handlerChangeInputSelectedHeightData = (e) => {
		setSelectedHeight(integerChecker(e.target.value))
	}

	let integerChecker = (value) => {
		let num = value.match(/^[\d.]{0,2}[.|,]{0,1}[\d]{0,2}/).join('').replace(/w/gi, '');
		let validVal = num.trim();

		// if (num === '0' || num === '' || num === '.') {
		// 	validVal = 1;
		// } else if (num[num.length - 1] === '.') {
		// 	validVal = num + '0';
		// } else {
		// 	validVal = num;
		// }

		return validVal;
	}

	let inputsValidator = (valueStr) => {
		let num = valueStr.match(/^[\d.]{0,2}[.|,]{0,1}[\d]{0,2}/).join('').replace(/w/gi, '');
		let validVal = num.trim();

		if (validVal === '0' || validVal === '' || validVal === '.') {
			validVal = 1;
		} else if (validVal[validVal.length - 1] === '.') {
			validVal += '0';
		}

		return validVal;
	}

	const setCorrectValue = (val) => {
		let validVal = 1;

		if (val === '0' || val === '' || val === '.') {
			validVal = 1;
		} else if (val[val.length - 1] === '.') {
			validVal = val + '0';
		} else {
			validVal = val;
		}

		let valNum = +val;

		if (valNum > 100) {
			validVal = 100;
		}

		return validVal;
	}

	let handlerTurnOnEditing = () => {
		props.focus()

		setIsEdit(true);
		// isEdit = true
		props.turnEditMode(true);
		setActive('')
	}

	let handlerTurnOffEditing = () => {
		props.blur();
		setIsEdit(false);
		// isEdit = false
		props.turnEditMode(false);

		// props.presetData(radius, width, height, length);
	}

	const hendlerRotatePlus = () => {
		props.btnRightRotater();
	}

	const hendlerRotateMinus = () => {
		props.btnLeftRotater();
	}

	const resetInputValuesAfterBlur = () => {
		props.presetData(radius, width, height, length);

		if (gridType === 'mil' && radius > 20) {
			setRadius(20);

			props.presetData(20, width, height, length);
		} else if (gridType === 'moa' && radius > 55) {
			setRadius(55);

			props.presetData(55, width, height, length);
		}

		if (currentTool === 'square' || currentTool === 'rectangle') {
			if (gridType === 'mil' && width > 30) {
				setWidth(30);

				props.presetData(radius, 30, height, length);
			} else if (gridType === 'moa' && width > 55) {
				setWidth(55);

				props.presetData(radius, 55, height, length);
			}

			if (gridType === 'mil' && height > 30) {
				setHeight(30);

				props.presetData(radius, width, 30, length);
			} else if (gridType === 'moa' && height > 55) {
				setHeight(55);

				props.presetData(radius, width, 55, length);
			}
		} else {
			if (gridType === 'mil' && width > 20) {
				setWidth(20);

				props.presetData(radius, 20, height, length);
			} else if (gridType === 'moa' && width > 55) {
				setWidth(55);

				props.presetData(radius, 55, height, length);
			}

			if (gridType === 'mil' && height > 20) {
				setHeight(20);

				props.presetData(radius, width, 20, length);
			} else if (gridType === 'moa' && height > 55) {
				setHeight(55);

				props.presetData(radius, width, 55, length);
			}
		}

		if (currentTool === 'move') {
			if (gridType === 'mil' && selectedRadius > 20) {
				setSelectedRadius(20);
	
				props.handlerEditParams('radius', 20);
			} else if (gridType === 'moa' && selectedRadius > 55) {
				setSelectedRadius(55);
	
				props.handlerEditParams('radius', 55);
			} else {
				props.handlerEditParams('radius', inputValidator(`${selectedRadius}`));
			}
		}
		
		if (currentTool === 'move') {
			if (gridType === 'mil' && selectedWidth > 20) {
				setSelectedWidth(20);
	
				props.handlerEditParams('radius', 20);
			} else if (gridType === 'moa' && selectedWidth > 55) {
				setSelectedWidth(55);
	
				props.handlerEditParams('radius', 55);
			} else {
				props.handlerEditParams('radius', inputValidator(`${selectedWidth}`));
			}
		}

		// if (selectedRadius > 20) {
		// 	setSelectedRadius(20);
		// }

		// if (selectedWidth > 30) {
		// 	setSelectedWidth(30);
		// }

		if (selectedHeight > 30) {
			setSelectedHeight(30);
		}
	}

	useEffect(() => {
		resetInputValuesAfterBlur();

		// props.presetData(radius, width, height, length);
	}, [isEdit])

	useEffect(() => {
		setRadius(1);
		setWidth(3);
		setHeight(3);

		if (currentTool === 'square') {
			props.presetData(radius, 3, height, length);
		}

		if (currentTool === 'rectangle') {
			setHeight(1.5);

			props.presetData(radius, 3, 1.5, length);
		}

		if (currentTool === 'triangle') {
			setWidth(3);

			props.presetData(radius, 3, height, length);
		}

		if (currentTool === 'number' || currentTool === 'arabic-number') {
			setWidth(9);

			props.presetData(radius, 9, height, length);
		}
	}, [currentTool])

	useEffect(() => {
		if (props.activeShapeButton.name !== currentTool) {
			setActive(props.activeShapeButton.name === 'circle' ? 'presetBtnDot' : 'presetBtnS')
		}
	}, [props.activeShapeButton.name, currentTool])

	useEffect(() => {
		if (props.shapeData.moa) {
			setGridType('moa')
		} else {
			setGridType('mil')
		}
	}, [props.shapeData.moa, props.shapeData.mil])

	useEffect(() => {
		let baseClassName = 'preset_button preset_button-';

		setCurrentTool(props.activeShapeButton.name);
		setDirection(props.activeShapeButton.direction);
		setSelectedObj(props.shapeData.selectedShape);

		switch (props.shapeData.selectedShape.type) {
			case 'rect':
				if (props.shapeData.selectedShape.node.id.includes('square')) {
					setSelectedShape('square');
				} else if (props.shapeData.selectedShape.node.id.includes('rect') &&
					!props.shapeData.selectedShape.node.id.includes('num-rect')
				) {
					setSelectedShape('rect');
				} else if (!props.shapeData.selectedShape.node.id.includes('selectedShapesRectangle')) {
					setSelectedShape('number');
				}
				break
			case 'g':
				if (props.shapeData.selectedShape.node.id.includes('romNumGroup') || props.shapeData.selectedShape.node.id.includes('arabicNumGroup') ) {
					setSelectedShape('number');
				}
				break
			case 'line':
				if (props.shapeData.selectedShape.node.id.includes('num-')) {
					setSelectedShape('number');
				} else {
					setSelectedShape('line');
				}
				break
			default:
				setSelectedShape(props.shapeData.selectedShape.type);
		}

		if (currentTool === 'move' || currentTool === 'line' || currentTool === 'number' || currentTool === 'arabic-number') {
			setShapeClass('preset-disabled');
		} else {
			setShapeClass(baseClassName + currentTool + (direction ? '-' + direction : ''));
		}

		if (props.shapeData.selectedShape) {
			switch (props.shapeData.selectedShape.type) {
				case 'circle':
					let rValue = gridType === 'moa' ? props.shapeData.selectedShape.radius() / 3 : props.shapeData.selectedShape.radius() / 10;

					if (radius === rValue.toFixed(1)) {
						setRadius(rValue.toFixed(1));
					}

					break
				case 'rect':
					let wValue = gridType === 'moa' ? props.shapeData.selectedShape.width() / 3 : props.shapeData.selectedShape.width() / 10;
					let hValue = gridType === 'moa' ? props.shapeData.selectedShape.height() / 3 : props.shapeData.selectedShape.height() / 10;

					if (width === wValue) {
						setWidth(wValue.toFixed(1));
					}

					if (height === hValue) {
						setHeight(hValue.toFixed(1));
					}
					break
				case 'polygon':
					let pValue = gridType === 'moa' ? props.shapeData.selectedShape.width() / 3 : props.shapeData.selectedShape.width() / 10;

					if (width === pValue.toFixed(1)) {
						setWidth(pValue.toFixed(1));
					}
					break
				case 'g':
					let gValue = gridType === 'moa' ? props.shapeData.selectedShape.width() / 3 : +props.shapeData.selectedShape.width() / 10;
					setWidth(gValue.toFixed(1));
					break
				case 'line':
					// let x1 = props.shapeData.selectedShape.node.attributes[0].nodeValue;
					// let y1 = props.shapeData.selectedShape.node.attributes[1].nodeValue;
					// let x2 = props.shapeData.selectedShape.node.attributes[2].nodeValue;
					// let y2 = props.shapeData.selectedShape.node.attributes[3].nodeValue;

					let x1 = props.shapeData.selectedShape.attr('x1');
					let y1 = props.shapeData.selectedShape.attr('y1');
					let x2 = props.shapeData.selectedShape.attr('x2');
					let y2 = props.shapeData.selectedShape.attr('y2');

					let l = Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2);

					let gridL = gridType === 'moa' ? l / 3 : l / 10;

					setLength(gridL.toFixed(1));
					break
				default:
			}
			
			props.presetData(radius, width, height, length);
		}

		if (props.shapeData.selectedShape !== selectedObj) {
			switch (props.shapeData.selectedShape.type) {
				case 'circle':
					let r = props.shapeData.selectedShape.radius();
					let gridR = gridType === 'moa' ? r / 3 : r / 10;

					setSelectedRadius(gridR.toFixed(1));
					break
				case 'rect':
					let w = props.shapeData.selectedShape.width();
					let h = props.shapeData.selectedShape.height();
					let gridW = gridType === 'moa' ? w / 3 : w / 10;
					let gridH = gridType === 'moa' ? h / 3 : h / 10;

					setSelectedWidth(gridW.toFixed(1));
					setSelectedHeight(gridH.toFixed(1));
					break
				case 'polygon':
					let pw = props.shapeData.selectedShape.width();
					let gridPW = gridType === 'moa' ? pw / 3 : pw / 10;
					setSelectedWidth(gridPW.toFixed(1));
					break
				case 'line':
					let x1 = +props.shapeData.selectedShape.node.attributes[0].nodeValue;
					let y1 = +props.shapeData.selectedShape.node.attributes[1].nodeValue;
					let x2 = +props.shapeData.selectedShape.node.attributes[2].nodeValue;
					let y2 = +props.shapeData.selectedShape.node.attributes[3].nodeValue;
					let dx = x2 - x1;
					let dy = y2 - y1;
					let oldLineLength = Math.sqrt((dx * dx) + (dy * dy));
					let gridL = gridType === 'moa' ? oldLineLength / 3 : oldLineLength / 10;
					
					setSelectedLength(gridL.toFixed(1));
					break
				case 'g':
					let gValue = gridType === 'moa' ? props.shapeData.selectedShape.width() / 3 : +props.shapeData.selectedShape.width() / 10;
					setSelectedWidth(gValue.toFixed(1));
					break

				default:
					setSelectedRadius(1)
			}
			// props.editShapeSize(selectedRadius, selectedWidth, selectedHeight);
		} //else {
		// props.presetData(radius, width, height, length);
		// }
	}, [props.activeShapeButton.name, props.activeShapeButton.direction, props.shapeData.selectedShape, props.shapeData.gridLength, currentTool, direction, radius, width, height, length, selectedShape, selectedObj, selectedRadius, selectedWidth, selectedHeight])

	useEffect(() => {
		if (!props.focusedInput && selectedShape) {
			switch(selectedShape) {
				case 'line':
					props.handlerEditParams('length', inputValidator(`${selectedLength}`));
					break;
				case 'circle':
					if (!selectedRadius || selectedRadius === '0') {
						setSelectedRadius(1);
						props.handlerEditParams('radius', '1');
					} else {
						props.handlerEditParams('radius', inputValidator(`${selectedRadius}`));
					}
					break;
				case 'square':
					if (!selectedWidth || selectedWidth === '0') {
						setSelectedWidth(3);
						props.handlerEditParams('width', '3');
					} else  {
						props.handlerEditParams('width', inputValidator(`${selectedWidth}`));
					}
					break;
				case 'rect':
					if (!selectedWidth || selectedWidth === '0') {
						setSelectedWidth(3);
						props.handlerEditParams('width', '3');
					} else  {
						props.handlerEditParams('width', inputValidator(`${selectedWidth}`));
					}

					if (!selectedHeight || selectedHeight === '0') {
						setSelectedHeight(1.5);
						props.handlerEditParams('height', '1.5');
					} else {
						props.handlerEditParams('height', inputValidator(`${selectedHeight}`));
					}
					break;
				case 'number':
					if (!selectedWidth || selectedWidth === '0') {
						setSelectedWidth(3);
						props.handlerEditParams('width', '3');
					} else  {
						props.handlerEditParams('width', inputValidator(`${selectedWidth}`));
					}
					break;
				default:
					// console.log('hhh')
			}
		} else {
			if (!radius || radius === '0') {
				setRadius('1');
			}

			if (!width || width === '0') {
				setWidth('3');
			}

			if (!height || height === '0') {
				setHeight('1.5');
			}
		}
	}, [props.focusedInput])

	useEffect(() => {
		if (props.shapeData.selectedShapes.length > 1) {
			setIsMultiSelectActive(true);
		} else {
			setIsMultiSelectActive(false);
		}
	}, [props.shapeData.selectedShapes])

	useEffect(() => {
		if (props.shapeData.selectedShape.type === 'line') {
			setSelectedLength(shapeDimensionsData.currentShapeLength);
		}

		// console.log(props.shapeData.selectedShape)
		if (props.shapeData.selectedShape.type === 'circle') {
			setSelectedRadius(shapeDimensionsData.currentShapeRadius);
		}

		if (props.shapeData.selectedShape.type === 'polygon') {
			setSelectedWidth(shapeDimensionsData.currentShapeWidth);
		}

		if (props.shapeData.selectedShape.type === 'rect' && props.shapeData.selectedShape.hasClass('rectangle')) {
			setSelectedHeight(shapeDimensionsData.currentShapeHeight);
			setSelectedWidth(shapeDimensionsData.currentShapeWidth);
		}

		if (props.shapeData.selectedShape.type === 'rect' && props.shapeData.selectedShape.hasClass('square')) {
			setSelectedWidth(shapeDimensionsData.currentShapeWidth);
		}
	}, [shapeDimensionsData])

	return (
		<section className='presets_section'>
			{currentTool !== '' &&
				currentTool !== 'hand' && currentTool !== 'move' &&
				currentTool !== 'number' && currentTool !== 'arabic-number' &&
				currentTool !== 'line' &&
				<h2 className='presets_section-title'>Presets</h2>
			}
			{currentTool !== '' && currentTool !== 'hand' && currentTool !== 'move' &&
				<div className='presets_buttons'>
					{currentTool === 'circle' &&
						<button id='presetBtnDot' className={`${shapeClass + '-dot'} ${active === 'presetBtnDot' ? 'preset_button-active' : ''}`} onClick={handlerSetDot}></button>
					}
					<button id='presetBtnS' className={`${shapeClass + '-s'} ${active === 'presetBtnS' ? 'preset_button-active' : ''}`} onClick={handlerSetSizeS}></button>
					<button id='presetBtnM' className={`${shapeClass + '-m'} ${active === 'presetBtnM' ? 'preset_button-active' : ''}`} onClick={handlerSetSizeM}></button>
					<button id='presetBtnL' className={`${shapeClass + '-l'} ${active === 'presetBtnL' ? 'preset_button-active' : ''}`} onClick={handlerSetSizeL}></button>
				</div>
			}
			{currentTool === 'square' &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Width</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={width}
							onChange={handlerChangeInputData}
							onKeyDown={handlerSetInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{currentTool === 'triangle' &&
				<div className='presets_dimensions'>
					{/* <div className='dimension-block'>
					<p className='dimension-text'>Height</p>
					<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
						value={height}
						onChange={handlerChangeInputData}
					/>
					<p className='dimension-text dimension-text-grid'>{gridType}</p>
				</div> */}
					<div className='dimension-block'>
						<p className='dimension-text'>Size</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={width}
							onChange={handlerChangeInputData}
							onKeyDown={handlerSetInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{currentTool === 'rectangle' &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Height</p>
						<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
							value={height}
							onChange={handlerChangeInputHeightData}
							onKeyDown={handlerSetInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
					<div className='dimension-block'>
						<p className='dimension-text'>Width</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={width}
							onChange={handlerChangeInputData}
							onKeyDown={handlerSetInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{currentTool === 'circle' &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Radius</p>
						<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
							value={radius}
							onChange={handlerChangeInputData}
							onKeyDown={handlerSetInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{(currentTool === 'number' || currentTool === 'arabic-number') &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Size</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={width}
							onChange={handlerChangeInputData}
							onKeyDown={handlerSetInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{/* { currentTool === 'line' &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Length</p>
						<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
							value={length}
							onChange={handlerChangeInputData}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			} */}
			{currentTool === 'move' && selectedShape === 'line' && !isMultiSelectActive &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Length</p>
						<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
							value={selectedLength}
							onChange={handlerChangeInputData}
							onKeyDown={handlerSetInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
					<div className='rotate-block'>
						<p className='rotate-paragraph'>Rotate</p>
						<button className='rotate-button rotate-btn_plus' onClick={hendlerRotatePlus}></button>
						<button className='rotate-button rotate-btn_minus' onClick={hendlerRotateMinus}></button>
					</div>
				</div>
			}
			{currentTool === 'move' && selectedShape === 'circle' && !isMultiSelectActive &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Radius</p>
						<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
							value={selectedRadius}
							onKeyDown={handlerSetInputData}
							onChange={handlerChangeInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{currentTool === 'move' && selectedShape === 'square' && !isMultiSelectActive &&
				<div className='presets_dimensions'>
					{/* <div className='dimension-block'>
					<p className='dimension-text'>Height</p>
					<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
						value={selectedHeight}
						onChange={handlerChangeInputData}
					/>
					<p className='dimension-text dimension-text-grid'>{gridType}</p>
				</div> */}
					<div className='dimension-block'>
						<p className='dimension-text'>Width</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={selectedWidth}
							onKeyDown={handlerSetInputData}
							onChange={handlerChangeInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{currentTool === 'move' && selectedShape === 'rect' && !isMultiSelectActive &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Height</p>
						<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
							value={selectedHeight}
							onKeyDown={handlerSetInputHeightData}
							onChange={handlerChangeInputSelectedHeightData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
					<div className='dimension-block'>
						<p className='dimension-text'>Width</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={selectedWidth}
							onKeyDown={handlerSetInputData}
							onChange={handlerChangeInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
			{currentTool === 'move' && selectedShape === 'polygon' && !isMultiSelectActive &&
				<div className='presets_dimensions'>
					{/* <div className='dimension-block'>
					<p className='dimension-text'>Height</p>
					<input type="text" id='dimensionsInputH' className='dimensions_input dimesions_input-height'
						value={height}
						onChange={handlerChangeInputData}
					/>
					<p className='dimension-text dimension-text-grid'>{gridType}</p>
				</div> */}
					<div className='dimension-block'>
						<p className='dimension-text'>Size</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={selectedWidth}
							onKeyDown={handlerSetInputData}
							onChange={handlerChangeInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
					<div className='rotate-block'>
						<p className='rotate-paragraph'>Rotate</p>
						<button className='rotate-button rotate-btn_plus' onClick={hendlerRotatePlus}></button>
						<button className='rotate-button rotate-btn_minus' onClick={hendlerRotateMinus}></button>
					</div>
				</div>
			}
			{currentTool === 'move' && (selectedShape === 'number' || selectedShape === 'arabic-number') && !isMultiSelectActive &&
				<div className='presets_dimensions'>
					<div className='dimension-block'>
						<p className='dimension-text'>Size</p>
						<input type="text" id='dimensionsInputW' className='dimensions_input dimesions_input-width'
							value={selectedWidth}
							onKeyDown={handlerSetInputData}
							onChange={handlerChangeInputData}
							onFocus={handlerTurnOnEditing}
							onBlur={handlerTurnOffEditing}
						/>
						<p className='dimension-text dimension-text-grid'>{gridType}</p>
					</div>
				</div>
			}
		</section>
	);
}
