import { useState } from 'react';
import { createUseStyles } from "react-jss";
import imgVisible from '../../../img/input_btn-visibility.svg';
import imgnotVisible from '../../../img/input_btn-notVisible.svg';
import backBtn from '../../../img/back_btn.svg';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordConfirm } from '../../../../redux/auth/auth-operations';
import { getUserEmail } from '../../../../redux/auth/auth-selectors';
import { useFormik } from "formik";
import * as Yup from 'yup';


const useStales = createUseStyles({
    CodeVerificationForm: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    CodeVerificationInputs: {
        width: 264,
        textAlign: 'start',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    CodeVerificationInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#3F414B',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#337AA6',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',

        },
    },
    errorCodeVerificationInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#B5463C',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#B5463C',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',

        },
    },
    CodeVerificationBtn: {
        width: 264,
        height: 40,
        backgroundColor: '#337AA6',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

        '&:hover': {
            backgroundColor: '#225A7C',
            border: '1px solid #225A7C',
        },

        '&:active': {
            backgroundColor: '#16405A',
            border: '1px solid #16405A'
        }
    },
    showBtn: {
        right: 15,
        top: 25,
        position: 'absolute',
        backgroundSize: 'cover',
        border: 'none',
        outline: 'none',
        width: 15,
        height: 10,
        backgroundColor: 'inherit',
        backgroundPosition: 'center',
        cursor: 'pointer',
    },
    backBlock: {
        marginTop: 50,
        marginLeft: 20,
    },
    backBtn: {
        backgroundImage: `url(${backBtn})`,
        backgroundSize: 'cover',
        border: 'none',
        outline: 'none',
        width: 21,
        height: 21,
        backgroundColor: 'inherit',
        backgroundPosition: 'center',
        cursor: 'pointer',
    },
    backText: {
        verticalAlign: 'top',
        fontSize: 20,
    },
    codeVerificationError: {
        color: '#B5463C',
        margin: 0,
        fontSize: 13
    },

})


const CodeVerificationStepTwo = ({ handlePage, code }) => {
    const dispatch = useDispatch();
    const [showPassword, setShownPassword] = useState(false);
    const [showReEnterPassword, setShownReEnterPassword] = useState(false);
    const authEmail = useSelector(getUserEmail);

    const classes = useStales(showPassword, showReEnterPassword)

    const formik = useFormik({
        initialValues: {
            password: '',
            reEnterPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().min(4, 'Password is too short - should be 4 symbols minimum.').max(20, 'Password is too long - should be 20 symbols maximum.').required('Password is required'),
            reEnterPassword: Yup.string().min(4, 'New password is too short - should be 4 symbols minimum.').max(20, 'New password is too long - should be 20 symbols maximum.').required('New password is required'),
        }),
        onSubmit: values => {
            if (formik.values.password === formik.values.reEnterPassword) {
                const payload = {
                    email: authEmail,
                    password: formik.values.password,
                    token: code,
                };
                // console.log('payload', payload)
                dispatch(resetPasswordConfirm(payload));
                handlePage(0)

            } else alert('Your passwords do not match')
        },
    }); 

    return (
        <>
            {/* <div className={classes.backBlock}>
                <button className={classes.backBtn} onClick={() => handlePage(0)}></button><span className={classes.backText}> Back </span>
            </div> */}

            <div className={classes.CodeVerificationForm}>
                <h2>Reset your password</h2>
                {formik.touched.password && formik.errors.password ? (<p className={classes.codeVerificationError}>{formik.errors.password}</p>) : null}
                {formik.touched.reEnterPassword && formik.errors.reEnterPassword ? (<p className={classes.codeVerificationError}>{formik.errors.reEnterPassword}</p>) : null}

                <form onSubmit={formik.handleSubmit}>
                    <div className={classes.CodeVerificationInputs}>

                        <label htmlFor='RegisterPassword'>New password</label>
                        <div style={{ position: 'relative' }}>
                            <input id='RegisterPassword' className={(formik.touched.password && formik.errors.password) ? classes.errorCodeVerificationInput : classes.CodeVerificationInput}
                                type={showPassword ? "text" : "password"}
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            />
                            <button type="button" className={classes.showBtn} onClick={() => setShownPassword(!showPassword)} style={{ backgroundImage: showPassword ? `url(${imgVisible})` : `url(${imgnotVisible})` }}></button>
                        </div>

                        <label htmlFor='ReEnterPassword'>Re-enter new password</label>
                        <div style={{ position: 'relative' }}>
                            <input id='ReEnterPassword' className={(formik.touched.reEnterPassword && formik.errors.reEnterPassword) ? classes.errorCodeVerificationInput : classes.CodeVerificationInput} style={{ marginBottom: 0 }}
                                type={showReEnterPassword ? "text" : "password"}
                                name="reEnterPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.reEnterPassword}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            />
                            <button type="button" className={classes.showBtn} onClick={() => setShownReEnterPassword(!showReEnterPassword)} style={{ backgroundImage: showReEnterPassword ? `url(${imgVisible})` : `url(${imgnotVisible})` }}></button>
                        </div>
                    </div>

                    <button type="submit" className={classes.CodeVerificationBtn}>Reset</button>
                </form>
            </div>
        </>
    )
}


export default CodeVerificationStepTwo;
