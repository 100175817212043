import React from 'react';

export default function PreviewDevicesList({deviceTypes, currentDeviceType, handlerSelectDevice}) {
    return (
        <form>
            {deviceTypes.map((device, i) => (
                <div className='devices_menu-block-item' key={i}>
                    <input type='radio' id={device} name={device} value={device} className='devices_menu-block-input'
                        checked={device === currentDeviceType ? true : false}
                        onChange={handlerSelectDevice}
                    />
                    <label htmlFor={device} className='devices_menu-block-label'>{device}</label>
                </div>
            ))}
        </form>
    )
}
