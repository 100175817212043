import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SVG } from '@svgdotjs/svg.js';
import './Navigation.scss';

import { updateZoomPosition } from '../../../../redux/zoom/zoom-reducer';

export default function Navigation(props) {
    const dispatch = useDispatch();
    const navWidth = 156;
    const navHeight = 120;
    const canvasWidth = 600;
    const canvasHeight = 450;
    let [viewedWidth, setViewedWidth] = useState(navWidth - 2);
    let [viewedHeight, setViewedHeight] = useState(navHeight - 2);
    let [viewedX, setViewedX] = useState(1);
    let [viewedY, setViewedY] = useState(1);
    let [selectedRect, setSelectedRect] = useState('');
    let [firstLoad, setFirstLoad] = useState(false);
    let [currentZoomIndex, setCurrentZoomIndex] = useState(100);
    // let [mousePressed, setMousePressed] = useState(false);

    const useSvg = useRef(SVG().size(navWidth, navHeight).attr({id: 'nav-svg'}))
    const svgData = useSelector((state) => state.tabs.tabs);
    const zoomData = useSelector((state) => state.zoom.zoomData);

    // const convertSVG2Nav = (svg) => {
    //     svg.each(function(item) {
    //         return item.fill('blue')
    //     })
    // }

    const moveNavRectangle = (rec, x, y) => {
        if (rec.x() >= 2) {
            rec.x(x);
        } else {
            rec.x(2);
        }

        if (rec.y() >= 2) {
            rec.y(y);
        } else {
            rec.y(2);
        }

        if (rec.x() + rec.width() > 157 ) {
            rec.x(156 - rec.width());
        }

        if (rec.y() + rec.height() > 120 ) {
            rec.y(120 - rec.height());
        }

        dispatch(updateZoomPosition({id: props.activeTabId, zx: (rec.x() / navWidth * canvasWidth), zy: (rec.y() / navHeight * canvasHeight)}));
        
        // setViewedX(rec.x());
        // setViewedY(rec.y());
    }

    const onMouseDown = (e) => {
        const navRec = useSvg.current.findOne(`#${e.target.id}`);

        // setMousePressed(true);

        if (navRec && navRec.id() === 'navigateRect') {
            setSelectedRect(navRec);
            selectedRect = navRec
        }
    }

    const onMove = (e) => {
        // let zoomD = zoomData.find(el => el.id === props.activeTabId);

        if (selectedRect) {
            moveNavRectangle(selectedRect, e.offsetX, e.offsetY);
        }
    }

    const onMoveUp = (e) => {
        // dispatch(updateZoomPosition({id: props.activeTabId, zx: (viewedX / navWidth * canvasWidth), zy: (viewedY / navHeight * canvasHeight)}));
        // setMousePressed(false);
        setSelectedRect('');
        selectedRect = '';
    }

    const onDblClick = (e) => {
        e.preventDefault();

        const navRec = useSvg.current.findOne('#navigateRect');

        moveNavRectangle(navRec, e.offsetX, e.offsetY);
    }

    useEffect(() => {
        const navRect = (x, y, w, h) => useSvg.current.rect(w, h)
            .id('navigateRect')
            .stroke({width: 1, color: '#337AA6'})
            .fill({opacity: 0.1, color: '#337AA6'})
            .move(x, y)
            .front()

        if (firstLoad) {
            let svgD = svgData.find(el => el.id === props.activeTabId);

            if (svgD && svgD.shapeList) {
                useSvg.current.clear();
                useSvg.current.svg(svgD.shapeList);
            } else {
                useSvg.current.clear();
                useSvg.current.svg('');
            }

            navRect(viewedX, viewedY, viewedWidth, viewedHeight);
        }
    }, [svgData, props.activeTabId, viewedX, viewedY, viewedWidth, viewedHeight])

    useEffect(() => {
        let zoomD = zoomData.find(el => el.id === props.activeTabId);

        if (zoomD && zoomD.index) {
            if (zoomD.index === 100) {
                setViewedWidth(navWidth - 2);
                setViewedHeight(navHeight - 2);
                setViewedX(1);
                setViewedY(1);
                setCurrentZoomIndex(100);
            } else {
                setViewedWidth(navWidth - ((zoomD.index - 100) / 100 * navWidth));
                setViewedHeight(navHeight - ((zoomD.index - 100) / 100 * navHeight));
                setViewedX(zoomD.zx / canvasWidth * navWidth);
                setViewedY(zoomD.zy / canvasHeight * navHeight);
                setCurrentZoomIndex(zoomD.index);
            }
        }
    }, [zoomData, props.activeTabId])

    useEffect(() => {
        const navSection = document.querySelector('.navigation-section');

        if (currentZoomIndex !== 100) {
            navSection.classList.remove('navigation-section_disabled');
        } else {
            navSection.classList.add('navigation-section_disabled');
        }
    }, [currentZoomIndex])

    useEffect(() => {
        const navContainer = document.querySelector('#navigation-svg');

        if (navContainer) {
            useSvg.current.addTo('#navigation-svg');
            useSvg.current.mousedown((e) => onMouseDown(e));
            useSvg.current.mousemove((e) => onMove(e));
            useSvg.current.mouseup((e) => onMoveUp(e));
            navContainer.addEventListener('dblclick', onDblClick);
        }

        setFirstLoad(true);

        return () => {
            if (navContainer) {
                useSvg.current.mousedown(null);
                useSvg.current.mousemove(null);
                useSvg.current.mouseup(null);
                navContainer.removeEventListener('dblclick', onDblClick);
            }
        }
    }, [])

    return (
        <section className='navigation-section'>
            <h2 className='navigation-title'>Navigation</h2>
            <div className='navigation-block'>
                <div id='navigation-svg' className='navigation-svg_block'>
                </div>
            </div>
        </section>
    );
}
