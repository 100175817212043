import React from 'react';
import './duplicate.scss';

export default function Duplicate(props) {

  return (
    <section className='duplicate-section'>
      <button id='duplicateBtn' className='duplicate-btn'
        onClick={(e) => {props.duplicate()}}
      >
        <span className='duplicate_text'>Duplicate</span>
      </button>
    </section>
  );
}
