import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import './tab.scss';

export default function Tab(props) {
  return (
    <>
      <h3 className='tab_title'>{props.name}</h3>
      { useSelector((state) => state.tabs.tabs).length > 1 &&
        <button id={props.id} className='tab_close-btn'
        onClick={(e) => props.handlerCloseTab(e, props.id)}
        ></button>
      }
    </>
  );
}
