import React from 'react';

export default function PreviewLensList({currentDeviceLensArr, currentDeviceLens, handlerSelectLens}) {
    return (
        <form>
            {currentDeviceLensArr.map((lens, i) => (
                <div className='devices_menu-block-item' key={i}>
                    <input type='radio' id={lens} name={lens} value={lens} className='devices_menu-block-input'
                        checked={lens === currentDeviceLens ? true : false}
                        onChange={handlerSelectLens}
                    />
                    <label htmlFor={lens} className='devices_menu-block-label'>{lens}</label>
                </div>
            ))}
        </form>
    )
}
