import { useState } from 'react';

import LoginForm from './LoginForm';
import RegisterForm from './/RegisterForm';
import Verification from './Verification';
import AccountEmail from './AccountEmail';
import CodeVerificationStepOne from './CodeVerificationStepOne';
import CodeVerificationStepTwo from './CodeVerificationStepTwo';


const LoyoutAuth = () => {
    const [page, setPage] = useState(0)
    const [code, setCode] = useState(null)

    const handlePage = (num) => {
        setPage(num)
    }

    const handleCodeVerification = (code) => {
        setCode(code)
    }

    const pageDisplay = () => {
        switch (page) {
            case 0:
                return (<LoginForm handlePage={handlePage}/>)
            case 1:
                return (<RegisterForm handlePage={handlePage} />)
            case 2:
                return (<Verification handlePage={handlePage} />)
            case 3:
                return (<AccountEmail handlePage={handlePage} />)
            case 4:
                return (<CodeVerificationStepOne handlePage={handlePage} handleCodeVerification={handleCodeVerification}/>)
            case 5:
                return (<CodeVerificationStepTwo handlePage={handlePage} code={code} />)   
            default:
                break;
        }
    }

    return (
        <section className='LoyoutAuth-section'>
            {pageDisplay()}
        </section>

    )
}

export default LoyoutAuth;
