import React from 'react';
// import axios from 'axios';
import { connect } from 'react-redux';
import './Editor.css';
// import Modal from './assets/components/Modal/Modal';
import Header from './assets/components/header/Header';
import Tools from './assets/components/tools/Tools';
import Main from './assets/components/main/Main';
import { getIsSavingId } from './redux/reticles/reticles-selectors';
import { getTabs } from './redux/reticles/tabs-reducer';
import { addSavingID } from './redux/reticles/tabs-reducer';
import { deleteReticles} from './redux/reticles/reticles-operations';
import { changeCurrentReticle } from './redux/reticles/reticles-actions';
import { updateGridData } from './redux/grid/grid-reducer';

import ModalCard from './assets/components/Modal/ModalCard';
import '../src/assets/components/Modal/modalCard.scss';

class Editor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            move: false,
            circle: false,
            line: false,
            simpleLine: false,
            ruler: false,
            moa: true,
            mil: false,
            gridSize: 3,
            reticleName: 'My Reticle 1',
            // showModal: true,
            modalCardContent: [
                {
                    id: 1,
                    img: '',
                    name: 'THOR 1 THERMAL SCOPE SERIES',
                },
                {
                    id: 2,
                    img: '',
                    name: 'THOR 2 THERMAL SCOPE SERIES',
                },
                {
                    id: 3,
                    img: '',
                    name: 'THOR 3 THERMAL SCOPE SERIES',
                },
            ],
            duplicate: false,
            undo: false,
            redo: false,
            clear: false,
            sharedID: '',

            // svgCopy: ''
            // isExport: false
            openHeaderButton: '',
            showFilterModal: false,
            headerSearchValue: '',
            isUseFilters: false,
            isShapeDelete: false,
            isSelectedCard: false,
            selectedReticles: [],
            importReticle: '',
            importReticleName: ''
        }
    }

    svgData = '';
    svgDaraArr = {};
    // clearedData = '';

    handleImportReticleInfo = (reticle, name) => {
        this.setState({ importReticle: reticle, importReticleName: name })
    };

    handleClearImportReticleInfo = () => {
        this.setState({ importReticle: '', importReticleName: '' })
    };

    handleDeleteSelectedReticles = () => {

        const payload = {
            headers: {},
            data: {
                reticleId: this.state.selectedReticles
            }
        };

        this.state.selectedReticles.forEach(reticleId => {
            const reticleInTabs = this.props.currentTabObj.payload.tabs.tabs.find(el => el.savingId === reticleId);

            if (reticleInTabs) {
                if (this.props.activeTabId !== reticleInTabs.id) {

                    this.props.updateTabsData({ id: reticleInTabs.id, savId: '', status: '', publicId: '' });

                    let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: '', svg: reticleInTabs.svg, name: reticleInTabs.name, isActiveTab: false, status: '', publicId: '', });
                    sessionStorage.setItem(reticleInTabs.id, jsonData);


                } else if (this.props.activeTabId === reticleInTabs.id) {

                    this.props.changeCurrentReticle({ status: '', savingId: '', publicId: '' });
                    this.props.updateTabsData({ id: reticleInTabs.id, savId: '', status: '', publicId: '' });

                    let jsonData = JSON.stringify({ id: reticleInTabs.id, savingId: '', svg: reticleInTabs.svg, name: reticleInTabs.name, isActiveTab: true, status: '', publicId: '', });
                    sessionStorage.setItem(reticleInTabs.id, jsonData);

                }

            } else if (!reticleInTabs) {
                return
            }
        })
        this.props.deleteReticles(payload);

        this.setState({ isSelectedCard: false, selectedReticles: [] })
    };


    checkBoxChange = (e) => {
        let isSelected = e.target.checked

        let value = e.target.value

        if (isSelected) {
            this.setState({ selectedReticles: [...this.state.selectedReticles, value] })
        } else {
            this.setState({selectedReticles: this.state.selectedReticles.filter((retId => retId !== value))})
        }
    };


    handleChangeSelectedCardStatus = (action) => {
        this.setState({ isSelectedCard: !this.state.isSelectedCard })
        if(action === 'back'){
            this.setState({ selectedReticles: [] })
        }
    };


    toggleDeleteShape = () => {
        this.setState({ isShapeDelete: !this.state.isShapeDelete })
    };

    onChange = (e => {
        this.setState({ headerSearchValue: e.target.value, isUseFilters: false });
    });

    handleUseFilters = () => {
        this.setState({ isUseFilters: true, headerSearchValue: '' });
    }

    clearHeaderFilter = () => {
        this.setState({ headerSearchValue: '', isUseFilters: false })
    };


    handleOpenHeaderButton = (name) => {
        this.setState({ openHeaderButton: name})
    };

    handleToggleFilterModal = () => {
        this.setState({ showFilterModal: !this.state.showFilterModal })
    };

    handlerSelectMove = () => {
        this.setState({
            move: this.state.move = true,
            circle: this.state.circle = false,
            line: this.state.line = false,
            ruler: this.state.ruler = false,
            simpleLine: this.state.simpleLine = false
        })
    }

    handletSelectCircle = () => {
        this.setState({
            move: this.state.move = false,
            circle: this.state.circle = true,
            line: this.state.line = false,
            ruler: this.state.ruler = false,
            simpleLine: this.state.simpleLine = false
        })
    }

    handletSelectLine = () => {
        this.setState({
            move: this.state.move = false,
            circle: this.state.circle = false,
            line: this.state.line = true,
            ruler: this.state.ruler = false,
            simpleLine: this.state.simpleLine = false
        })
    }

    handletSelectSimpleLine = () => {
        this.setState({
            move: this.state.move = false,
            circle: this.state.circle = false,
            line: this.state.line = false,
            ruler: this.state.ruler = false,
            simpleLine: this.state.simpleLine = true
        })
    }

    // handlerSelectRuler = (val) => {
    //     this.setState({
    //         move: this.state.move = false,
    //         circle: this.state.circle = false,
    //         line: this.state.line = false,
    //         simpleLine: this.state.simpleLine = false,
    //         ruler: this.state.ruler = val
    //     })
    // }

    regClearSvg = (svg) => {
        let svgValueStr = svg.toString();

        let clearedSvgData = svgValueStr//.replace(/\sid="[^aimpoint]\w+\s?-?\w*"/gi, '')
            .replace(/\sid="svgCanvas-\w+-?\d*"/gi, '')
            .replace(/\sid="Svgjs\w+-?\d*"/gi, '')
            .replace(/\sid="square-\w+-?\d*"/gi, '')
            .replace(/\sid="rect-\w+-?\d*"/gi, '')
            .replace(/\sid="circle-\w+-?\d*"/gi, '')
            .replace(/\sid="line-\w+-?\d*"/gi, '')
            .replace(/\sid="triangle-\w+-?\d*"/gi, '')
            .replace(/\sid="romNumGroup-\w+-?\d*"/gi, '')
            .replace(/\sid="num-\w+-?\d*"/gi, '')
            .replace(/\sclass="\w+\s?-?\w+"/gi, '')
            .replace(/\sdata-[^axis]\w+="\w+\s?-?\w+"/gi, '')
            // .replace(/\sxmlns:svgjs="http://svgjs.dev/svgjs"/gi, '')
            .replace(/#ffffff/gi, '#fff')
            .replace(/#000000/gi, '#000')
            .replace(/#666666/gi, '#666')
            .replace(/#ff0000/gi, '#f00')
            .replace(/#ffaa00/gi, '#fa0')
            .replace(/#ffff00/gi, '#ff0')
            .replace(/#00ff00/gi, '#0f0')
            .replace(/#0000ff/gi, '#00f')
            .replace(/\.\d+/g, '')
            
        return clearedSvgData;
    }

    handlerGridType = (mil, moa) => {
        let gridUpdateObj = {
            id: this.props.activeTabId,
            gridType: moa ? 'moa' : 'mil',
            gridSize: this.state.gridSize
        }

        this.setState({
            mil: this.state.mil = mil,
            moa: this.state.moa = moa
        })
        
        this.props.updateGrid(gridUpdateObj);
    }

    postData = async (url, method) => {
            const response = await fetch(url, method)
            const result = await response
    }

    getData = async (url, method) => {
        const response = await fetch(url, method)
        const result = await response.text()
    }

    handlerGetGridSize = (type, value) => {
        let gSize = parseFloat(value.replace('MOA', '').replace('MIL', '').trim());
        let gridUpdateObj = {
            id: this.props.activeTabId,
            gridType: type,
            gridSize: gSize
        }
        
        this.setState({
            gridSize: this.state.gridSize = gSize
        })

        this.props.updateGrid(gridUpdateObj);
    }

    // getClearedData2Export = async (data) => {
    //     let res = await data;

    //     return this.clearedData = res;
    // }

    // handlerExport = async () => {
    //     this.setState({
    //         isExport: true
    //     })

    //     try {
    //         await this.getClearedData2Export();
    //         let exportObj = ({svgString: this.clearedData});

    //         axios.post('/reticle/export', exportObj)
    //         .then(response => {
    //             let link = document.createElement('a');
    //             link.download = this.state.reticleName ? `${this.props.activeTab.name}.atn` : 'My Reticle.atn';
    //             let blob = new Blob([response.data]);
    //             link.href = URL.createObjectURL(blob);
    //             link.click();
    //             URL.revokeObjectURL(link.href);
    //         });
    //     } catch {
    //         console.log('ERROR')
    //     }

    //     this.setState({
    //         isExport: false
    //     })

    //     // axios.post('/reticle/export', exportObj)
    //     //     .then(response => {
    //     //         let link = document.createElement('a');
    //     //         link.download = this.state.reticleName ? `${this.props.activeTab.name}.atn` : 'My Reticle.atn';
    //     //         let blob = new Blob([response.data]);
    //     //         link.href = URL.createObjectURL(blob);
    //     //         link.click();
    //     //         URL.revokeObjectURL(link.href);
    //     //     });

    //     // axios.get('/')
    //     //     .then(response => console.log('FROM SERVER', response))
    // }

    // handlerReticleRename = (name) => {
    //     if (this.state.reticleName !== name) {
    //         this.setState({
    //             reticleName: this.state.reticleName = name
    //         })
    //     }
    // }

    // onExportPreviewData = () => {
    //     axios.post('/preview', {svg: this.svgData, fileName: this.state.reticleName})
    // }

    // toggleModal = () => {
    //     this.setState(({ showModal }) => ({
    //         showModal: !showModal,
    //     }));
    // };


    // componentDidUpdate() {
        // console.log(' Update.svgData', this.svgData)
            // setInterval(() => {
        // if (this.state.currentShapesID.length !== 0 && this.props.loginStatus && this.props.activeTab.savingId === '') {
        //             const reticle = {
        //                 name: this.props.activeTab.name,
        //                 reticleImg: this.svgData,
        //                 shapesList: this.state.currentShapesID
        //             }
        //             console.log('reticle', reticle)
        //             this.props.addReticle(reticle);
        //         } else return   
            // }, 20000)
    // }
    
    onDuplicate = () => {
        this.setState({
            duplicate: this.state.duplicate = true
        })
    }

    onUndo = () => {
        this.setState({
            undo: this.state.undo = true,
            redo: this.state.redo = false
        })
    }

    onRedo = () => {
        this.setState({
            undo: this.state.undo = false,
            redo: this.state.redo = true
        })
    }

    onClear = () => {
        this.setState({
            clear: this.state.clear = true,
        })
    }

    duplicateDeactivate = () => {
        this.setState({
            duplicate: this.state.duplicate = false,
        })
    }

    undoRedoDeactivate = () => {
        this.setState({
            undo: this.state.undo = false,
            redo: this.state.redo = false
        })
    }

    clearDeactivate = () => {
        this.setState({
            clear: this.state.clear = false,
        })
    }

    clearImportData = () => {
        // this.setState({
        //     importReticle: ''
        // })
        this.handleClearImportReticleInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.activeLib !== prevProps.activeLib || this.props.activeLink !== prevProps.activeLink || this.props.isReticleCardOpen) && this.state.isSelectedCard){
            this.setState({ isSelectedCard: false, selectedReticles: [] })
        }
        try {
            if (this.state.activeTabId !== this.props.activeTabId) {
                this.setState({
                    activeTabId: this.props.activeTabId
                })
    
                this.props.currentTabObj.payload.grid.gridData.forEach(element => {
                    if (element.id === this.props.activeTabId) {
                        if (element.gridType === 'moa' && this.state.mil) {
                            this.setState({
                                moa: true,
                                mil: false
                            })
                        }
    
                        if (element.gridType === 'mil' && this.state.moa) {
                            this.setState({
                                moa: false,
                                mil: true
                            })
                        }
                    }
                });
            }
        } catch(e) {
            console.log(e);
        }
    }

    render() {
       const { showModal } = this.state
       
        return(
            <article className="main-article">
                {/* <div className='modal-container'> */}
                <Header
                    docName={this.state.reticleName}
                    handlerAddTab={this.props.handlerAddTab}
                    handlerCloseTab={this.props.handlerCloseTab}
                    activeTab={this.props.activeTab}
                    reticles={this.props.reticles}
                    activeTabId={this.props.activeTabId}
                    handlerActiveTab={this.props.handlerActiveTab}
                    activeLink={this.props.activeLink}
                    handlerActivateButton={this.props.handlerActivateButton}
                    // desktopDevice={this.props.desktopDevice}
                    deviceWidth={this.props.deviceWidth}
                    handleOpenHeaderButton={this.handleOpenHeaderButton}
                    openHeaderButton={this.state.openHeaderButton}
                    handleToggleFilterModal={this.handleToggleFilterModal}
                    value={this.state.headerSearchValue}
                    onChange={this.onChange}
                    clearHeaderFilter={this.clearHeaderFilter}
                    handleReticleCardOpen={this.props.handleReticleCardOpen}
                    isReticleCardOpen={this.props.isReticleCardOpen}
                />
                <Tools
                    instruments={this.state}
                    onSelectChangeMove={this.handlerSelectMove}
                    onSelectChangeCircle={this.handletSelectCircle}
                    onSelectChangeLine={this.handletSelectLine}
                    onSelectChangeSimpleLine={this.handletSelectSimpleLine}
                    onSelectRuler={this.handlerSelectRuler}
                    onSelectGrid={this.handlerGridType}
                    onExportingTool={this.props.onExportingTool}
                    onGetGridSize={this.handlerGetGridSize}
                    onExportPreviewData={this.onExportPreviewData}
                    toggleModal={this.toggleModal}
                    focus={this.props.focus}
                    blur={this.props.blur}
                    duplicate={this.onDuplicate}
                    undo={this.onUndo}
                    redo={this.onRedo}
                    clear={this.onClear}
                    gridSize={this.state.gridSize}
                    handlerReticleRename={this.props.handlerReticleRename}
                    activeTab={this.props.activeTab}
                    getIsSavingId={this.props.getIsSavingId}
                    activeLink={this.props.activeLink}
                    handlerActivateButton={this.props.handlerActivateButton}
                    focusedInput={this.props.focusedInput}
                    toggleDeleteShape={this.toggleDeleteShape}
                    isSelectedCard={this.state.isSelectedCard}
                    handleChangeSelectedCardStatus={this.handleChangeSelectedCardStatus}
                    activeLib={this.props.activeLib}
                    handleDeleteSelectedReticles={this.handleDeleteSelectedReticles}
                    handleImportReticleInfo={this.handleImportReticleInfo}
                    handlerAddTab={this.props.handlerAddTab}
                />
                <Main
                    instruments={this.state}
                    svgUpdateData={this.props.svgUpdateData}
                    onSelectChangeMove={this.handlerSelectMove}
                    focus={this.props.focus}
                    blur={this.props.blur}
                    focusedInput={this.props.focusedInput}
                    duplicateDeactivate={this.duplicateDeactivate}
                    undoRedoDeactivate={this.undoRedoDeactivate}
                    clearDeactivate={this.clearDeactivate}
                    getCurrentID={this.props.getCurrentID}
                    shapes={this.props.shapes}
                    getClearedData2Export={this.props.getClearedData2Export}
                    isExport={this.props.isExport}
                    reticlesID={this.props.reticlesID}
                    activeTabId={this.props.activeTabId}
                    activeTab={this.props.activeTab}
                    setCleanedSvg={this.props.setCleanedSvg}
                    firstSave={this.props.firstSave}
                    disableFirstSave={this.props.disableFirstSave}
                    handlerActivateButton={this.props.handlerActivateButton}
                    activeLink={this.props.activeLink}
                    onExportingTool={this.props.onExportingTool}
                    showFilterModal={this.state.showFilterModal}
                    handleToggleFilterModal={this.handleToggleFilterModal}
                    openHeaderButton={this.state.openHeaderButton}
                    headerSearchValue={this.state.headerSearchValue}
                    clearHeaderFilter={this.clearHeaderFilter}
                    handleUseFilters={this.handleUseFilters}
                    isUseFilters={this.state.isUseFilters}
                    onChangeHeaderSearchValue={this.onChange}
                    handleReticleCardOpen={this.props.handleReticleCardOpen}
                    isReticleCardOpen={this.props.isReticleCardOpen}
                    handlerAddTab={this.props.handlerAddTab}
                    deviceWidth={this.props.deviceWidth}
                    handleOpenCard={this.props.handleOpenCard}
                    reticle={this.props.reticle}
                    openCard={this.props.openCard}
                    closeOpenCard={this.props.closeOpenCard}
                    handleCloseCard={this.props.handleCloseCard}
                    handleActiveLib={this.props.handleActiveLib}
                    handleActiveLibWithChangeFolder={this.props.handleActiveLibWithChangeFolder}
                    activeButton={this.props.activeButton}
                    activeLib={this.props.activeLib}
                    burgerButtonName={this.props.burgerButtonName}
                    handleClearActiveLib={this.props.handleClearActiveLib}
                    isShapeDelete={this.state.isShapeDelete}
                    toggleDeleteShape={this.toggleDeleteShape}
                    handleChangeSelectedCardStatus={this.handleChangeSelectedCardStatus}
                    isSelectedCard={this.state.isSelectedCard}
                    checkBoxChange={this.checkBoxChange}
                    selectedReticles={this.state.selectedReticles}
                    importReticle={this.state.importReticle}
                    clearImportData={this.clearImportData}
                    appVersion={this.props.appVersion}
                />
                    {/* {showModal && (
                        <Modal toggleModal={this.toggleModal}>
                             <div className='modal-section'>
                                <h1 className='modal-text'>Choose your device</h1>
                                <p className='modal-text'>You can always change it later</p>
                                <ul className='modal-list'>
                                    {this.state.modalCardContent.map(device => (
                                        <ModalCard key={device.id} device={device} />
                                    ))}
                                </ul>
                                <p className='modal-text'>Your reticle will work with any XSight 5 or ThOR 5 devices.
                                    Device selection will only affect the preview during editing.</p>
                            </div> 
                         </Modal>
                    )}  */}
                {/* </div> */}
            </article>
        )
    }
}

// export default Editor;
const mapStateToProps = state => ({
    getIsSavingId: getIsSavingId(state),
    currentTabObj: getTabs(state),
});

const mapDispatchToProps = dispatch => ({
    updateTabsData: data => dispatch(addSavingID(data)),
    updateGrid: data => dispatch(updateGridData(data)),
    deleteReticles: data => dispatch(deleteReticles(data)),
    changeCurrentReticle: data => dispatch(changeCurrentReticle(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
