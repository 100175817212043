import { useState, useCallback } from 'react';
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login, googleLogin } from '../../../../redux/auth/auth-operations';
import { authError, authLoginError } from '../../../../redux/auth/auth-selectors';
import { useGoogleLogin } from '@react-oauth/google';
import { useFormik } from "formik";
import * as Yup from 'yup';
// import { GoogleLogin } from '@react-oauth/google';

import { createUseStyles } from "react-jss";
import imgVisible from '../../../img/input_btn-visibility.svg';
import imgNotVisible from '../../../img/input_btn-notVisible.svg';

const useStales = createUseStyles({
    loginForm: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    loginInputs: {
        width: 264,
        textAlign: 'start',
        marginLeft: 'auto',
        marginRight: 'auto',       
    },
    loginInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#AAAAAA',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#337AA6',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',
        },
    },
    errorLoginInput:{
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#B5463C',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#B5463C',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',
        },

    },
    loginBtn: {
        width: 264,
        height: 40,
        backgroundColor: '#337AA6',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

        '&:hover': {
            backgroundColor: '#225A7C',
            border: '1px solid #225A7C',
        },

        '&:active': {
            backgroundColor: '#16405A',
            border: '1px solid #16405A'
        }
    },
    googleBtn: {
        width: 264,
        height: 40,
        backgroundColor: 'transparent',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

        '&:hover': {
            backgroundColor: '#225A7C',
            border: '1px solid #225A7C',
        },

        '&:active': {
            backgroundColor: '#16405A',
            border: '1px solid #16405A'
        }
    },

    loginLink: {
        margin: 0,
        textAlign: 'end',
        color: '#337AA6'
    },
    ftr: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 35,
    },
    showBtn: {
        right:15,
        top: 25,
        position: 'absolute',
        backgroundImage: (data) => data.shown ? `url(${imgVisible})` : `url(${imgNotVisible})`,
        backgroundSize: 'cover',
        border: 'none',
        outline: 'none',
        width: 15,
        height: 10,
        backgroundColor: 'inherit',
        backgroundPosition: 'center',
    },
    loginText: {
        width: 264,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    loginError: {
        color: '#B5463C',
        margin: 0,
        fontSize: 13
    },
    loginSignUpBtn: {
        border: 'none',
        outline: 'none',
        backgroundColor: 'inherit',
        cursor: 'pointer',
    },

})

const LoginForm = ({ handlePage }) => {
    const dispatch = useDispatch();
    const [shown, setShown] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().min(4, 'Password is too short - should be 4 symbols minimum.').max(20, 'Password is too long - should be 20 symbols maximum.').required('Password is required'),
        }),
        onSubmit: values => {
            const payload = {
                email: formik.values.email,
                password: formik.values.password,
            };
            dispatch(login(payload));
        },
    }); 

    const classes = useStales({shown: shown});
        
    const loginError = useSelector(authLoginError);
    // const loginError = useSelector(authError);

    const googleLoginResponse = useGoogleLogin({
        onSuccess: tokenResponse => {
            // console.log('tokenResponse', tokenResponse)          
            const response = tokenResponse.access_token
            googleLoginSubmit(response)
        },
        onError: () => console.log('Login Failed'),
    });

    const googleLoginSubmit = useCallback(token => {
    const payload = {
        refreshToken: token,
    };    
        dispatch(googleLogin(payload));
    }, [dispatch]
    );

    const loginErrorMessage = () => {
        // console.log('loginError[0]', loginError)
        if (Array.isArray(loginError) && loginError[0] === 'email must be an email') {
            return 'Invalid mail format'
        } else if (loginError === 'USER_NOT_FOUND') {
            return "Couldn't find your account"
        }  else if (loginError === 'Uncooperative password') {
            return 'Wrong password. Try again or click Forgot password to reset it.'
        }
    }

    return (
        <>
            <div>
                    <div className={classes.loginForm}>
                        <h2>Log in required</h2>
                        <p className={classes.loginText}>Log in with ATN ID or your Google account to get access to your saved reticles</p>
                            {loginError ? <p className={classes.loginError}>{loginErrorMessage()}</p> : null }
                            {formik.touched.email && formik.errors.email ? (<p className={classes.loginError}>{formik.errors.email}</p>) : null}
                            {formik.touched.password && formik.errors.password ? (<p className={classes.loginError}>{formik.errors.password}</p>) : null}

                        <form onSubmit={formik.handleSubmit}>
                            <div className={classes.loginInputs}>

                                <label htmlFor='LoginEmail'>Email</label>
                            <input id='LoginEmail' className={(formik.touched.email && formik.errors.email) ? classes.errorLoginInput : classes.loginInput}
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                />
                                <label htmlFor='LoginPassword'>Password</label>
                                <div style={{ position: 'relative' }}>
                                <input id='LoginPassword' className={(formik.touched.password && formik.errors.password) ? classes.errorLoginInput : classes.loginInput} style={{marginBottom: 0}}
                                    type={shown ? "text" : "password"}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    />
                                    <button type="button" className={classes.showBtn} onClick={() => setShown(!shown)}></button>
                                </div>
                            <button style={{ padding: '5px 0px', marginLeft: 152 }} type="button" className={classes.loginSignUpBtn} onClick={() => handlePage(3)}>
                                <p style={{ color: '#337AA6', margin: 0 }}>Forgot password?</p>
                            </button>
                            </div>
                        <div style={{  width: 264, textAlign: 'start', marginLeft: 'auto', marginRight: 'auto' }}>
                            <button type="submit" className={classes.loginBtn}>Login</button>

                            <button type="button" onClick={() => googleLoginResponse()} className={classes.googleBtn}>Sign in with Google</button>
                            </div>
                        {/* <div style={{ marginLeft: 16, marginTop: 20 }}>
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                        const payload = {
                                            token: credentialResponse.credential,
                                        };

                                        dispatch(googleLogin(payload));

                                console.log(credentialResponse);
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            // size="medium"
                            text="signin"
                            width='240'
                            // type= "icon"
                            theme= "filled_black"
                        /> 
                        </div> */}
                        </form>

                        <div className={classes.ftr}>
                            <p style={{ marginTop: 0 }}>Don’t have an account yet?</p>
                        <button className={classes.loginSignUpBtn} onClick={() => handlePage(1)}>
                            <p style={{ color: '#337AA6', marginTop: 0 }}>Sign up</p>
                            </button>
                        </div>
                    </div>
            </div>         
        </>
    )
}


export default LoginForm;
