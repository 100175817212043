import React from 'react';
import defaultUserPhoto from '../../../img/default-user-photo.svg';
import { useSelector } from 'react-redux';
import { getIsAuthenticated, getUserFirstName, getUserUrl } from '../../../../redux/auth/auth-selectors';

import './lib_section.scss';
import './libMenuSection.scss';
import './libParameters.scss';

const LibMenuSection = ({ handlerActivateButton, activeLink }) => {
    const profPic = useSelector(getUserUrl);
    const loginStatus = useSelector(getIsAuthenticated);
    const firstName = useSelector(getUserFirstName);

    return (
        <>
            <section className='menu-section_btn-block'>
                <button onClick={() => activeLink === '1' ? handlerActivateButton('') : handlerActivateButton('1')} className={`lib-btn focal-planes_btn ${activeLink === '1' ? 'lib-btn_active focal-planes_btn-active' : ''}`} >Editor</button>
                <button onClick={() => activeLink === '2' ? handlerActivateButton('') : handlerActivateButton('2')} className={`lib-btn public-library_btn ${activeLink === '2' ? 'lib-btn_active public-library_btn-active' : ''}`} >Libraries</button>

            </section>
            <section className='menu-secction_user'>
                {!loginStatus ? (
                    <button onClick={() => activeLink === '3' ? handlerActivateButton('') : handlerActivateButton('3')} className={`lib-btn log-in_btn ${activeLink === '3' ? 'lib-btn_active log-in_btn-active ' : ''}`}>Profile</button>
                ) : (
                        <button onClick={() => activeLink === '3' ? handlerActivateButton('') : handlerActivateButton('3')} className={`lib-btn log-in_userBtn ${activeLink === '3' ? 'lib-btn_active' : ''}`}>
                            <img onClick={() => activeLink === '3' ? handlerActivateButton('') : handlerActivateButton('3')} src={profPic ? profPic : defaultUserPhoto} alt='User avatar' className='user-photo' />
                            {firstName}
                        </button>
                    )
                }
            </section>
        </>
    )
}

export default LibMenuSection;
