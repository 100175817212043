import React from 'react';
import Share from './../tools/create_section/Share';

import './export.scss';

export default function Export({ onExportingTool, activeTab }) {
  return (
    <section className='preview_export-section'>
      <Share activeTab={activeTab}/>
      <button id='exportBtn' className='preview_section-btn export-btn' onClick={() => { onExportingTool() }}>Download</button>
    </section>
  );
}
