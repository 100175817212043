import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    colotThemeData: [{id: 'tab-0', isNightMode: false, colorTheme: '#fff'}],
    currentColorTheme: '#fff',
    currentTheme: 'day'
}

export const colorThemeSlice = createSlice ({
    name: 'colorTheme',
    initialState,
    reducers: {
        addColorThemeData: (state, colorThemeObj) => {
            state.colotThemeData = [...state.colotThemeData, {id: colorThemeObj.payload.id, isNightMode: false, colorTheme: '#fff'}];
            state.currentColorTheme = '#fff';
            state.currentTheme = 'day';

            let jsonThemeData = JSON.stringify([...state.colotThemeData]);
            sessionStorage.setItem(`colorThemes`, jsonThemeData);
        },
        deleteColorThemeData: (state, id) => {
            let newColorThemeData = state.colotThemeData.filter(el => el.id !== id.payload);

            state.colotThemeData = newColorThemeData;
            state.currentColorTheme = state.colotThemeData[state.colotThemeData.length - 1].colorTheme;
            state.currentTheme = state.currentColorTheme === '#fff' ? 'day' : 'night';

            let jsonThemeData = JSON.stringify([...state.colotThemeData]);
            sessionStorage.setItem(`colorThemes`, jsonThemeData);
        },
        editColorThemeData: (state, colorThemeObj) => {
            let editedColotThemeArr = state.colotThemeData.map(themeEl => {
                if (themeEl.id === colorThemeObj.payload.id) {
                    themeEl.isNightMode = colorThemeObj.payload.isNightMode;
                    themeEl.colorTheme = colorThemeObj.payload.colorTheme;

                    return themeEl;
                } else {
                    return themeEl;
                }
            })

            state.colotThemeData = editedColotThemeArr;
            state.currentColorTheme = colorThemeObj.payload.colorTheme;
            state.currentTheme = state.currentColorTheme === '#fff' ? 'day' : 'night';

            let jsonThemeData = JSON.stringify([...state.colotThemeData]);
            sessionStorage.setItem(`colorThemes`, jsonThemeData);
        },
        setCurrentThemeData: (state, id) => {
            let setCurrentColotThemeArr = state.colotThemeData.find(themeEl => themeEl.id === id.payload);

            if (setCurrentColotThemeArr && setCurrentColotThemeArr.length) {
                state.currentColorTheme = setCurrentColotThemeArr.currentColorTheme;
                state.currentTheme = setCurrentColotThemeArr.currentTheme;
            }
        },
        getColorThemeData: state => state.colotTheme,
        updateThemesAfterRefrefh: (state, arr) => {
            state.colotThemeData = arr.payload[0];

            let setCurrentColotThemeArr = state.colotThemeData.find(themeEl => themeEl.id === arr.payload[1]);

            state.currentColorTheme = setCurrentColotThemeArr.currentColorTheme;
            state.currentTheme = setCurrentColotThemeArr.currentTheme;
        }
    }
})

export const {
    addColorThemeData,
    deleteColorThemeData,
    editColorThemeData,
    getColorThemeData,
    setCurrentThemeData,
    updateThemesAfterRefrefh,
} = colorThemeSlice.actions

export default colorThemeSlice.reducer;
