import React from 'react';
import { Link } from "react-router-dom";
import '../../scss/header/logo.scss';
import logo from '../../img/logo.svg';

class Logo extends React.Component {
    render() {
        return (
            <>
                <Link to="/">
                    <img src={logo} alt='ATN logo' className='logo_img' />
                </Link>
                <h1 className='logo_title'>Reticle Editor</h1>
            </>
        )
    }
}

export default Logo;
