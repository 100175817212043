import React from 'react';
import LibSection from '../main/lib_section/Lib_section';
import CanvasSection from '../main/canvas_section/Canvas_section';
import EditSection from '../main/edit_section/Edit_section';
import './main.scss';

class Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedShape: '',
            selectedShapes: [],
            groupedShapes: '',
            canvasWidth: 0,
            canvasHeight: 0,
            x: 0,
            y: 0,
            shapeW: 0,
            shapeH: 0,
            editedW: 0,
            editedH: 0,
            editedL: 0,
            distance: 0,
            strokeDasharray: 'null',
            isStrokeTypeChanged: false,
            strokeColor: '#000000',
            isStrokeColorSet: false,
            prevStrokeColor: '#000000',
            shapeStrokeColor: '#000000',
            strokeWidth: 1,
            fillColor: '#ffffff',
            isFillColorSet: false,
            prevFillColor: '#ffffff',
            shapeFillColor: '#ffffff',
            opacity: 0,
            prevOpacity: 0,
            align: false,
            undoSteps: [],
            canvasShapes: [],
            // currentShapesID: [],
            deletedIndex: 0,
            startX: 0,
            startY: 0,
            lineStartX: 0,
            lineStartY: 0,
            degree: 0,
            endX: 0,
            endY: 0,
            pxLength: 0,
            gridLength: 0,
            radius: 0,
            editedR: 0,
            focalPlane: 'off',
            fpSwitched: false,
            isSelectedShapeScaleble: false,
            shapesDataMap: new Map(),
            currentMarker: '',
            mil: false,
            moa: true,
            gridChanged: true,
            moaAvg: 3,
            milAvg: 10,
            gridSize: 3,
            isMarkerEdit: false,
            markerX: 0,
            markerY: 0,
            shapeType: '',
            isGroupingActive: false,
            selectedLayerID: '',
            duplicate: false,
            undo: false,
            redo: false,
            clearCanvas: false,
            activeShapeButton: {name: 'move', direction: ''},
            renameData: {id: '', name: ''},
            isEdit: false,
            // activeLink: '1',
            isEditParam: false,
            lastEditParam: '',
            axisSwitch: false,
            isActiveFP: false,
            isLayerMove: false,
            btnRightRotate: false,
            btnLeftRotate: false,
            isAllShapesFill: false
        }
    }

    map = new Map();

    // handlerActivateButton = (num) => this.setState({
    //     activeLink: this.state.activeLink = num
    // })


    convertPathData = (data) => {
        let arr = data.replace('M', '').replace(/L/gi, ' ').replace('Z', '').split(' ')

        let arr2 = []

        arr.forEach(el => {
            if (el) {
                arr2.push(el)
            }
        })

        let [m1, m2, l1, l2] = arr2
        let x1 = parseInt(m1)
        let y1 = parseInt(m2)
        let x2 = parseInt(l1)
        let y2 = parseInt(l2)

        return [x1, y1, x2, y2]
    }

    presetData = (r, w, h, l) => {
        this.setState({
            radius: this.state.radius = r,
            shapeW: this.state.shapeW = w,
            shapeH: this.state.shapeH = h,
            gridLength: this.state.gridLength = l
        })
    }

    getDistance = (val) => {
        let gridDistance = this.state.moa ? val * this.state.moaAvg : val * this.state.milAvg

        this.setState({
            distance: this.state.distance = gridDistance
        })
    }

    getSelectedShapesType = (bool) => {
        if (this.state.isAllShapesFill !== bool) {
            this.setState({
                isAllShapesFill: bool
            })
        }
    }

    getSelectedShapes = (shapes) => {
        if (this.state.selectedShapes !== shapes) {
            this.setState({
                selectedShapes: this.state.selectedShapes = shapes
            })
        }
    }

    setGroupedShapes = (value) => {
        this.setState({
            groupedShapes: this.state.groupedShapes = value
        })
    }

    fillShapeColor = (val) => {
        this.setState({
            fillColor: this.state.fillColor = val,
            prevFillColor: this.state.prevFillColor = val,
            align: this.state.align = !this.state.align,
            shapeFillColor: this.state.shapeFillColor = val,
        })

        if (this.state.selectedShapes.length && this.state.activeShapeButton.name === 'move') {
            this.state.selectedShapes.forEach(el => {
                if (el.fill() !== val) {
                    this.setState({
                        isFillColorSet: this.state.isFillColorSet = true
                    })

                    if (!el.hasClass('mouseMove') && !el.hasClass('mouseSelect')) {
                        el.fill(val);
                    }
                }
            })
        } else if (this.state.selectedShape && this.state.activeShapeButton.name === 'move') {
            if (this.state.selectedShape.fill() !== val) {
                this.setState({
                    isFillColorSet: this.state.isFillColorSet = true
                })

                this.state.selectedShape.fill(val);
            }
        }
    }

    fillShapeOpacity = (val) => {
        this.setState({
            opacity: this.state.opacity = val,
            align: this.state.align = !this.state.align,
        })

        if (this.state.selectedShapes.length > 0 && this.state.activeShapeButton.name === 'move') {
            this.state.selectedShapes.forEach(el => {
                if (!el.hasClass('mouseSelect')) {
                    el.attr({'fill-opacity': val / 100})
                }
            })
        } else if (this.state.selectedShape && this.state.activeShapeButton.name === 'move') {
            this.state.selectedShape.attr({'fill-opacity': val / 100})
        }
    }

    switchOffisFillColorSet = () => {
        this.setState({
            isFillColorSet: this.state.isFillColorSet = false
        })
    }

    fillShapePrevOpacity = (val) => {
        if(this.state.opacity !== val) {
           this.setState({
            //    prevOpacity: this.state.prevOpacity = val,
               opacity: this.state.opacity = val,
        })  
        }
    }

    fillShapePrevColor = (val) => {
        if (this.state.prevFillColor !== val) {
            this.setState({
                //    prevOpacity: this.state.prevOpacity = val,
                fillColor: this.state.fillColor = val,
                prevFillColor: this.state.prevFillColor = val
            })
        }
    }

    resetStrokeTypeChanged = () => {
        if (this.state.isStrokeTypeChanged) {
            this.setState({
                isStrokeTypeChanged: false
            })
        }
    }

    setStrokeColor = (val) => {
        this.setState({
            strokeColor: this.state.strokeColor = val,
            align: this.state.align = !this.state.align,
            shapeStrokeColor: this.state.shapeStrokeColor = val
        })

        if (this.state.selectedShapes.length > 0 && this.state.activeShapeButton.name === 'move') {
            this.state.selectedShapes.forEach(el => {
                if (el.stroke() !== val) {
                    this.setState({
                        isStrokeColorSet: true
                    })
                }
                
                if (!el.hasClass('mouseSelect')) {
                    if (el.type === 'g') {
                        el.each(function(i, children) {
                            this.stroke({color: val})
                        })
                    } else {
                        el.stroke({color: val})
                    }
                }
            })
        } else if (this.state.selectedShape && this.state.activeShapeButton.name === 'move') {
            if (this.state.selectedShape.stroke() !== val) {
                this.setState({
                    isStrokeColorSet: true
                })

                this.state.selectedShape.stroke({ color: val})
            }
        }
    }

    switchOffisStrokeColorSet = () => {
        this.setState({
            isStrokeColorSet: this.state.isStrokeColorSet = false
        })
    }

    setStrokeType = (val) => {
        if(val){
            this.setState({
                strokeDasharray: val,
                isStrokeTypeChanged: true,
            })
        }
    }

    setStrokeWidth = (val) => {
        this.setState({
            strokeWidth: this.state.strokeWidth = val.strokeWidth,
            align: this.state.align = !this.state.align,
        })

        if (this.state.selectedShapes.length > 1 && this.state.activeShapeButton.name === 'move') {
            this.state.selectedShapes.forEach(el => {
                if (!el.hasClass('mouseSelect')) {
                    el.stroke({ width: val.pxStrokeWidth })
                }
            })
        } else if (this.state.selectedShape && this.state.activeShapeButton.name === 'move') {
            this.state.selectedShape.stroke({ width: val.pxStrokeWidth })
        }
    }

    getSelectedShapeWidth = (width) => {
        if (this.state.strokeWidth !== width) {
            this.setState({
                strokeWidth: this.state.moa ? this.state.strokeWidth = width / 3 : this.state.strokeWidth = width / 10,
            })
        }
    }

    btnRightRotater = () => {
        if (!this.state.btnRightRotate) {
            this.setState({
                btnRightRotate: true
            })
        }
    }

    btnLeftRotater = () => {
        if (!this.state.btnLeftRotate) {
            this.setState({
                btnLeftRotate: true
            })
        }
    }

    getSelectedShapeStrokeColor = (color) => {
        if (this.state.strokeColor !== color) {
            this.setState({
                strokeColor: this.state.strokeColor = color,
                prevStrokeColor: this.state.prevStrokeColor = color,
            })
        }
    }

    setActiveShapeButton = (data) => {
        this.setState({
            activeShapeButton: this.state.activeShapeButton = data,
        })
    }

    getCanvasShapesData = (data) => {
        this.setState({
            canvasShapes: this.state.canvasShapes = data
        })
    }

    getNewCoords = (data) => {
        this.setState({
            startX: this.state.startX = data.startX,
            startY: this.state.startY = data.startY,
            endX: this.state.endX = data.endX,
            endY: this.state.endY = data.endY
        })
    }

    getNewShapeSize = (w, h, bool) => {
        if (this.state.shapeW !== w || this.state.shapeH !== h) {
            this.setState({
                shapeW: this.state.shapeW = w,
                shapeH: this.state.shapeH = h,
                isMarkerEdit: this.state.isMarkerEdit = bool
            })
        }

        this.state.selectedShape.size(this.state.shapeW, this.state.shapeH)
    }

    getNewCircleData = (data) => {
        if (this.state.radius !== data) {
            this.setState({
                radius: this.state.radius = data,
            })
        }
        this.state.selectedShape.attr({ r: this.state.radius })
    }

    getFocalPlanesData = (data) => {
        if (this.state.focalPlane !== data) {
            this.setState({
                focalPlane: this.state.focalPlane = data
            })
        }

        if (data === 'on' && !this.state.isActiveFP) {
            this.setState({
                isActiveFP: this.state.isActiveFP = true
            })
        }

        if (!this.state.fpSwitched) {
            this.setState({
                fpSwitched: this.state.fpSwitched = true
            })
        }
    }

    disablefpSwitcher = () => {
        if (this.state.fpSwitched) {
            this.setState({
                fpSwitched: this.state.fpSwitched = false
            })
        }
    }

    getFocalPlaneName = (data) => {
        // console.log('NAME', data)
    }

    fpSwitchOff = () => {
        if (this.state.isActiveFP) {
            this.setState({
                isActiveFP: this.state.isActiveFP = false
            })
        }
    }

    getMarkersData = (data) => {
        if (this.state.currentMarker !== data) {
            this.setState({
                currentMarker: data,
            })
        }
    }

    getNewMarkerCoords = (x, y, bool) => {
        if (x !== this.state.markerX || y !== this.state.markerY || bool !== this.state.isMarkerEdit) {
            this.setState({
                markerX: this.state.markerX = x,
                markerY: this.state.markerY = y,
                isMarkerEdit: this.state.isMarkerEdit = bool
            })
        }
    }

    // getCurrentID = (arr) => {
    //     if (this.state.currentShapesID !== arr) {
    //         this.setState({
    //             currentShapesID: this.state.currentShapesID = arr
    //         })
    //     }
    // }

    getDeletedIndex = (i) => {
        if (this.state.deletedIndex !== i) {
            this.setState({
                deletedIndex: this.state.deletedIndex = i
            })
        }
    }

    getGroupedData = (bool) => {
        if (this.state.isGroupingActive !== bool) {
            this.setState({
                isGroupingActive: this.state.isGroupingActive = bool
            })
        }
    }


    getSelectedShape = (value) => {
        if (value !== null) {
            if (value === '') {
                this.setState({
                    selectedShape: this.state.selectedShape = value
                })
            } else {
                let el = document.querySelector(`#${value.node.attributes.id.nodeValue}`);
    
                if (this.state.selectedShape !== value) {
                    this.setState({
                        selectedShape: this.state.selectedShape = value,
                        canvasWidth: this.state.canvasWidth = value.node.farthestViewportElement.width.baseVal.value,
                        canvasHeight: this.state.canvasHeight = value.node.farthestViewportElement.height.baseVal.value
                    })
                }
    
                switch (value.node.attributes.class.value.replace('selected', '').trim()) {
                    // let el = document.querySelector(`#${e.target.id}`)
                    // console.log('SELECTED: ', el.getBoundingClientRect().width)
                    case 'circle':
                        if (this.state.shapeW !== el.getBoundingClientRect().width || this.state.shapeH !== el.getBoundingClientRect().height) {
                            this.setState({
                                shapeW: this.state.shapeW = el.getBoundingClientRect().width,
                                shapeH: this.state.shapeH = el.getBoundingClientRect().height,
                                x: this.state.x = value.node.attributes.cx.nodeValue,
                                y: this.state.y = value.node.attributes.cy.nodeValue
                            })
                        }
                        break
                    case 'line':
                        // let pathData = value.node.attributes.d.nodeValue.replace('M', '').replace('L', '').split(' ');
                        // let [m1, m2, l1, l2] = value.node.attributes.d.nodeValue
                        //     .replace('M', '')
                        //     .replace('L', ' ')
                        //     .split(' ');
                        // let x1 = parseInt(m1)
                        // let y1 = parseInt(m2)
                        // let x2 = parseInt(l1)
                        // let y2 = parseInt(l2)
                        let x1 = this.convertPathData(value.node.attributes[0].nodeValue)
                        let y1 = this.convertPathData(value.node.attributes[1].nodeValue)
                        // let x2 = this.convertPathData(value.node.attributes[2].nodeValue)
                        // let y2 = this.convertPathData(value.node.attributes[3].nodeValue)
    
                        this.setState({
                            shapeW: this.state.shapeW = el.getBoundingClientRect().width,
                            shapeH: this.state.shapeH = el.getBoundingClientRect().height,
                            x: this.state.x = x1,
                            y: this.state.y = y1
                        })
                        break
                    case 'shape':
                        if (this.state.shapeW !== el.getBoundingClientRect().width && this.state.shapeH !== el.getBoundingClientRect().height) {
                            this.setState({
                                shapeW: this.state.shapeW = el.getBoundingClientRect().width,
                                shapeH: this.state.shapeH = el.getBoundingClientRect().height,
                            })
                        }
                        break
                    case 'group':
                        if (this.state.shapeW !== el.getBoundingClientRect().width && this.state.shapeH !== el.getBoundingClientRect().height) {
                            this.setState({
                                shapeW: this.state.shapeW = el.getBoundingClientRect().width,
                                shapeH: this.state.shapeH = el.getBoundingClientRect().height,
                            })
                        }
                        break
                    default:
                        // if (this.state.shapeW !== el.getBoundingClientRect().width && this.state.shapeH !== el.getBoundingClientRect().height) {
                        //     this.setState({
                        //         shapeW: this.state.shapeW = el.getBoundingClientRect().width,
                        //         shapeH: this.state.shapeH = el.getBoundingClientRect().height,
                        //     })
                        // }
                }
            }
        }
    }

    getLineLength = (data) => {
        if (this.state.pxLength !== data) {
            this.setState({
                pxLength: this.state.pxLength = +data
            })
        }
    }

    getLineStartCoords = (startX, startY) => {
        if (this.state.lineStartX !== startX || this.state.lineStartY !== startY) {
            this.setState({
                lineStartX: this.state.lineStartX = startX,
                lineStartY: this.state.lineStartY = startY
            })
        }
    }

    getDegree = (deg) => {
        if (this.state.degree !== deg) {
            this.setState({
                degree: this.state.degree = deg
            })
        }
    }

    getSelectedLayerID = (id) => {
        if (this.state.selectedLayerID !== id) {
            this.setState({
                selectedLayerID: this.state.selectedLayerID = id
            })
        }
    }

    // getHoveredLayerID = (hoverID) => {
    //     let hoveredEl = this.draw.findOne(`#${hoverID}`)
    //     console.log(hoveredEl)
    // }

    handlerEditParams = (paramName, paramVal) => {
        switch(paramName) {
            case 'length':
                if (this.state.gridLength !== paramVal) {
                    this.setState({
                        gridLength: this.state.gridLength = paramVal
                    })
                }
                break
            case 'radius':
                if (this.state.radius !== paramVal) {
                    this.setState({
                        editedR: this.state.editedR = paramVal
                    })
                }
                break
            case 'width':
                if (this.state.editedW !== paramVal) {
                    this.setState({
                        editedW: this.state.editedW = paramVal
                    })
                }
                break
            case 'height':
                if (this.state.editedH !== paramVal) {
                    this.setState({
                        editedH: this.state.editedH = paramVal
                    })
                }
                break
            default:
                this.setState({
                    isEditParam: this.state.isEditParam = false,
                    lastEditParam: this.state.lastEditParam = paramName,
                })
        }

        this.setState({
            isEditParam: this.state.isEditParam = true,
            lastEditParam: this.state.lastEditParam = paramName,
        })
    }

    disableEditParam = () => {
        this.setState({
            isEditParam: this.state.isEditParam = false,
            // selectedId: this.state.selectedShape.id()
        })

        if (this.state.selectedShape) {
            this.setState({
                selectedId: this.state.selectedShape.id()
            })
        }
    }

    getLineGridLength = (l) => {
        if (l !== this.state.gridLength) {
            this.setState({
                gridLength: this.state.gridLength = l
            })
        }
    }

    getNewShapeName = (id, name) => {
        this.setState({
            renameData: this.state.renameData = {id: id, name: name}
        })
    }

    turnEditMode = (bool) => {
        this.setState({
            isEdit: bool
        })
    }

    setAxisLine = (bool) => {
        if (this.state.selectedShape.type === 'line') {
            if (this.state.selectedShape.data('axis')) {
                this.state.selectedShape.data('axis', null);
            } else {
                this.state.selectedShape.data('axis', 'axis');
            }
        }

        if (this.state.axisSwitch !== bool) {
            this.setState({
                axisSwitch: this.state.axisSwitch = bool
            })
        }
    }

    rotateSwitcher = () => {
        if (this.state.btnRightRotate || this.state.btnLeftRotate) {
            this.setState({
                btnRightRotate: false,
                btnLeftRotate: false
            })
        }
    }

    disableAxisSwitch = () => {
        if (this.state.axisSwitch) {
            this.setState({
                axisSwitch: this.state.axisSwitch = false
            })
        }
    }

    gridDisabled = () => {
        this.setState({
            gridChanged: this.state.gridChanged = false
        })
    }

    layerMoveSwitcher = (bool) => {
        this.setState({
            isLayerMove: bool
        })
    }

    // handlerUpdateShapesName = (data) => {
    //     if (this.state.currentShapesID !== data) {
    //         this.setState({
    //             currentShapesID: this.state.currentShapesID = data
    //         })
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedShape) {
            this.map.set(
                this.state.selectedShape,
                {
                    name: this.state.selectedShape.node.classList[0],
                    focalPlane: this.state.focalPlane
                }
            )

            if (this.state.selectedShape.type === 'g') {
                if (this.state.selectedShape.children()[0].children()[0].node.textContent === '42') {
                    if (this.state.isSelectedShapeScaleble !== false) {
                        this.setState({ isSelectedShapeScaleble: this.state.isSelectedShapeScaleble = false })
                    }
                } else {
                    if (this.state.isSelectedShapeScaleble !== true) {
                        this.setState({ isSelectedShapeScaleble: this.state.isSelectedShapeScaleble = true })
                    }
                }
            } else {
                if (this.state.selectedShape.node.children[0]) {
                    if (this.state.selectedShape.node.children[0].innerHTML === '42') {
                        if (this.state.isSelectedShapeScaleble !== false) {
                            this.setState({ isSelectedShapeScaleble: this.state.isSelectedShapeScaleble = false })
                        }
                    } else {
                        if (this.state.isSelectedShapeScaleble !== true) {
                            this.setState({ isSelectedShapeScaleble: this.state.isSelectedShapeScaleble = true })
                        }
                    }
                }
            }
        }

        if (this.map !== prevState.shapesDataMap) {

            this.setState({
                shapesDataMap: this.state.shapesDataMap = this.map
            })
        }

        if (prevProps.instruments.moa !== prevState.moa || prevProps.instruments.gridSize !== prevState.gridSize) {
            this.setState({
                moa: this.state.moa = prevProps.instruments.moa,
                mil: this.state.mil = prevProps.instruments.mil,
                gridSize: this.state.gridSize = prevProps.instruments.gridSize,
                gridChanged: true
            })
        }

        if (prevProps.instruments.duplicate !== prevState.duplicate) {
            this.setState({
                duplicate: this.state.duplicate = prevProps.instruments.duplicate,
            })
        }

        if (prevProps.instruments.undo !== prevState.undo || prevProps.instruments.redo !== prevState.redo) {
            this.setState({
                undo: this.state.undo = prevProps.instruments.undo,
                redo: this.state.redo = prevProps.instruments.redo
            })
        }

        if (prevProps.instruments.clear !== prevState.clearCanvas) {
            this.setState({
                clearCanvas: this.state.clearCanvas = prevProps.instruments.clear,
            })
        }

        if (!this.state.strokeColor) {
            this.setState({
                strokeColor: '#000'
            })
        }
    }

    render () {
        return (
            <main className={this.props.activeLink === '2' ? 'main_block-lib' : 'main_block'}>
                <LibSection
                    handlerActivateButton={this.props.handlerActivateButton}
                    activeLink={this.props.activeLink}
                    shapeData={this.state}
                    shapes={this.props.shapes}
                    getFocalPlaneName={this.getFocalPlaneName}
                    getSelectedLayerID={this.getSelectedLayerID}
                    getHoveredLayerID={this.getHoveredLayerID}
                    handlerUpdateShapesName={this.handlerUpdateShapesName}
                    getNewShapeName={this.getNewShapeName}
                    activeTabId={this.props.activeTabId}
                    focus={this.props.focus}
                    blur={this.props.blur}
                    onExportingTool={this.props.onExportingTool}
                    showFilterModal={this.props.showFilterModal}
                    handleToggleFilterModal={this.props.handleToggleFilterModal}
                    openHeaderButton={this.props.openHeaderButton}
                    headerSearchValue={this.props.headerSearchValue}
                    clearHeaderFilter={this.props.clearHeaderFilter}
                    handleUseFilters={this.props.handleUseFilters}
                    isUseFilters={this.props.isUseFilters}
                    onChangeHeaderSearchValue={this.props.onChangeHeaderSearchValue}
                    layerMoveSwitcher={this.layerMoveSwitcher}
                    handleReticleCardOpen={this.props.handleReticleCardOpen}
                    isReticleCardOpen={this.props.isReticleCardOpen}
                    handlerAddTab={this.props.handlerAddTab}
                    deviceWidth={this.props.deviceWidth}
                    handleOpenCard={this.props.handleOpenCard}
                    reticle={this.props.reticle}
                    openCard={this.props.openCard}
                    closeOpenCard={this.props.closeOpenCard}
                    handleCloseCard={this.props.handleCloseCard}
                    handleActiveLib={this.props.handleActiveLib}
                    handleActiveLibWithChangeFolder={this.props.handleActiveLibWithChangeFolder}
                    activeButton={this.props.activeButton}
                    activeLib={this.props.activeLib}
                    burgerButtonName={this.props.burgerButtonName}
                    handleClearActiveLib={this.props.handleClearActiveLib}
                    handleChangeSelectedCardStatus={this.props.handleChangeSelectedCardStatus}
                    isSelectedCard={this.props.isSelectedCard}
                    checkBoxChange={this.props.checkBoxChange}
                    selectedReticles={this.props.selectedReticles}
                />
                {this.props.activeLink === '2' ? null : (
                <>
                <CanvasSection
                    activeLink={this.props.activeLink}
                    align={this.state}
                    instruments={this.props.instruments}
                    svgUpdateData={this.props.svgUpdateData}
                    getSelectedShapes={this.getSelectedShapes}
                    setGroupedShapes={this.setGroupedShapes}
                    grid={this.props.grid}
                    getCanvasShapesData={this.getCanvasShapesData}
                    getMarkersData={this.getMarkersData}
                    getNewMarkerCoords={this.getNewMarkerCoords}
                    getCurrentID={this.props.getCurrentID}
                    getDeletedIndex={this.getDeletedIndex}
                    getGroupedData={this.getGroupedData}
                    getSelectedShape={this.getSelectedShape}
                    getSelectedShapeWidth={this.getSelectedShapeWidth}
                    getSelectedShapeStrokeColor={this.getSelectedShapeStrokeColor}
                    onSelectChangeMove={this.props.onSelectChangeMove}
                    focusedInput={this.props.focusedInput}
                    setSelectedStrokeChanged={this.setSelectedStrokeChanged}
                    undoRedoDeactivate={this.props.undoRedoDeactivate}
                    setActiveShapeButton={this.setActiveShapeButton}
                    activeShapeButton={this.state.activeShapeButton}
                    duplicateDeactivate={this.props.duplicateDeactivate}
                    clearDeactivate={this.props.clearDeactivate}
                    fillOpacity={this.fillOpacity}
                    getClearedData2Export={this.props.getClearedData2Export}
                    isExport={this.props.isExport}
                    getLineGridLength={this.getLineGridLength}
                    // getMarkerXY={this.getMarkerXY}
                    // fillData={this.state.fillColor}
                    // fillOpacity={this.state.opacity}
                    fillShapePrevOpacity={this.fillShapePrevOpacity}
                    fillShapePrevColor={this.fillShapePrevColor}
                    fillColor={this.state.fillColor}
                    opacity={this.state.opacity}
                    reticlesID={this.props.reticlesID}
                    strokeColor={this.state.strokeColor}
                    strokeWidth={this.state.strokeWidth}
                    activeTabId={this.props.activeTabId}
                    activeTab={this.props.activeTab}
                    gridDisabled={this.gridDisabled}
                    setCleanedSvg={this.props.setCleanedSvg}
                    disableEditParam={this.disableEditParam}
                    disableAxisSwitch={this.disableAxisSwitch}
                    firstSave={this.props.firstSave}
                    disableFirstSave={this.props.disableFirstSave}
                    disablefpSwitcher={this.disablefpSwitcher}
                    fpSwitchOff={this.fpSwitchOff}
                    switchOffisFillColorSet={this.switchOffisFillColorSet}
                    switchOffisStrokeColorSet={this.switchOffisStrokeColorSet}
                    layerMoveSwitcher={this.layerMoveSwitcher}
                    rotateSwitcher={this.rotateSwitcher}
                    getSelectedShapesType={this.getSelectedShapesType}
                    isShapeDelete={this.props.isShapeDelete}
                    toggleDeleteShape={this.props.toggleDeleteShape}
                    setStrokeType={this.setStrokeType}
                    strokeDasharray={this.state.strokeDasharray}
                    isStrokeTypeChanged={this.state.isStrokeTypeChanged}
                    resetStrokeTypeChanged={this.resetStrokeTypeChanged}
                    importReticle={this.props.importReticle}
                    clearImportData={this.props.clearImportData}
                    appVersion={this.props.appVersion}
                />
                <EditSection
                    getDistance={this.getDistance}
                    presetData={this.presetData}
                    shapeData={this.state}
                    rotateShape={this.handlerRotate}
                    fillShapeColor={this.fillShapeColor}
                    fillShapeOpacity={this.fillShapeOpacity}
                    focusedInput={this.props.focusedInput}
                    fillColor={this.state.prevFillColor}
                    opacity={this.state.opacity}

                    setStrokeColor={this.setStrokeColor}
                    setStrokeWidth={this.setStrokeWidth}
                    getNewCoords={this.getNewCoords}
                    getNewCircleData={this.getNewCircleData}
                    getLineLength={this.getLineLength}
                    getFocalPlanesData={this.getFocalPlanesData}
                    getNewMarkerCoords={this.getNewMarkerCoords}
                    getNewShapeSize={this.getNewShapeSize}
                    getLineStartCoords={this.getLineStartCoords}
                    getDegree={this.getDegree}
                    focus={this.props.focus}
                    blur={this.props.blur}
                    getSelectedShapeWidth={this.getSelectedShapeWidth}
                    getSelectedShapeStrokeColor={this.getSelectedShapeStrokeColor}
                    instruments={this.props.instruments}
                    activeShapeButton={this.state.activeShapeButton}
                    fillChanged={this.fillChanged}
                    handlerEditParams={this.handlerEditParams}
                    turnEditMode={this.turnEditMode}
                    setAxisLine={this.setAxisLine}
                    activeTabId={this.props.activeTabId}
                    btnRightRotater={this.btnRightRotater}
                    btnLeftRotater={this.btnLeftRotater}
                    setStrokeType={this.setStrokeType}
                    appVersion={this.props.appVersion}
                />
                </>
                )}

            </main>
        )
    }
}

export default Main;
