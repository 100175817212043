const coords2GridFinder = (x, y, gridArr, width, height) => {
    let gX = gridArr[0];
    let gY = gridArr[1];
    let bufferGridX = 0;
    let bufferGridY = 0;
    let persentX = x / 600;
    let persentY = y / 450;
    let newX = width * persentX;
    let newY = height * persentY;
    let bufferGridMaxX = newX <= gX[gX.length - 1] ? newX : gX[gX.length - 1];
    let bufferGridMaxY = newY <= gY[gY.length - 1] ? newY : gY[gY.length - 1];
    let isMaxXFind = true;
    let isMaxYFind = true;
    let gridX = 0;
    let gridY = 0;

    gX.forEach(el => {
        let current = el;

        if (current <= newX && (typeof bufferGridX === 'undefined' || bufferGridX < current)) {
            bufferGridX = current;
        }
    })

    gX.forEach(el => {
        let current = el
        if (current >= newX && isMaxXFind) {
            bufferGridMaxX = current;
            isMaxXFind = false;
        }
    })

    if (bufferGridMaxX - newX > newX - bufferGridX) {
        if (gridX !== bufferGridX) {
            gridX = bufferGridX;
        }
    } else {
        if (gridX !== bufferGridMaxX) {
            gridX = bufferGridMaxX;
        }
    }

    gY.forEach(el => {
        let current = el;

        if (current <= newY && (typeof bufferGridY === 'undefined' || bufferGridY < current)) {
            bufferGridY = current;
        }
    })

    gY.forEach(el => {
        let current = el;

        if (current >= newY && isMaxYFind) {
            bufferGridMaxY = current;
            isMaxYFind = false;
        }
    })

    if (bufferGridMaxY - newY > newY - bufferGridY) {
        if (gridY !== bufferGridY) {
            gridY = bufferGridY;
        }
    } else {
        if (gridY !== bufferGridMaxY) {
            gridY = bufferGridMaxY;
        }
    }

    return [gridX, gridY];
}

module.exports = coords2GridFinder;
