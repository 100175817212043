import React from 'react';
import './libParameters.scss';

class LibParameters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedShape: '',
            canvasShapes: [],
            currentShapesID: [],
            shapesNames: [],
            shapesDataMap: new Map(),
            shapeName: ''
        }
    }

    handlerChangeName = (e) => {
        // let arr = this.state.shapesNames
        // arr[e.target.id] = e.target.value

        // this.setState({
        //     shapesNames: this.state.shapesNames = arr
        // })

        // this.state.shapesNames[e.target.id] = e.target.value
        
        let elID = e.target.id.replace('input-', '');
        
        let inputEl = '';
        
        this.state.currentShapesID.map((el) => {
            if (el.id === elID) {
                inputEl = el
            }
            
            if (el.id.includes('group')) {
                el.gElements.map(gEl => {
                    let target = e.target.id.replace('input-', '')
                    if (gEl.id === target) {
                        inputEl = gEl
                    }
                })
            }
        })

        if (this.state.shapeName !== e.target.value) {
                this.setState({
                    shapeName: this.state.shapeName = e.target.value
                })
        }

        inputEl.name = this.state.shapeName

    }

    // handlerInputActivate = (e) => {

        // let inputBtns = e.target.parentElement.children

        // inputBtns.forEach(el => {
        //     el.classList.add('input_btn-active')
        // })

        // for (let el in inputBtns) {
        //     inputBtns[el].classList.add('input_btn-active')
        // }

        // inputBtns[1].classList.add('input_btn-active')
    // }

    selectedLayersCleaner = () => {
        let layersList = document.querySelectorAll('.list_input-active');
        if (layersList.length > 0) {
            layersList.forEach(el => el.classList.remove('list_input-active'))
        }
    }

    classListCleaner = () => {
        let inputBtns = document.querySelectorAll('.lib-edit-section_input-btn');

        if (inputBtns.length > 0) {
            inputBtns.forEach(el => el.remove())
        }
    }

    editCloser = () => {
        let inputs = document.querySelectorAll('.lib-edit-section_fp-input');

        inputs.forEach(el => el.readOnly = true)
    }

    handlerInputActivate = (e) => {
        this.editCloser();
        let selectedLayer = e.target.id.replace('input-', '');

        this.props.getSelectedLayerID(selectedLayer);
        this.props.blur();
    }

    handlerReanameLayer = (e) => {
        e.target.readOnly = false;
        this.props.focus();
    }

    handlerEndRename = (e) => {
        let id = '';
        let newName = '';
        let elID = e.target.id.replace('input-', '');
        let inputEl = '';

        this.state.currentShapesID.map((el) => {
            if (el.id === elID) {
                inputEl = el
            }

            if (el.id.includes('group')) {
                el.gElements.map(gEl => {
                    let target = e.target.id.replace('input-', '')
                    if (gEl.id === target) {
                        inputEl = gEl
                    }
                })
            }
        })
        
        if (e.key === 'Enter') {
            e.target.readOnly = true;
            id = e.target.id.replace('input-', '');
            newName = e.target.value;
                if (newName === '') {
                    this.setState({
                        shapeName: this.state.shapeName = this.props.shapeData.selectedShape.data('layername')
                    })
                    this.props.getNewShapeName(id, this.state.shapeName);
                    inputEl.name = this.state.shapeName

                } else if (newName !== '') this.props.getNewShapeName(id, newName);

            this.props.blur();
        }
    }

    handlerHoverLayer = (e) => {
        let layerID = e.target.id.replace('input-', '');
        let hoveredEl = '';

        if (layerID) {
            hoveredEl = document.querySelector(`#${layerID}`);
            hoveredEl.classList.add('selected');
        }
    }

    handlerHoverOutLayer = (e) => {
        let layerID = e.target.id.replace('input-', '');
        let hoveredEl = '';

        if (layerID) {
            hoveredEl = document.querySelector(`#${layerID}`);
            hoveredEl.classList.remove('selected');
        }
    }

    calcMaxLayerPosition = (el) => {
        const parent = el.root();
        const childs = parent.children();
        let maxPosition = 0;

        childs.each(function(i, children) {
            if (!i.hasClass('marker') && !i.hasClass('groupBox') && !i.hasClass('canvasGroup') && i.type !== 'defs') {
                maxPosition = i.position() > maxPosition ? i.position() : maxPosition;
            }
        })

        return maxPosition;
    }

    handlerLayerUp = () => {
        if (this.state.selectedShape) {
            const maxPos = this.calcMaxLayerPosition(this.state.selectedShape);

            if (this.state.selectedShape.position() < maxPos) {
                this.state.selectedShape.forward();

                this.props.layerMoveSwitcher(true);
            }
        }
    }

    handlerLayerDown = () => {
        if (this.state.selectedShape) {
            if (this.state.selectedShape.position() >= 3) {
                this.state.selectedShape.backward();

                this.props.layerMoveSwitcher(true);
            }
        }
    }

    canvasShapesBuffer = [];
    // firstFocalPlaneShapes = [];
    // secondFocalPlaneShapes = [];
    // shapesMap = new Map();
    shapesObj = {};
    // shapesNames = [];
    objShapes = {};

    componentDidMount() {
        this.setState({
            currentShapesID: this.state.currentShapesID = this.props.shapes
        })
        
        let layers = document.querySelector('#layersList');

        layers.addEventListener('mouseover', this.handlerHoverLayer);
        layers.addEventListener('mouseout', this.handlerHoverOutLayer);
    }

    componentWillUpdate(prevProps, prevState) {
        if (prevProps.shapeData.canvasShapes !== prevState.canvasShapes) {
            this.setState({
                canvasShapes: this.state.canvasShapes = prevProps.shapeData.canvasShapes,
            })
        }

        if (prevProps.shapeData.selectedShape !== prevState.selectedShape) {
            this.setState({
                selectedShape: this.state.selectedShape = prevProps.shapeData.selectedShape,
            })
        }

        if (prevProps.shapeData.shapesDataMap !== prevState.shapesDataMap) {
            this.setState({
                shapesDataMap: this.state.shapesDataMap = prevProps.shapeData.shapesDataMap
            })
        }

        if (prevProps.shapes !== prevState.currentShapesID) {
            this.setState({
                currentShapesID: this.state.currentShapesID = prevProps.shapes,
                shapesNames: this.state.shapesNames = prevProps.shapes
            })
        }

        if (prevProps.shapeData.deletedIndex !== prevState.deletedIndex) {
            this.setState({
                deletedIndex: this.state.deletedIndex = prevProps.shapeData.deletedIndex
            })
        }

        if (this.state.selectedShape) {
            this.selectedLayersCleaner();

            let shapeID = 'input-' + this.state.selectedShape.id();
            let inputEl = document.querySelector(`#${shapeID}`);
            if (this.state.selectedShape.type === 'g') {
                // let groupBlockID = 'groupBlock-' + this.state.selectedShape.id();
                // let grpEl = document.querySelector(`#${groupBlockID}`);
                // grpEl && grpEl.classList.add('list_input-active')

                let grpEl = document.querySelector(`#input-${this.state.selectedShape.id()}`);
                if (grpEl) {
                    let grpElParrent = grpEl.closest('.lib-edit-section_fp-item');
                    grpElParrent && grpElParrent.classList.add('list_input-active');
                }
            } else if (inputEl) {
                inputEl.parentNode.classList.add('list_input-active')
            }
        }
        if (this.state.selectedShape === '') {
            this.selectedLayersCleaner();
        }
        // this.state.shapesDataMap.forEach(el => {
        //     this.shapesMap.set(el, 'Hooo')
        // })
        // this.shapeMap.set()

        // this.firstFocalPlaneShapes = [];
        // this.secondFocalPlaneShapes = [];
        // if (prevState.shapesDataMap) {
        //     prevState.shapesDataMap.forEach((el) => {
        //         if (el.focalPlane === 'second') {
        //             this.secondFocalPlaneShapes.push(el.name)
        //         } else {
        //             this.firstFocalPlaneShapes.push(el.name)
        //         }
        //     })
        // }

        // this.secondFpListRender = this.secondFocalPlaneShapes.map((el, index) => {
        //     if (el.includes('circle')) {
        //         return(
        //             <li className='lib-edit-section_fp-item lib-edit-section_fp-item_circle' key={index}>
        //                 <input type="text" className='lib-edit-section_fp-input' value={el}
        //                     onChange={this.handlerChangeName}
        //                 />
        //             </li>
        //         )
        //     } else {
        //         return(
        //             <li className='lib-edit-section_fp-item lib-edit-section_fp-item_line' key={index}>
        //                 <input type="text" className='lib-edit-section_fp-input' value={el}
        //                     onChange={this.handlerChangeName}
        //                 />
        //             </li>
        //         )
        //     }
        // })

        this.shapesObj = {}

        this.state.canvasShapes.forEach(el => {
            this.shapesObj[el] = el
            // this.shapesMap.set(el)
            // console.log('KEYS', this.shapesMap)
        })
        // for (let mapEl in this.shapesMap) {
        //     console.log('KEYS', mapEl.keys())
        // }

        this.canvasShapesBuffer = []

        for (let el in this.shapesObj) {
            if (!this.canvasShapesBuffer.includes(this.shapesObj[el])) {
                this.canvasShapesBuffer.push(this.shapesObj[el])
            }

            // if (!this.shapesNames.includes(this.shapesObj[el])) {
            //     this.shapesNames.push(this.shapesObj[el])
            // }
        }

        // this.shapesNames.forEach(el => {
        //     if (!this.canvasShapesBuffer.includes(el)) {
        //         let index = this.shapesNames.indexOf(el)
        //         this.shapesNames.splice(index, 1)
        //     }
        // })

        // console.log('prevProps', this.state.currentShapesID)

        
        // this.state.canvasShapes.forEach(el => this.canvasShapesBuffer.push(el))
        // if (this.state.canvasShapes.size())

        // this.firstFpListRender = this.canvasShapesBuffer.map((el, index) => {
        //     console.log(el)
        //     if (el.includes('circle')) {
        //         return(
        //             <li className='lib-edit-section_fp-item lib-edit-section_fp-item_circle' key={index}>
        //                 <input id={index} type="text" className='lib-edit-section_fp-input' value={this.state.shapesNames[index]}
        //                     onChange={this.handlerChangeName}
        //                     onClick={this.handlerInputActivate}
        //                 />
        //             </li>
        //         )
        //     } else if (el.includes('line')) {
        //         return(
        //             <li className='lib-edit-section_fp-item lib-edit-section_fp-item_line' key={index}>
        //                 <input id={index} type="text" className='lib-edit-section_fp-input' value={this.state.shapesNames[index]}
        //                     onChange={this.handlerChangeName}
        //                     onClick={this.handlerInputActivate}
        //                 />
        //             </li>
        //         )
        //     } else if (el.includes('shape')) {
        //         return(
        //             <li className='lib-edit-section_fp-item lib-edit-section_fp-item_shape' key={index}>
        //                 <input id={index} type="text" className='lib-edit-section_fp-input' value={this.state.shapesNames[index]}
        //                     onChange={this.handlerChangeName}
        //                     onClick={this.handlerInputActivate}
        //                 />
        //             </li>
        //         )
        //     }
        // })

        this.groupListRender = (el) => {

            if (el.length > 0) {
            
                el.map((el, index) => {
                    return(
                        <li className='lib-edit-section_fp-item lib-edit-section_fp-item_circle' key={index}>
                            <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input' readOnly value={el.name}
                                onChange={this.handlerChangeName}
                                onClick={this.handlerInputActivate}
                                onDoubleClick={this.handlerReanameLayer}
                                onKeyDown={this.handlerEndRename}
                                // onFocus={this.props.focus}
                                // onBlur={this.props.blur}
                                maxLength="50"
                            />
                        </li>
                    )
                })
            }
        }

        this.firstFpListRender = this.state.currentShapesID.map((el, index) => {

            if (el.id.includes('circle')) {
                return(
                    <li className='lib-edit-section_fp-item lib-edit-section_fp-item_circle' key={index}>
                        <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input' readOnly value={el.name}
                            onChange={this.handlerChangeName}
                            onClick={this.handlerInputActivate}
                            onDoubleClick={this.handlerReanameLayer}
                            onKeyDown={this.handlerEndRename}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            maxLength="50"
                        />
                        { this.state.selectedShape && this.state.selectedShape.node.attributes[7] && this.state.selectedShape.node.attributes[7].nodeValue === el.id &&
                            <div className='leyers_buttons-block'>
                                <button className='layer-btn layer_btn-up' onClick={this.handlerLayerUp}></button>
                                <button className='layer-btn layer_btn-down'  onClick={this.handlerLayerDown}></button>
                            </div>
                        }
                    </li>
                )
            } else if (el.id.includes('line')) {
                return(
                    <li className='lib-edit-section_fp-item lib-edit-section_fp-item_line' key={index}>
                        <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input' readOnly value={el.name}
                            onChange={this.handlerChangeName}
                            onClick={this.handlerInputActivate}
                            onDoubleClick={this.handlerReanameLayer}
                            onKeyDown={this.handlerEndRename}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            maxLength="50"
                        />
                        { this.state.selectedShape && this.state.selectedShape.node.attributes[7] && this.state.selectedShape.node.attributes[7].nodeValue === el.id &&
                            <div className='leyers_buttons-block'>
                                <button className='layer-btn layer_btn-up' onClick={this.handlerLayerUp}></button>
                                <button className='layer-btn layer_btn-down'  onClick={this.handlerLayerDown}></button>
                            </div>
                        }
                    </li>
                )
            } else if (el.id.includes('triangle')) {
                return(
                    <li className='lib-edit-section_fp-item lib-edit-section_fp-item_triangle' key={index}>
                        <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input' readOnly value={el.name}
                            onChange={this.handlerChangeName}
                            onClick={this.handlerInputActivate}
                            onDoubleClick={this.handlerReanameLayer}
                            onKeyDown={this.handlerEndRename}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            maxLength="50"
                        />
                        { this.state.selectedShape && this.state.selectedShape.node.attributes[5] && this.state.selectedShape.node.attributes[5].nodeValue === el.id &&
                            <div className='leyers_buttons-block'>
                                <button className='layer-btn layer_btn-up' onClick={this.handlerLayerUp}></button>
                                <button className='layer-btn layer_btn-down'  onClick={this.handlerLayerDown}></button>
                            </div>
                        }
                    </li>
                )
            } else if (el.id.includes('square') || el.id.includes('rect')) {
                return(
                    <li className={`lib-edit-section_fp-item ${el.id.includes('square') ? 'lib-edit-section_fp-item_square' : 'lib-edit-section_fp-item_rect'} `} key={index}>
                        <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input' readOnly value={el.name}
                            onChange={this.handlerChangeName}
                            onClick={this.handlerInputActivate}
                            onDoubleClick={this.handlerReanameLayer}
                            onKeyDown={this.handlerEndRename}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            maxLength="50"
                        />
                        { this.state.selectedShape && this.state.selectedShape.node.attributes[8] && this.state.selectedShape.node.attributes[8].nodeValue === el.id &&
                            <div className='leyers_buttons-block'>
                                <button className='layer-btn layer_btn-up' onClick={this.handlerLayerUp}></button>
                                <button className='layer-btn layer_btn-down'  onClick={this.handlerLayerDown}></button>
                            </div>
                        }
                    </li>
                )
            } else if (el.id.includes('romNumGroup') || el.id.includes('arabicNumGroup')) {
                return(
                    <li className={`lib-edit-section_fp-item ${el.id.includes('romNumGroup') ? 'lib-edit-section_fp-item_romNum' : 'lib-edit-section_fp-item_arabicNum'}`} key={index}>
                        <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input' readOnly value={el.name}
                            onChange={this.handlerChangeName}
                            onClick={this.handlerInputActivate}
                            onDoubleClick={this.handlerReanameLayer}
                            onKeyDown={this.handlerEndRename}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            maxLength="50"
                        />
                        { this.state.selectedShape && this.state.selectedShape.node.attributes[0] && this.state.selectedShape.node.attributes[0].nodeValue === el.id &&
                            <div className='leyers_buttons-block'>
                                <button className='layer-btn layer_btn-up' onClick={this.handlerLayerUp}></button>
                                <button className='layer-btn layer_btn-down'  onClick={this.handlerLayerDown}></button>
                            </div>
                        }
                    </li>
                )
            } //else if (el.id.includes('group')) {
            //     return(
            //         <div key={index} id={'groupBlock-' + el.id}>
            //             <li className='lib-edit-section_fp-item lib-edit-section_fp-item_group'>
            //                 <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input' readOnly value={el.name}
            //                     onChange={this.handlerChangeName}
            //                     onClick={this.handlerInputActivate}
            //                     onDoubleClick={this.handlerReanameLayer}
            //                     onKeyDown={this.handlerEndRename}
            //                     // onFocus={this.props.focus}
            //                     // onBlur={this.props.blur}
            //                     maxLength="50"
            //                 />
            //             </li>
            //             <ul id={'list-' + el.id} className='lib-edit-section_group'>
            //                 {
            //                     el.gElements.length > 0 &&
            //                         el.gElements.map((el, index) => {
            //                             if (el.id.includes('circle')) {
            //                                 return(
            //                                     <li className='lib-edit-section_fp-item lib-edit-section_fp-item_circle lib-edit-section_group-item' key={index}>
            //                                         <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input lib-edit-section_group-input' readOnly value={el.name}
            //                                             onChange={this.handlerChangeName}
            //                                             onClick={this.handlerInputActivate}
            //                                             onDoubleClick={this.handlerReanameLayer}
            //                                             onKeyDown={this.handlerEndRename}
            //                                             // onFocus={this.props.focus}
            //                                             // onBlur={this.props.blur}
            //                                             maxLength="50"
            //                                         />
            //                                     </li>
            //                                 )
            //                             } else if (el.id.includes('triangle')) {
            //                                 return(
            //                                     <li className='lib-edit-section_fp-item lib-edit-section_fp-item_triangle lib-edit-section_group-item' key={index}>
            //                                         <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input lib-edit-section_group-input' readOnly value={el.name}
            //                                             onChange={this.handlerChangeName}
            //                                             onClick={this.handlerInputActivate}
            //                                             onDoubleClick={this.handlerReanameLayer}
            //                                             onKeyDown={this.handlerEndRename}
            //                                             // onFocus={this.props.focus}
            //                                             // onBlur={this.props.blur}
            //                                             maxLength="50"
            //                                         />
            //                                     </li>
            //                                 )
            //                             } else if (el.id.includes('line')) {
            //                                 return(
            //                                     <li className='lib-edit-section_fp-item lib-edit-section_fp-item_line lib-edit-section_group-item' key={index}>
            //                                         <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input lib-edit-section_group-input' readOnly value={el.name}
            //                                             onChange={this.handlerChangeName}
            //                                             onClick={this.handlerInputActivate}
            //                                             onDoubleClick={this.handlerReanameLayer}
            //                                             onKeyDown={this.handlerEndRename}
            //                                             // onFocus={this.props.focus}
            //                                             // onBlur={this.props.blur}
            //                                             maxLength="50"
            //                                         />
            //                                     </li>
            //                                 )
            //                             } else if (el.id.includes('square') || el.id.includes('rect')) {
            //                                 return(
            //                                     <li className={`lib-edit-section_fp-item lib-edit-section_group-item ${el.id.includes('square') ? 'lib-edit-section_fp-item_square' : 'lib-edit-section_fp-item_rect'}`} key={index}>
            //                                         <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input lib-edit-section_group-input' readOnly value={el.name}
            //                                             onChange={this.handlerChangeName}
            //                                             onClick={this.handlerInputActivate}
            //                                             onDoubleClick={this.handlerReanameLayer}
            //                                             onKeyDown={this.handlerEndRename}
            //                                             onFocus={this.props.focus}
            //                                             onBlur={this.props.blur}
            //                                             maxLength="50"
            //                                         />
            //                                     </li>
            //                                 )
            //                             } else if (el.id.includes('romNumGroup') || el.id.includes('arabicNumGroup')) {
            //                                 return (
            //                                     <li className={`lib-edit-section_fp-item lib-edit-section_group-item ${el.id.includes('romNumGroup') ? 'lib-edit-section_fp-item_romNum' : 'lib-edit-section_fp-item_arabicNum'}`} key={index}>
            //                                         <input id={'input-' + el.id} type="text" className='lib-edit-section_fp-input lib-edit-section_group-input' readOnly value={el.name}
            //                                             onChange={this.handlerChangeName}
            //                                             onClick={this.handlerInputActivate}
            //                                             onDoubleClick={this.handlerReanameLayer}
            //                                             onKeyDown={this.handlerEndRename}
            //                                             // onFocus={this.props.focus}
            //                                             // onBlur={this.props.blur}
            //                                             maxLength="50"
            //                                         />
            //                                     </li>
            //                                 )
            //                             }
            //                         })
            //                 }
            //             </ul>
            //         </div>
            //     )
            // }
        })

    }

    // componentDidUpdate() {
        // sessionStorage.setItem('layersName', JSON.stringify(this.state.currentShapesID))
    // }

    componentWillUnmount() {
        let layers = document.querySelector('#layersList');

        layers.removeEventListener('mouseover', this.handlerHoverLayer);
        layers.removeEventListener('mouseout', this.handlerHoverOutLayer);
    }

    render () {
        return (
            <section className='lib-edit-section-block'>
                <h2 className='lib-edit-section_title'>Shapes</h2>
                <div className='lib-edit-section_fp lib-edit-section_first-fp'>
                    <ul id='layersList' className='lib-edit-section_fp-list'>
                        {this.firstFpListRender}
                    </ul>
                </div>
                {/*
                <div className='lib-edit-section_fp lib-edit-section_first-fp'>
                    <h3 className='lib-edit-section_fp-title'>First (Elements scale during zoom)</h3>
                    <ul className='lib-edit-section_fp-list'>
                        {this.firstFpListRender}
                    </ul>
                </div>
                <div className='lib-edit-section_fp lib-edit-section_second-fp'>
                    <h3 className='lib-edit-section_fp-title'>Second (Elements remain fixed during zoom)</h3>
                    <ul className='lib-edit-section_fp-list'>
                        {this.secondFpListRender}
                    </ul>
                </div>
                */}
            </section>
        )
    }
}

export default LibParameters;
