import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import {
    registerSuccess,
    registerError,
    confirmEmailSuccess,
    confirmEmailError,
    loginRequest,
    loginSuccess,
    loginError,
    logoutSuccess,
    // logoutError,
    getCurrentUserRequest,
    getCurrentUserSuccess,
    getCurrentUserError,
    googleLoginSuccess,
    googleLoginError,
    updateUserSuccess,
    updateUserError,
    resetPasswordSuccess,
    resetPasswordError,
    resetPasswordCheckOtpSuccess,
    resetPasswordCheckOtpError,
    resetPasswordConfirmSuccess,
    resetPasswordConfirmError
} from './auth-actions';

const initialUserState = { 
    firstName: null,
    lastName: null,
    email: null,
    profPic: null,
};

const user = createReducer(initialUserState, {
    [registerSuccess]: (_, { payload }) => payload,
    [confirmEmailSuccess]: (_, { payload }) => payload.user,
    [loginSuccess]: (_, { payload }) => payload.user,
    [googleLoginSuccess]: (_, { payload }) => payload.user,
    [logoutSuccess]: () => initialUserState,
    [getCurrentUserSuccess]: (_, { payload }) => payload,
    [updateUserSuccess]: (_, { payload }) => payload,
    [resetPasswordSuccess]: (_, { payload }) => payload,
    [resetPasswordCheckOtpSuccess]: (_, { payload }) => payload,
    [resetPasswordConfirmSuccess]: (_, { payload }) => payload,
});

const token = createReducer(null, {
    [confirmEmailSuccess]: (_, { payload }) => payload.token,
    [loginSuccess]: (_, { payload }) => payload.token,
    [googleLoginSuccess]: (_, { payload }) => payload.token,
    [logoutSuccess]: () => null,
});

const loginErrorMessage = createReducer(null, {
    [loginError]: (_, { payload }) => payload,
    [loginSuccess]: (_, __) => "",
});

const error = createReducer(null, {
    [registerError]: (_, { payload }) => payload,
    [confirmEmailError]: (_, { payload }) => payload,
    // [loginError]: (_, { payload }) => payload,
    // [loginSuccess]: (_, __) => "",
    [googleLoginSuccess]: (_, __) => "",
    [googleLoginError]: (_, { payload }) => payload,
    // [logoutError]: (_, { payload }) => payload,
    [getCurrentUserError]: (_, { payload }) => payload,
    [updateUserError]: (_, { payload }) => payload,
    [resetPasswordError]: (_, { payload }) => payload,
    [resetPasswordCheckOtpError]: (_, { payload }) => payload,
    [resetPasswordConfirmError]: (_, { payload }) => payload,
});

const isAuthenticated = createReducer(false, {
    [confirmEmailSuccess]: () => true,
    [loginSuccess]: () => true,
    [googleLoginSuccess]: () => true,
    [getCurrentUserSuccess]: () => true,
    [confirmEmailError]: () => false,
    [loginError]: () => false,
    [googleLoginError]: () => false,
    [getCurrentUserError]: () => false,
    [logoutSuccess]: () => false,
    // [logoutError]: () => false,
});

const loading = createReducer(false, {
    [loginRequest]: () => true,
    [loginSuccess]: () => false,
    [loginError]: () => false,
    [getCurrentUserRequest]: () => true,
    [getCurrentUserSuccess]: () => false,
    [getCurrentUserError]: () => false,
});

export default combineReducers({
    user,
    isAuthenticated,
    token,
    error,
    loginErrorMessage,
    loading
});
