import React, {useState, useEffect} from 'react';
import Tab from './Tab';
import './tabs.scss';
import './tab.scss';

export default function Tabs({ handlerAddTab, handlerCloseTab, activeTab, reticles, activeTabId, handlerActiveTab }) {
  const [currentURL, setCurrentURL] = useState(window.location.pathname);

  useEffect(() => {
    setCurrentURL(window.location.pathname);
  }, [currentURL])

  const reticlesNumb = reticles.length < 10;

  return (
    <section className='tabs_section'>
      <ul className='tabs_list-style'>
        {reticles.map((el) => (
            <li className={`tabs__item`} key={el.id}>
              <div className={`tab_block ${(activeTabId === el.id) ? 'active_tab' : ''}`} onClick={() => handlerActiveTab(el.id, el.name)} >
                <Tab key={el.id} id={el.id} name={el.name} handlerCloseTab={handlerCloseTab} docName={el.name} activeTab={activeTab}/>
              </div>
          </li>
        ))}
        { currentURL !== '/preview' &&
          <button id='newDocBtn' className={reticlesNumb ? 'new-doc_btn' : 'new-doc_btn-invisible'} onClick={handlerAddTab}></button>
        }
      </ul>
    </section>
  );
}
