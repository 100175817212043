import React, { useEffect, useLayoutEffect } from 'react';
import './modal.scss';
import { createPortal } from 'react-dom';

const modalRoot = document.querySelector('#modal-root');

const Modal = ({ children, toggleModal, width }) => {
    // const handleKeydown = e => {
    //     console.log('нажали ESC');
    //     if (e.code === 'Escape') {
    //         toggleModal();
    //     }
    // };

    // useEffect(() => {
    //     console.log('addEventListener');
    //     window.addEventListener('keydown', handleKeydown);
    // }, []);

    // useEffect(() => {
    //     console.log('removeEventListener');
    //     return () => window.removeEventListener('keydown', handleKeydown);
    // });

//     useEffect(() => () => {
//         console.log('removeEventListener');
//     return () => window.removeEventListener('keydown', handleKeydown);
//     }
// , []);

    // useLayoutEffect(() => {
    //     return () => {
    //     console.log('removeEventListener');
    //     return () => window.removeEventListener('keydown', handleKeydown);
    //     }
    // }, [])


    const handleOverlayClick = e => {
        // console.log('e.target', e.target)
        // console.log('e.currentTarget', e.currentTarget)

        if (e.target === e.currentTarget) {
            toggleModal();
        }
    };

    return createPortal(
        <div className="overlay" onClick={handleOverlayClick}>
            <div className={`modal ${width}`}>
                {children}
            </div>
         </div>
        ,
        modalRoot,
    );
};
export default Modal;
