const gridRender = (canvasW, canvasH, step) => {
    let gX = [];
    let gY = [];

    for(let i = canvasW / 2; i <= canvasW; i += step) {
        let buffGX = gX;
        buffGX.push(+i.toFixed(2));
        gX = buffGX;
    }

    for(let j = canvasH / 2; j <= canvasH; j += step) {
        let buffGY = gY;
        buffGY.push(+j.toFixed(2));
        gY = buffGY;
    }

    for(let i = canvasW / 2; i >= 0; i -= step) {
        let buffGX = gX;
        buffGX.unshift(+i.toFixed(2));
        gX = buffGX;
    }

    for(let j = canvasH / 2; j >= 0; j -= step) {
        let buffGY = gY;
        buffGY.unshift(+j.toFixed(2));
        gY = buffGY;
    }

    let grXBuff = gX;
    let grYBuff = gY;

    grXBuff.splice(gX.length / 2, 1);
    grYBuff.splice(gY.length / 2, 1);

    gX = grXBuff;
    gY = grYBuff;

    return [gX, gY];
}

module.exports = gridRender;
