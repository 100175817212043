import React from 'react';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { editDeviceData, updateAfterRefrefh } from '../../../redux/devices/device-reducer';
import './Preview_devices.scss';

import DevicesList from './Preview_devices-list';
import SensorList from './Preview_sensor-list';
import Lens from './Preview_lens-list';

export default function Preview_devices({activeTab, focus, blur, isFocused}) {
    const dispatch = useDispatch();
    const devices = useSelector((state) => state.device.deviceData);

    const deviceTypes = ['Thor', 'X-Sight 5'];
    const deviceSensors = ['320', '640', '1280'];
    const xSight5Lens = ['3-15X', '5-25X'];
    const thorLens = ['3-12X', '4-16X', '5-20X'];
    const xSightWidth = 1280;
    const xSightHeight = 960;
    const thorWidth = 1024;
    const thorHeight = 768;
    
    const [currentTabId, setCurrentTabId] = useState('');
    const [currentDeviceType, setCurrentDeviceType] = useState('X-Sight 5');
    const [currentDeviceLens, setCurrentDeviceLens] = useState('3-15X');
    const [currentDeviceSensor, setCurrentDeviceSensor] = useState('320');
    const [currentDeviceWidth, setCurrentDeviceWidth] = useState(xSightWidth);
    const [currentDeviceHeight, setCurrentDeviceHeight] = useState(xSightHeight);
    let [isDeviceButtonActive, setIsDeviceButtonActive] = useState(false);
    let [currentDeviceLensArr, setCurrentDeviceLensArr] = useState(['3-15X', '5-25X']);
    const [firstLoad, setFirstLoad] = useState(true);

    const handlerPressButton = () => {
        setIsDeviceButtonActive(!isDeviceButtonActive);
    }

    const handlerSelectDevice = (e) => {
        const currentDeviceW = e.target.value === 'X-Sight 5' ? xSightWidth : thorWidth;
        const currentDeviceH = e.target.value === 'X-Sight 5' ? xSightHeight : thorHeight;
        const currentDeviceLens = e.target.value === 'X-Sight 5' ? '3-15X' : '3-12X';

        setCurrentDeviceType(e.target.value);
        setCurrentDeviceLens(currentDeviceLens);
        setCurrentDeviceWidth(currentDeviceW);
        setCurrentDeviceHeight(currentDeviceH);

        dispatch(editDeviceData({id: activeTab.id, device: e.target.value, sensor: currentDeviceSensor, lens: currentDeviceLens, width: currentDeviceW, height: currentDeviceH}));
    }

    const handlerSelectSensor = (e) => {
        setCurrentDeviceSensor(e.target.value);

        dispatch(editDeviceData({id: activeTab.id, device: currentDeviceType, sensor: e.target.value, lens: currentDeviceLens, width: 1024, height: 768}));
    }

    const handlerSelectLens = (e) => {
        let w = currentDeviceType === 'X-Sight 5' ? 1280 : 1024;
        let h = currentDeviceType === 'X-Sight 5' ? 960 : 768;
        
        setCurrentDeviceLens(e.target.value);

        dispatch(editDeviceData({id: activeTab.id, device: currentDeviceType, sensor: currentDeviceSensor, lens: e.target.value, width: w, height: h}));
    }

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
        }
        if (sessionStorage.getItem('devices')) {
            let storageData = JSON.parse(sessionStorage.getItem('devices'));
            let currentData = storageData.filter(el => el.id === activeTab.id);
            
            dispatch(updateAfterRefrefh(storageData));

            if (currentData.length) {
                setCurrentDeviceType(currentData[0].device);
                setCurrentDeviceLens(currentData[0].lens);
                setCurrentDeviceSensor(currentData[0].sensor);
            }
        }
    }, [])

    useEffect(() => {
        if (isDeviceButtonActive) {
            focus();
        } else {
            blur();
        }
    }, [isDeviceButtonActive])

    useEffect(() => {
        currentDeviceType === 'X-Sight 5' ? setCurrentDeviceLensArr([...xSight5Lens]) : setCurrentDeviceLensArr([...thorLens]);
        // currentDeviceType === 'X-Sight 5' ? setCurrentDeviceLens('3-15X') : setCurrentDeviceLens('3-12X');
        // currentDeviceType === 'X-Sight 5' ? setCurrentDeviceWidth(xSightWidth) : setCurrentDeviceWidth(thorWidth);
        // currentDeviceType === 'X-Sight 5' ? setCurrentDeviceHeight(xSightHeight) : setCurrentDeviceHeight(thorHeight);

        // if (currentDeviceType === 'X-Sight 5') {
            let [currentData] = devices.filter(el => el.id === activeTab.id);

            if (currentData) {
                setCurrentDeviceLens(currentData.lens);
            }
        // }

        // if (currentDeviceType === 'Thor') {
        //     let currentData = devices.filter(el => el.id === activeTab.id);

        //     setCurrentDeviceLens(currentData[0].lens);
        // }
    }, [currentDeviceType])

    useEffect(() => {
        if (!isFocused) {
            setIsDeviceButtonActive(false);
        }
    }, [isFocused])

    useEffect(() => {
        if (currentTabId !== activeTab.id) {
            setCurrentTabId(activeTab.id);

            let currentData = devices.filter(el => el.id === activeTab.id);
            
            if (currentData.length) {
                setCurrentDeviceType(currentData[0].device);
                setCurrentDeviceLens(currentData[0].lens);
                setCurrentDeviceSensor(currentData[0].sensor);
            }
        }
    }, [activeTab.id])

    useEffect(() => {
        if (!firstLoad) {
            sessionStorage.setItem('devices', JSON.stringify(devices));
        }
    }, [devices, dispatch])

    return (
        <section className='preview_devices-section'>
            <div className='preview_devices-block'>
                <p className='preview_devices-paragraph'>Showing preview for: </p>
                <button className='preview_devices-button' onClick={handlerPressButton}>
                    <span className='devices_button-txt'>{currentDeviceType} {currentDeviceLens}</span>
                </button>
                {isDeviceButtonActive &&
                    (<div className='devices_menu-block'>
                        <div className='devices_menu-block-devices'>
                            <p className='devices_menu-block-paragraph'>Device:</p>
                            <DevicesList deviceTypes={deviceTypes} currentDeviceType={currentDeviceType} handlerSelectDevice={handlerSelectDevice} />
                        </div>

                        {currentDeviceType === 'Thor' &&
                            <div className='devices_menu-block-sensors'>
                                <p className='devices_menu-block-paragraph'>Sensor:</p>
                                <SensorList deviceSensors={deviceSensors} currentDeviceSensor={currentDeviceSensor} handlerSelectSensor={handlerSelectSensor} />
                            </div>
                        }

                        <div className='devices_menu-block-lens'>
                            <p className='devices_menu-block-paragraph'>Lens:</p>
                            <Lens currentDeviceLensArr={currentDeviceLensArr} currentDeviceLens={currentDeviceLens} handlerSelectLens={handlerSelectLens} />
                        </div>
                    </div>)
                }
            </div>
        </section>
    );
}
