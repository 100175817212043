import React from 'react';
import Tabs from './Tabs';
import './header.scss';
import Logo from './Logo';
import { ReactComponent as FilterIcon } from '../../img/sortIcon.svg';
import { ReactComponent as SearchIcon } from '../../img/filterIcon.svg';
import { ReactComponent as UserIcon } from '../../img/headerButtonIcon.svg';
import Search from '../main/lib_section/Search';


function Header(props) {

    const handleOpenHeaderButton = (text) => {
        props.handleOpenHeaderButton(text)
        if (text === 'filter') {
            props.handleToggleFilterModal()
        }
    };

    const handleCloseSearch = () => {
        props.handleOpenHeaderButton('')
    };

    return (
        <>
            <header className={`header ${props.openHeaderButton === 'search' && !props.isReticleCardOpen ? 'header_openSearch' : ''}`}>

                {props.isReticleCardOpen ? (
                    <div style={{ display:'flex', alignItems: 'center' }}>
                        <button className='header_backBtn' onClick={() => props.handleReticleCardOpen(false)}></button>
                        <span className='libraries_reticleInfo-backText header_backText'> Back </span>
                    </div>
                ) : null}

                {props.openHeaderButton === 'search' && !props.isReticleCardOpen ? (
                    <button className='header_backBtn' onClick={() => handleCloseSearch()}></button>
                ): null}

                {props.openHeaderButton === 'userProfile' && props.deviceWidth < 1020 ? (
                    <button className='libraries_reticleInfo-backBtn' onClick={() => handleOpenHeaderButton('')}></button>
                ) : null}

            <section className='header_section'>
                    {(props.isReticleCardOpen || props.openHeaderButton === 'search' || props.openHeaderButton === 'userProfile') && props.deviceWidth < 1020 ? null : (<Logo />)}


                    {props.openHeaderButton === 'search' && !props.isReticleCardOpen ? (
                    <div className='header_filters-block'>
                        <Search value={props.value} onChange={props.onChange} handleClearFilter={props.clearHeaderFilter} mobileClass={'mobile_class'}/>
                    </div>
                ) : null}

            </section>

                {props.activeLink === '2' ? null :(
                    <Tabs {...props} />
                )}

                {props.activeLink === '2' && props.deviceWidth < 1020 && props.openHeaderButton !== 'search' && props.isReticleCardOpen !== true? (
                <div>
                    <button className={`header_Button header_filerIcon`} onClick={() => handleOpenHeaderButton('search')}><SearchIcon/></button>
                    <button className={`header_Button header_searchIcon`} onClick={() => handleOpenHeaderButton('filter')}><FilterIcon/></button>
                    <button className={`header_Button header_userIcon`}onClick={() => handleOpenHeaderButton('userProfile')}><UserIcon/></button>
                </div>
                ) : null}

            </header>
        </>
    )
}

export default Header;
