import React from 'react';
import './canvas_section.scss';
import Canvas from './canvas/Canvas';
import Zoom from './Zoom';
import ShapesButtons from './ShapesButtons';
import EditorNotifications from './EditorNotifications';
import { getError } from '../../../../redux/reticles/reticles-selectors';
import { connect } from 'react-redux';

class Canvas_section extends React.Component {
    // constructor(props) {
    //     super(props)
    //     this.state = {
            // errorMessage: false,
    //     }
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.error !== this.props.error && this.props.error){
    //         this.setState({ errorMessage: true });
    //         setTimeout(() => {
    //             this.setState({ errorMessage: false});
    //         }, 10000);
    //     }
    // }

    render () {
        return (
            <section className='canvas-section'>
                <Canvas
                    alignTop={this.props.alignTop}
                    instruments={this.props.instruments}
                    svgUpdateData={this.props.svgUpdateData}
                    getSelectedShapes={this.props.getSelectedShapes}
                    setGroupedShapes={this.props.setGroupedShapes}
                    strokeWidth={this.props.strokeWidth}
                    strokeColor={this.props.strokeColor}
                    align={this.props.align}
                    getCanvasShapesData={this.props.getCanvasShapesData}
                    getMarkersData={this.props.getMarkersData}
                    getNewMarkerCoords={this.props.getNewMarkerCoords}
                    getCurrentID={this.props.getCurrentID}
                    getDeletedIndex={this.props.getDeletedIndex}
                    getGroupedData={this.props.getGroupedData}
                    getSelectedShape={this.props.getSelectedShape}
                    getSelectedShapeWidth={this.props.getSelectedShapeWidth}
                    onSelectChangeMove={this.props.onSelectChangeMove}
                    focusedInput={this.props.focusedInput}
                    duplicateDeactivate={this.props.duplicateDeactivate}
                    undoRedoDeactivate={this.props.undoRedoDeactivate}
                    clearDeactivate={this.props.clearDeactivate}
                    activeShapeButton={this.props.activeShapeButton}
                    getSelectedShapeStrokeColor={this.props.getSelectedShapeStrokeColor}
                    getClearedData2Export={this.props.getClearedData2Export}
                    isExport={this.props.isExport}
                    getLineGridLength={this.props.getLineGridLength}
                    fillShapePrevOpacity={this.props.fillShapePrevOpacity}
                    fillShapePrevColor={this.props.fillShapePrevColor}
                    fillColor={this.props.fillColor}
                    opacity={this.props.opacity}
                    reticlesID={this.props.reticlesID}
                    activeTabId={this.props.activeTabId}
                    activeTab={this.props.activeTab}
                    gridDisabled={this.props.gridDisabled}
                    setCleanedSvg={this.props.setCleanedSvg}
                    disableEditParam={this.props.disableEditParam}
                    disableAxisSwitch={this.props.disableAxisSwitch}
                    firstSave={this.props.firstSave}
                    disableFirstSave={this.props.disableFirstSave}
                    disablefpSwitcher={this.props.disablefpSwitcher}
                    fpSwitchOff={this.props.fpSwitchOff}
                    switchOffisFillColorSet={this.props.switchOffisFillColorSet}
                    switchOffisStrokeColorSet={this.props.switchOffisStrokeColorSet}
                    layerMoveSwitcher={this.props.layerMoveSwitcher}
                    rotateSwitcher={this.props.rotateSwitcher}
                    getSelectedShapesType={this.props.getSelectedShapesType}
                    // strokeColor={this.props.strokeColor}
                    // strokeWidth={this.props.strokeWidth}
                    isShapeDelete={this.props.isShapeDelete}
                    toggleDeleteShape={this.props.toggleDeleteShape}
                    setStrokeType={this.props.setStrokeType}
                    strokeDasharray={this.props.strokeDasharray}
                    isStrokeTypeChanged={this.props.isStrokeTypeChanged}
                    resetStrokeTypeChanged={this.props.resetStrokeTypeChanged}
                    importReticle={this.props.importReticle}
                    clearImportData={this.props.clearImportData}
                    appVersion={this.props.appVersion}
                    setActiveShapeButton={this.props.setActiveShapeButton}
                />
                {this.props.error ? (
                    <EditorNotifications activeLink={this.props.activeLink}/>
                ) : null}
                <ShapesButtons activeLink={this.props.activeLink} setActiveShapeButton={this.props.setActiveShapeButton} selectedShapeProps={this.props.align.selectedShape} />
                <Zoom activeLink={this.props.activeLink} activeTabId={this.props.activeTabId}/>
            </section>
        )
    }
}

// export default Canvas_section;
const mapStateToProps = state => ({
    error: getError(state),
});

export default connect(mapStateToProps)(Canvas_section);
