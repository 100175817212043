import React from 'react';
import { connect } from 'react-redux';
import { updateGridData, getGridData } from '../../../../redux/grid/grid-reducer';
import { getColorThemeData, editColorThemeData  } from '../../../../redux/colorTheme/colorTheme-reducer';

import './measure.scss';

class Measure extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            counter: 0,
            moaCurrent: this.moaSteps[2],
            milCurrent: this.milSteps[2],
            ruler: false,
            moa: true,
            mil: false,
            gridSize: 1,
            gridBlock: false,
            isHovered: false,
            isActive: false,
            activeTab: '',
            currentValue: 1,
            gridVal: 1,
            gridValueSize: 1,
            isActiveMeasureMenu: false,
            isDayMode: true,
            isNightMode: false
        }
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    gData = [];

    moaSteps = ['1', '2', '3'];
    milSteps = ['1', '2', '3'];
    i = 0;

    hasInputValuePresets = false;

    componentDidMount() {
        let reloadThemesData = JSON.parse(sessionStorage.getItem('colorThemes'));
        let activeTab = JSON.parse(sessionStorage.getItem('activeTab'));

        if (reloadThemesData && activeTab) {
            let colorMode = reloadThemesData.find(el => el.id === activeTab);

            if (colorMode) {
                if (!this.state.isNightMode && colorMode.isNightMode) {
                    this.setState({
                        isNightMode: this.state.isNightMode = true,
                        isDayMode: this.state.isDayMode = false
                    })
                }

                if (this.state.isNightMode && !colorMode.isNightMode) {
                    this.setState({
                        isDayMode: this.state.isDayMode = true,
                        isNightMode: this.state.isNightMode = false
                    })
                }
            }
        }

        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate() {
        this.gData = '';

        try {
            this.gData = this.props.activeTab ? this.props.getGrid.payload.grid.gridData.filter(el => el.id === this.props.activeTab.id) : '';
        } catch(e) {
            console.log(e);
        }

        if (this.gData.length && !this.state.moa && this.gData[0].gridType === 'moa') {
            this.setState({
                moa: this.state.moa = true,
                mil: this.state.mil = false
            })
        }

        if (this.gData.length && !this.state.mil && this.gData[0].gridType === 'mil') {
            this.setState({
                moa: this.state.moa = false,
                mil: this.state.mil = true
            })
        }

        if (this.gData.length && this.state.moaCurrent !== this.gData[0].gridSize) {
            this.setState({
                moaCurrent: this.state.moaCurrent = this.gData[0].gridSize
            })
        }

        if (this.gData.length && this.state.milCurrent !== this.gData[0].gridSize) {
            this.setState({
                milCurrent: this.state.milCurrent = this.gData[0].gridSize
            })
        }

        if (this.props.activeTab && this.props.activeTab.id !== this.state.activeTab) {
            this.setState({
                activeTab: this.state.activeTab = this.props.activeTab.id
            })

            if (this.gData.length && this.state.gridValueSize !== this.gData[0].gridSize) {
                this.setState({
                    gridValueSize: this.state.gridValueSize = this.gData[0].gridSize
                })
            }

            let currentTheme = this.props.getColorTheme.payload.colorTheme.colotThemeData.find(el => el.id === this.props.activeTab.id);

            try {
                if (!this.state.isNightMode && currentTheme && currentTheme.isNightMode) {
                    this.setState({
                        isNightMode: this.state.isNightMode = true,
                        isDayMode: this.state.isDayMode = false
                    })
                }
            } catch(e) {
                console.log(e);
            }

            try {
                if (this.state.isNightMode && !currentTheme.isNightMode) {
                    this.setState({
                        isDayMode: this.state.isDayMode = true,
                        isNightMode: this.state.isNightMode = false
                    })
                }
            } catch(e) {
                console.log(e);
            }

        }

        let hasInputValuePresets = this.state.moa ? this.moaSteps.includes(`${this.state.gridValueSize}`) : this.milSteps.includes(`${this.state.gridValueSize}`);
        const input = document.querySelector('#custopGridLabel');
        const inputs = document.querySelectorAll('.custom-grid_radio');

        if (!hasInputValuePresets && input) {
            inputs.forEach(el => el.checked = false);
            input.classList.add('custom-grid-active');
        } else if (hasInputValuePresets && input) {
            inputs.forEach(el => {
                if (el.value !== `${this.state.gridValueSize}`) {
                    el.checked = false;
                } else {
                    el.checked = true;
                }
            });

            input.classList.remove('custom-grid-active');
        }

        if (this.state.isHovered && !this.state.isActiveMeasureMenu) {
            this.setState({
                isActiveMeasureMenu: true
            })
        } else if (!this.state.isHovered && this.state.isActiveMeasureMenu) {
            this.setState({
                isActiveMeasureMenu: false
            })

            this.props.blur();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            this.setState({
                gridBlock: this.state.gridBlock = false
            })
        }

        let validVal = `${this.state.gridValueSize}`;
        let digitsFormat = validVal.match(/^[\d]{0,2}[.|,]{0,1}[\d]{0,2}/).join('').replace(/,/gi, '.').replace(/w/gi, '');
        
        if (digitsFormat === '0' || digitsFormat === '' || digitsFormat === '.') {
            validVal = this.state.moa ? '1' : '0.8';
        } else if (digitsFormat[digitsFormat.length - 1] === '.') {
            validVal = digitsFormat + '0';
        } else {
            validVal = digitsFormat;
        }

        if (this.state.moa && validVal > 30) {
            validVal = '30';
        }

        if (this.state.mil && validVal > 13) {
            validVal = '13';
        }

        if (validVal <= 0) {
            validVal = '1';
        }
        
        if (this.state.gridValueSize !== +validVal) {
            this.setState({
                currentValue: this.state.currentValue = +validVal,
                gridSize: this.state.gridSize = +validVal,
                gridValueSize: this.state.gridValueSize = +validVal
            })

            this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', validVal);
        }
    }

    hendlerClickUp = () => {
        if (this.state.counter < this.moaSteps.length - 1) {
            this.setState({
                counter: this.state.counter = this.state.counter + 1,
                // gridVal: this.state.gridVal = this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter]
            });
        }

        if (this.state.moa) {
            this.setState({
                moaCurrent: this.state.moaCurrent = this.moaSteps[this.state.counter]
            })
        } else {
            this.setState({
                milCurrent: this.state.milCurrent = this.milSteps[this.state.counter]
            })
        }

        if (this.state.gridSize !== this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter]) {
            this.setState({
                gridSize: this.state.gridSize = this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter],
                currentValue: this.state.currentValue = this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter]
            })
        }

        if (this.state.moa && this.state.gridValueSize < 30) {
            this.setState({
                gridValueSize: this.state.gridValueSize = Math.trunc(this.state.gridValueSize) + 1
            });
        }

        if (this.state.mil && this.state.gridValueSize < 13) {
            this.setState({
                gridValueSize: this.state.gridValueSize = Math.trunc(this.state.gridValueSize) + 1
            });
        }

        // this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', this.state.moa ? this.state.moaCurrent : this.state.milCurrent);
        this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', `${this.state.gridValueSize}`);
    }

    hendlerClickDown = () => {
        if (this.state.counter > 0) {
            this.setState({
                counter: this.state.counter = this.state.counter - 1,
                // gridVal: this.state.gridVal = this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter]
            });
        }

        if (this.state.moa) {
            this.setState({
                moaCurrent: this.state.moaCurrent = this.moaSteps[this.state.counter]
            })
        } else {
            this.setState({
                milCurrent: this.state.milCurrent = this.milSteps[this.state.counter]
            })
        }

        if (this.state.gridSize !== this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter]) {
            this.setState({
                gridSize: this.state.gridSize = this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter],
                currentValue: this.state.currentValue = this.state.moa ? +this.moaSteps[this.state.counter] : +this.milSteps[this.state.counter]
            })
        }

        if (this.state.gridValueSize > 1) {
            this.setState({
                gridValueSize: this.state.gridValueSize = Math.trunc(this.state.gridValueSize) - 1
            });
        }
        
        // this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', this.state.moa ? this.state.moaCurrent : this.state.milCurrent);
        this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', `${this.state.gridValueSize}`);
    }

    handlerRulers = (event) => {
        let btnMove = document.querySelector('#btnMove')
        let btnCircle = document.querySelector('#btnCircle')
        let btnLine = document.querySelector('#btnLine')

        btnCircle.classList.remove('active_btn')
        btnLine.classList.remove('active_btn')
        btnMove.classList.remove('active_btn')

        event.target.classList.toggle('measure-btn-active');

        this.setState({
            ruler: this.state.ruler = !this.state.ruler
        })

        this.props.onSelectRuler(this.state.ruler)
    }

    handlermil = (e) => {
        this.setState({
            mil: this.state.mil = e.target.checked,
            moa: this.state.moa = false
        })

        if (this.state.gridSize > 13 || this.state.gridValueSize > 13) {
            if (this.state.gridValueSize !== 13) {
                this.setState({
                    currentValue: 13,
                    gridSize: 13,
                    gridValueSize: 13
                })
            }

            this.props.onGetGridSize('mil', '13');
        }

        this.props.onSelectGrid(this.state.mil, this.state.moa);
    }

    handlermoa = (e) => {

        this.setState({
            mil: this.state.mil = false,
            moa: this.state.moa = e.target.checked
        })

        this.props.onSelectGrid(this.state.mil, this.state.moa)
    }

    handlerSelectMoa = (e) => {
        if (this.state.moaCurrent !== e.target.value) {
            this.setState({
                moaCurrent: this.state.moaCurrent = e.target.value
            })
        }
        
        if (this.state.gridSize !== e.target.value) {
            this.setState({
                gridSize: this.state.gridSize = +e.target.value,
                currentValue: this.state.currentValue = e.target.value
            })
        }

        if (this.state.gridValueSize !== e.target.value) {
            this.setState({
                gridValueSize: this.state.gridValueSize = +e.target.value
            })
        }

        this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', e.target.value)
    }

    handlerSelectMil = (e) => {
        if (this.state.milCurrent !== e.target.value) {
            this.setState({
                milCurrent: this.state.milCurrent = e.target.value
            })
        }

        if (this.state.gridSize !== e.target.value) {
            this.setState({
                gridSize: this.state.gridSize = +e.target.value,
                currentValue: this.state.currentValue = e.target.value
            })
        }

        if (this.state.gridValueSize !== e.target.value) {
            this.setState({
                gridValueSize: this.state.gridValueSize = +e.target.value
            })
        }

        this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', e.target.value)
    }

    gridSizeInputValidator = value => {
        let num = value.match(/^[\d.]{0,2}[.|,]{0,1}[\d]{0,2}/).join('').replace(/w/gi, '');

        return num;
    }

    handlerGridSize = (e) => {
        let gridVal = this.gridSizeInputValidator(e.target.value);

        this.setState({
            moaCurrent: this.state.moaCurrent = gridVal,
            gridSize: this.state.gridSize = gridVal,
            gridValueSize: this.state.gridValueSize = gridVal
        })
    }

    handlerSetGrid = (e) => {
        if (e.key === 'Enter') {
            let hasInputValuePresets = this.state.moa ? this.moaSteps.includes(e.target.value) : this.milSteps.includes(e.target.value);
            let input = document.querySelector('#custopGridLabel');
            let validVal = e.target.value.trim();
            let digitsFormat = validVal.match(/^[\d]{0,2}[.|,]{0,1}[\d]{0,2}/).join('').replace(/,/gi, '.').replace(/w/gi, '');
            
            if (digitsFormat === '0' || digitsFormat === '' || digitsFormat === '.') {
                validVal = this.state.moa ? '1' : '0.8';
            } else if (digitsFormat[digitsFormat.length - 1] === '.') {
                validVal = digitsFormat + '0';
            } else {
                validVal = digitsFormat;
            }

            if (this.state.moa && validVal > 30) {
                validVal = '30';
            }

            if (this.state.mil && validVal > 13) {
                validVal = '13';
            }

            if (validVal <= 0) {
                validVal = '1';
            }
            
            if (!hasInputValuePresets && input) {
                input.classList.add('custom-grid-active');
            } else {
                input.classList.remove('custom-grid-active');
            }

            if (this.state.gridValueSize !== +validVal) {
                this.setState({
                    currentValue: this.state.currentValue = +validVal,
                    gridSize: this.state.gridSize = +validVal,
                    gridValueSize: this.state.gridValueSize = +validVal
                })
            }

            this.props.onGetGridSize(this.state.moa ? 'moa' : 'mil', validVal);
        }
    }

    handlerActivateGrid = () => {
        this.setState(({ gridBlock }) => ({
            gridBlock: !gridBlock
        }))
    }

    handleEnter() {
        this.setState({
            isHovered: this.state.isHovered = true
        });
    }

    handleLeave() {
        this.setState({
            isHovered: this.state.isHovered = false
        });
    }

    handlerDayOn = () => {
        if (!this.state.isDayMode) {
            this.setState({
                isNightMode: false,
                isDayMode: true
            })

            this.props.updateColorTheme({id: this.props.activeTab.id, isNightMode: false, colorTheme: '#fff'});
        }
    }

    handlerNightOn = () => {
        if (!this.state.isNightMode) {
            this.setState({
                isDayMode: false,
                isNightMode: true
            })

            this.props.updateColorTheme({id: this.props.activeTab.id, isNightMode: true, colorTheme: '#000'});
        }
    }

    render() {
        return (
            <section className='measure_section'>
                {/* <div className='measure-block'>
                    <button id='btnRuler' className='measure-btn rulers'
                        onClick={this.handlerRulers}
                    >Rulers</button>
                </div> */}
                <div ref={this.wrapperRef} className={`measure-block grid-block ${!this.state.gridBlock ? '' : 'grid-block-active'}`} onMouseEnter={this.handleEnter.bind(this)} onMouseLeave={this.handleLeave.bind(this)}>
                    <button className={`measure-paragraph measure-btn grid ${!this.state.gridBlock ? 'measure-btn-Top' : 'grid-active measure-btn-Bottom'}`} onClick={this.handlerActivateGrid}
                    >Grid: {this.state.moa ? `${this.state.gridValueSize} MOA` : `${this.state.gridValueSize} MIL`}</button>
                    {this.state.gridBlock || this.state.isHovered ?
                        (<div className='grid-menu grid-menu-active'>
                            <div className="arrow-up"></div>
                            <form className='formunit'>
                                <legend className='unit-legend'>Units:</legend>
                                <div className='units-wrapper'>
                                    <div className='radio-wrapper'>
                                        <input type='radio' id='milRadio' className='custom-unit_radio' name='gridType' value={this.state.mil}
                                            checked={this.state.mil}
                                            onChange={this.handlermil}
                                        />
                                        <label htmlFor='milRadio' className='custom-unit_radio-label'>MIL</label>
                                    </div>
                                    <div className='radio-wrapper'>
                                        <input type='radio' id='moaRadio' className='custom-unit_radio' name='gridType' value={this.state.moa}
                                            checked={this.state.moa}
                                            onChange={this.handlermoa}
                                        />
                                        <label htmlFor='moaRadio' className='custom-unit_radio-label'>MOA</label>
                                    </div>
                                </div>
                            </form>
                            <div className='grid-size'>
                                <p className='grid-size_label'>Grid: </p>
                            </div>
                            {this.state.moa ? (
                                <form className='grid-list'>
                                    {this.moaSteps.map((step, index) => (
                                        <div className='grid-wrapper' key={index}>
                                            <input type='radio' className='custom-grid_radio' id={'grid-' + index} name='grid-size'
                                                value={step}
                                                checked={this.props.gridSize === Number(step) ? true : false}
                                                onChange={this.handlerSelectMoa}
                                            />
                                            <label htmlFor={'grid-' + index} className='custom-grid_label'>{`${step} MOA`}</label>
                                        </div>
                                    ))}
                                </form>) : (
                                <form className='grid-list'>
                                    {this.milSteps.map((step, index) => (
                                        <div className='grid-wrapper' key={index}>
                                            <input type='radio' className='custom-grid_radio' id={'grid-' + index} name='grid-size'
                                                value={step}
                                                checked={this.props.gridSize === Number(step) ? true : false}
                                                onChange={this.handlerSelectMil}
                                            />
                                            <label htmlFor={'grid-' + index} className='custom-grid_label'>{`${step} MIL`}</label>
                                        </div>
                                    ))}
                                </form>)
                            }
                            <div className='grid-size'>
                                <label htmlFor='gridSize' id='custopGridLabel' className='grid-size_label'>Custom </label>
                                <div className='grid-size_container'>
                                    <button id='downBtn' className='grid_menu-btn btn-down' onClick={this.hendlerClickDown}></button>
                                    <input id='gridSize' className='grid-size_input' type='text'
                                        value={this.state.gridValueSize}
                                        onChange={this.handlerGridSize}
                                        onKeyDown={this.handlerSetGrid}
                                        onFocus={this.props.focus}
                                        onBlur={this.props.blur}
                                    />
                                    <button id='upBtn' className='grid_menu-btn btn-up' onClick={this.hendlerClickUp}></button>
                                </div>
                            </div>
                            <div>
                                {/* <form className='form-night-mode'>
                                    <legend className='night-mode-legend'>Mode:</legend>
                                    <div className='night-mode-wrapper'>
                                        <div className='radio-wrapper' onClick={this.handlerDayOn}>
                                            <input type='radio' id='dayRadio' className='night-mode_radio' name='dayMode' value={this.state.isDayMode}
                                                checked={this.state.isDayMode}
                                                onChange={this.handlerDayOn}
                                            />
                                            <label htmlFor='dayRadio' className='night-mode_radio-label'>Day</label>
                                        </div>
                                        <div className='radio-wrapper' onClick={this.handlerNightOn}>
                                            <input type='radio' id='nightRadio' className='night-mode_radio' name='nightMode' value={this.state.isNightMode}
                                                checked={this.state.isNightMode}
                                                onChange={this.handlerNightOn}
                                            />
                                            <label htmlFor='nightRadio' className='night-mode_radio-label'>Night</label>
                                        </div>
                                    </div>
                                </form> */}
                                <form className='formunit'>
                                    <legend className='unit-legend'>Mode:</legend>
                                    <div className='units-wrapper'>
                                        <div className='radio-wrapper' onClick={this.handlerDayOn}>
                                            <input type='radio' id='dayRadio' className='custom-unit_radio' name='gridType' value={this.state.isDayMode}
                                                checked={this.state.isDayMode}
                                                onChange={this.handlerDayOn}
                                            />
                                            <label htmlFor='dayRadio' className='custom-unit_radio-label'>Day</label>
                                        </div>
                                        <div className='radio-wrapper' onClick={this.handlerNightOn}>
                                            <input type='radio' id='nightRadio' className='custom-unit_radio' name='gridType' value={this.state.isNightMode}
                                                checked={this.state.isNightMode}
                                                onChange={this.handlerNightOn}
                                            />
                                            <label htmlFor='nightRadio' className='custom-unit_radio-label'>Night</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>) : null}
                </div>
                {/* <div className='measure-block'>
                    <Link to="/preview" className='measure-link'
                    // onClick={() => { this.props.onExportPreviewData() }}
                    >
                        <p className='measure-preview preview'>Preview</p>
                    </Link>
                </div> */}
            </section>
        )
    }
}

// export default Measure;

const mapStateToProps = state => ({
    getGrid: getGridData(state),
    getColorTheme: getColorThemeData(state)
});

const mapDispatchToProps = dispatch => ({
    updateGrid: data => dispatch(updateGridData(data)),
    updateColorTheme: data => dispatch(editColorThemeData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Measure);
