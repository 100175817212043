import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  zoomData: [{ id: 'tab-0', index: 100, zx: 0, zy: 0 },],
  currentZoomIndex: 100,
  currentZoomPosition: {x: 0, y: 0},
}

export const zoomSlice = createSlice({
  name: 'zoom',
  initialState,
  reducers: {
    addZoomData: (state, zoomObj) => {
        state.zoomData = [...state.zoomData, {id: zoomObj.payload.id, index: zoomObj.payload.index}];
        state.currentZoomIndex = 100;
    },
    deleteZoomData: (state, id) => {
        let newZoomData = state.zoomData.filter(el => el.id !== id.payload);
        state.zoomData = newZoomData;
        // state.currentZoomIndex = 100;
        state.currentZoomIndex = state.zoomData[state.zoomData.length - 1].index;
        state.currentZoomPosition = {
          x: state.zoomData[state.zoomData.length - 1].zx,
          y: state.zoomData[state.zoomData.length - 1].zy,
        };
    },
    updateZoomData: (state, zoomData) => {
        let updatedArr = state.zoomData.map(zoomEl => {
            if (zoomEl.id === zoomData.payload.id) {
                zoomEl.index = zoomData.payload.index
                return zoomEl
            } else {
                return zoomEl
            }
        })

        state.zoomData = updatedArr
        state.currentZoomIndex = zoomData.payload.index
    },
    updateZoomPosition: (state, zoomCoords) => {
      let updatedZoomArr = state.zoomData.map(zoomEl => {
        if (zoomEl.id === zoomCoords.payload.id) {
          zoomEl.zx = zoomCoords.payload.zx;
          zoomEl.zy = zoomCoords.payload.zy;
          return zoomEl;
        } else {
          return zoomEl;
        }
      })

      state.zoomData = updatedZoomArr;
      state.currentZoomPosition = {x: zoomCoords.payload.zx, y: zoomCoords.payload.zy}
    },
    getZoomData: state => state.zoom,
    setCurrentZoomData: (state, id) => {
        let currentZoomData = state.zoomData.filter(el => el.id === id.payload)
        state.currentZoomIndex = currentZoomData[0].index
    },
    updateAfterRefrefh: (state, arr) => {
        state.zoomData = arr.payload
    }
  },
});

export const {
  addZoomData,
  deleteZoomData,
  updateZoomData,
  updateZoomPosition,
  getZoomData,
  setCurrentZoomData,
  updateAfterRefrefh,
} = zoomSlice.actions

export default zoomSlice.reducer;
