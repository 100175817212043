import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './editorNotifications.scss';
import { clearError } from '../../../../redux/reticles/reticles-actions';
import { getError } from '../../../../redux/reticles/reticles-selectors';


export default function EditorNotifications({ activeLink }) {
    const dispatch = useDispatch();
    const errorMessage = useSelector(getError);

    const handleCloseNotification = () => {
        dispatch(clearError());
    };

    return (
        <section className={`${activeLink === '2' ? 'notificationsSectionInLib' : activeLink === '' ? 'notificationsNotActiveLink' : 'notificationsActiveLink'}`}>
            <p style={{ margin: 'auto', fontWeight: 500, fontSize: 20, paddingRight:10, paddingLeft:10 }}>{errorMessage}</p>
            <button className='notification_close-btn' onClick={handleCloseNotification}></button>
        </section>
    );
}
