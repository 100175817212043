import { useEffect, useCallback, useState, useLayoutEffect } from 'react';
import _ from 'lodash'
import ReticleCardNew from './ReticleCardNew';
import ReticleInfo from './ReticleInfo';
import Search from './Search';
import Filters from './Filters';
import FilterModal from './FilterModal';
import UserAccount from '../authentication/UserAccount';
import CardSkeleton from './CardSkeleton';


import { ReactComponent as EmptyCollectionIcon } from '../../../img/emptyCollectionIcon.svg';
import { ReactComponent as CreateReticleIcon } from '../../../img/create-reticle-icon.svg';

import { useSelector, useDispatch } from 'react-redux';
import { getIsAuthenticated } from '../../../../redux/auth/auth-selectors';
import { fetchUserReticles } from '../../../../redux/reticles/reticles-operations';
import { getAllUserReticles, getSkipUserReticles, getFetchingUserReticles, getFetchError, getFetchReticlesLoading, userReticlesCount } from '../../../../redux/reticles/reticles-selectors';
import { setIsFetchingUser, setSkipAdd, setSkipRemove } from '../../../../redux/reticles/reticles-actions';

import './library.scss';

const MyLib = ({ 
    activeButton, 
    activeTabId, 
    focus, 
    blur, 
    handleOpenCard, 
    openCard, 
    reticle, 
    onExportingTool, 
    handleCloseCard, 
    handlerActivateButton, 
    handleToggleFilterModal, 
    showFilterModal, 
    openHeaderButton, 
    headerSearchValue,
    handleUseFilters,
    isUseFilters,
    onChangeHeaderSearchValue,
    handlerAddTab,
    deviceWidth,
    handleActiveLib,
    handleChangeSelectedCardStatus,
    isSelectedCard,
    checkBoxChange,
    selectedReticles,
}) => {

    const dispatch = useDispatch();

    const loginStatus = useSelector(getIsAuthenticated);
    const reticles = useSelector(getAllUserReticles);
    const skip = useSelector(getSkipUserReticles);
    const fetching = useSelector(getFetchingUserReticles);
    const fetchingError = useSelector(getFetchError);
    const fetchReticleLoading = useSelector(getFetchReticlesLoading);
    const reticlesCount = useSelector(userReticlesCount);


    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [sortValue, setSortValue] = useState('');
    const [activeSort, setActiveSort] = useState('');
    const [firstRender, setFirstRender] = useState(false);

    const [isActiveType, setIsActiveType] = useState(false);
    const [isActiveRange, setIsActiveRange] = useState(false);
    const [isActiveMeasurements, setIsActiveMeasurements] = useState(false);
    const [isActiveRangefinder, setIsActiveRangefinder] = useState(false);
    const [isActiveSort, setIsActiveSort] = useState(false);


    const [selectedType, setIsSelectedType] = useState('Any');
    const [selectedRange, setIsSelectedRange] = useState('Any');
    const [selectedMeasurements, setIsSelectedMeasurements] = useState('Any');
    const [selectedRangefinder, setIsSelectedRangefinder] = useState('Any');
    const [selectedSort, setIsSelectedSort] = useState('Any');



    const handleOpen = () => {
        setOpen(true)
    };

    const handleClearFilter = () => {
        setValue('');
        setActiveSort('')
        setSortValue('')
        dispatch(setSkipRemove(0));
        fetchReticles(0, `searchQuery=`);
    };

    useEffect(() => {
        if (headerSearchValue !== '' && !isUseFilters && firstRender) {
            setValue(headerSearchValue);
            setActiveSort('');
            setSortValue('');
            dispatch(setSkipRemove(0));

            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

            fetchReticles(0, `searchQuery=${headerSearchValue}`);
        } else if (headerSearchValue === '' && !isUseFilters && firstRender) {
            handleClearFilter()
        }
    }, [headerSearchValue]);

    useEffect(() => {
        dispatch(setSkipRemove(0));
    }, []);

    useEffect(() => {
        setFirstRender(true)
    }, []);

    const fetchReticles = useCallback(
        _.debounce((skip, value) => dispatch(fetchUserReticles(skip, value)), 600
        ), []
    );

    useEffect(() => {
        if (loginStatus && fetching) {
            if (value !== '') {
                fetchReticles(skip, `searchQuery=${value}`)

            } else if (value === '') {
                dispatch(fetchUserReticles(skip, sortValue));
            }
        } else return
    }, [loginStatus, fetching]);

    useLayoutEffect(() => {
        return () => {
            dispatch(setSkipRemove(0));
            setValue('')
            dispatch(setIsFetchingUser(true));
        }
    }, []);

    useEffect(() => {
        if (loginStatus && !fetching) {
            let scrollBlock = document.querySelector('.libraries_card-block');
            scrollBlock.addEventListener('scroll', scrollHendler);

            return function () {
                scrollBlock.removeEventListener('scroll', scrollHendler);
            }
        }
    }, [loginStatus, fetching]);

    const scrollHendler = (e) => {
        if (e.target.scrollTop === 0) {
            return
        }

        if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
            dispatch(setSkipAdd(16));
            dispatch(setIsFetchingUser(true));
        }
        // console.log('scrollHeigth', e.target.scrollHeight)
        // console.log('scrollTop', e.target.scrollTop)
        // console.log('innerHeight', window.innerHeight)
    };

    const onChange = useCallback(e => {
        setValue(e.target.value);
        onChangeHeaderSearchValue(e);

        setActiveSort('');
        setSortValue('');

        dispatch(setSkipRemove(0));
        fetchReticles(skip, `searchQuery=${e.target.value}`);
    }, [dispatch]);

    const handleModalFilterChange = (title, textContent) => {
        setSortValue(textContent)
        if (title === 'Type') {
            setIsSelectedType(textContent)
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Range') {
            setIsSelectedRange(textContent)
            setIsSelectedType('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Measurements') {
            setIsSelectedMeasurements(textContent)
            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedRangefinder('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Rangefinder') {
            setIsSelectedRangefinder(textContent)
            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedSort('Any')

        } else if (title === 'Sort') {
            setIsSelectedSort(textContent)
            setIsSelectedType('Any')
            setIsSelectedRange('Any')
            setIsSelectedMeasurements('Any')
            setIsSelectedRangefinder('Any')
        }
    };

    const handlerModalFilterSetSort = useCallback(() => {
        setValue('')
        dispatch(setSkipRemove(0));
        handleUseFilters();

        if (sortValue === 'Any' || sortValue === '') {
            setSortValue('')
            dispatch(fetchUserReticles(0, ''))
        } else if (sortValue === 'Download count') {
            setSortValue('byNumDownloads=DESC')
            dispatch(fetchUserReticles(0, 'byNumDownloads=DESC'))

        } else if (sortValue === 'Upload date') {
            setSortValue('byCreated=DESC')
            dispatch(fetchUserReticles(0, 'byCreated=DESC'))
        } else {
            setSortValue(`searchQuery=${sortValue}`)
            dispatch(fetchUserReticles(0, `searchQuery=${sortValue}`))
        }
    });


    const handlerSetSort = useCallback((title, textContent) => {

        setValue('')
        setActiveSort(textContent)
        dispatch(setSkipRemove(0));

        if(textContent === '') {
                setSortValue('')
                dispatch(fetchUserReticles(0, ''))
        } else if (textContent === 'Download count') {
                setSortValue('byNumDownloads=DESC')
                dispatch(fetchUserReticles(skip, 'byNumDownloads=DESC'))

            } else if (textContent === 'Upload date') {
                setSortValue('byCreated=DESC')
                dispatch(fetchUserReticles(skip, 'byCreated=DESC'))
            } else {
                setSortValue(`searchQuery=${textContent}`)
                dispatch(fetchUserReticles(skip, `searchQuery=${textContent}`))
            }

    }, [dispatch]);

    const handleCreateNewReticle = () => {
        handlerAddTab();
        handlerActivateButton('1');
    }

    return (
        <>
            {openCard? (
                <div className='card_info-block'>
                    <ReticleInfo reticle={reticle} activeButton={activeButton} activeTabId={activeTabId} onExportingTool={onExportingTool} handleCloseCard={handleCloseCard} handlerActivateButton={handlerActivateButton} deviceWidth={deviceWidth}/>
                </div>
            ) : null}

            {openHeaderButton === 'userProfile' && deviceWidth < 1020 ? (
                <div className='card_info-block'>
                    <UserAccount />
            </div>
            ) : null}

            <div className='libraries__filters-block'>
                <Search value={value} onChange={onChange} focus={focus} blur={blur} handleClearFilter={handleClearFilter}/>
                <button className={`libraries_filterButton ${isFiltersOpen ? 'libraries_filterButton-active' : ''}`} onClick={() => setIsFiltersOpen(!isFiltersOpen)}>Filters</button>
            </div>

            {isFiltersOpen? (
                <Filters 
                    handlerSetSort={handlerSetSort} 
                    activeSort={activeSort} 
                />
            ) : null}


            <div className={`libraries_card-block ${isFiltersOpen ? 'libraries_card-block-openFilter' : 'libraries_card-block-closeFilter'}`}>
                {(fetchReticleLoading && !reticles.length) &&
                    <ul className='libraries_card-list'>
                        <CardSkeleton cards={16} 
                    />
                    </ul>
                }

                {reticles.length ? (
                    <ul className='libraries_card-list'>
                        <li className='reticleCard reticle_createCard' onClick={() => handleCreateNewReticle()}>
                            <div style={{top: '50%',left: '50%',transform: 'translate(-50%, -50%)',position: 'absolute'}}>
                                <CreateReticleIcon />
                                <h3 className='reticleCard__title'>Create your reticle</h3>
                            </div>
                        </li>

                        {reticles.map(reticle => (
                            <ReticleCardNew key={reticle.id} reticle={reticle} activeButton={activeButton} activeTabId={activeTabId} handleOpenCard={handleOpenCard} handleOpen={handleOpen} deviceWidth={deviceWidth} isSelectedCard={isSelectedCard} handleChangeSelectedCardStatus={handleChangeSelectedCardStatus} selectedReticles={selectedReticles} checkBoxChange={checkBoxChange}/>))}
                    </ul>
                ) : null}

                {!fetchReticleLoading ? (
                    <>
                        {!reticles.length && reticlesCount === 0 ? (
                            <div className='libraries_collection-empty'>
                                <EmptyCollectionIcon />
                                <h2 className='libraries_collection-empty-title'>Your collection is empty.</h2>
                                <p className='libraries_collection-empty-text'>Start building your collection by
                                    <button className='libraries_collection-empty-button' onClick={() => handleActiveLib('2')}>saving</button>
                                    or
                                    {deviceWidth < 1019 ? ' creating ' : (<button className='libraries_collection-empty-button' onClick={() => handleCreateNewReticle()}>creating</button>)}
                                    reticles.
                                </p>
                            </div>
                        ) : null}

                        {(!reticles.length && fetchingError === 'RETICLE_NOT_FOUND' && (value || sortValue)) && reticlesCount > 0 ? (
                            <div className='libraries_collection-empty'>
                                <EmptyCollectionIcon />
                                <h2 className='libraries_collection-empty-title'>Reticle Not Found</h2>
                                <p className='libraries_collection-empty-text'>Sorry, we couldn't find a matching reticle for your search. Please try a different term.</p>
                            </div>
                        ) : null}

                    </>
                ) : null} 
            </div>

            {showFilterModal ? (
                <FilterModal 
                    handleToggleFilterModal={handleToggleFilterModal}
                    handleModalFilterChange={handleModalFilterChange}
                    handlerModalFilterSetSort={handlerModalFilterSetSort} 
                    isActiveType={isActiveType}
                    setIsActiveType={setIsActiveType}
                    isActiveRange={isActiveRange}
                    setIsActiveRange={setIsActiveRange}
                    isActiveMeasurements={isActiveMeasurements}
                    setIsActiveMeasurements={setIsActiveMeasurements}
                    isActiveRangefinder={isActiveRangefinder}
                    setIsActiveRangefinder={setIsActiveRangefinder}
                    selectedType={selectedType}
                    selectedRange={selectedRange}
                    selectedMeasurements={selectedMeasurements}
                    selectedRangefinder={selectedRangefinder}
                    isActiveSort={isActiveSort}
                    setIsActiveSort={setIsActiveSort}
                    selectedSort={selectedSort}
                />
            ): null}
        </>
    )
}

export default MyLib;
