import React from 'react';
import defaultUserPhoto from '../../../../img/default-user-photo.svg';
import './targets_menu.scss';
import { useSelector } from 'react-redux';
import { getIsAuthenticated, getUserFirstName, getUserUrl } from '../../../../../redux/auth/auth-selectors';


const TargetsMenu = ({ handlerActivateButton, activeLink }) => {
  const profPic = useSelector(getUserUrl);
  const loginStatus = useSelector(getIsAuthenticated);
  const firstName = useSelector(getUserFirstName);

  return (
    <>
      <section>
        <button onClick={() => activeLink === '1' ? handlerActivateButton('') : handlerActivateButton('1')} className={`targets_menu-btn  targets_menu-scene-btn ${activeLink === '1' ? 'targets-btn_active targets_menu-scene-btn-active' : ''}`}>Scenes</button>
        {/* <button data-index='2' onClick={handlerActivateButton} className={`targets_menu-btn targets_menu-targets-btn ${activeLink === '2' ? 'targets-btn_active' : ''}`}>Targets</button> */}
      </section>
      <section className='menu-secction_user'>

        <section className='menu-secction_user'>
          {!loginStatus ? (
            <button onClick={() => activeLink === '3' ? handlerActivateButton('') : handlerActivateButton('3')} className={`lib-btn log-in_btn ${activeLink === '3' ? 'lib-btn_active log-in_btn-active ' : ''}`}>Profile</button>
          ) : (
              <button onClick={() => activeLink === '3' ? handlerActivateButton('') : handlerActivateButton('3')} className={`lib-btn log-in_userBtn ${activeLink === '3' ? 'lib-btn_active' : ''}`}>
                <img onClick={() => activeLink === '3' ? handlerActivateButton('') : handlerActivateButton('3')} src={profPic ? profPic : defaultUserPhoto} alt='User avatar' className='user-photo' />
              {firstName}
            </button>
          )
          }
        </section>
      </section>
    </>
  );
}

export default TargetsMenu;
