import React from 'react';
import { Link } from "react-router-dom";
import './return.scss';

export default function Return() {
  return (
    <Link to="/" className='preview_return-link'>
      <button id='returnBtn' className='preview_return-btn'>Exit preview mode</button>
    </Link>
  );
}
