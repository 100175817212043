import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import {
    addReticlesRequest,
    addReticlesSuccess,
    addReticlesError,
    resaveReticlesRequest,
    resaveReticlesSuccess,
    resaveReticlesError,
    copyReticleRequest,
    copyReticleSuccess,
    copyReticleError,
    deleteReticlesRequest,
    resaveReticleInfoRequest,
    resaveReticleInfoSuccess,
    resaveReticleInfoError,
    deleteReticlesSuccess,
    deleteReticlesError,
    savePublicReticleRequest,
    savePublicReticleSuccess,
    savePublicReticleError,
    deletePublicReticlesRequest,
    deletePublicReticlesSuccess,
    deletePublicReticlesError,
    fetchReticlesRequest,
    fetchReticlesSuccess,
    fetchReticlesError,
    fetchPublicReticlesRequest,
    fetchPublicReticlesSuccess,
    fetchPublicReticlesError,
    setIsFetchingUser,
    setIsFetchingPublic,
    setSkipAdd,
    setSkipRemove,
    setSkipAddPublic,
    setSkipRemovePublic,
    // changeUserStatusRequest,
    // changeUserStatusSuccess,
    // changeUserStatusError,
    changeUserStatusReticleInPublicRequest,
    changeUserStatusReticleInPublicSuccess,
    changeUserStatusReticleInPublicError,
    changeStatusRequest,
    changeStatusSuccess,
    changeStatusError,
    changeAdminStatusRequest,
    changeAdminStatusSuccess,
    changeAdminStatusError,
    // changeAdminShareStatusRequest,
    // changeAdminShareStatusSuccess,
    // changeAdminShareStatusError,
    changeAdminStatusReticleInPublicRequest,
    changeAdminStatusReticleInPublicSuccess,
    changeAdminStatusReticleInPublicError,
    changeCurrentReticle,
    changeCalculationRequest,
    changeCalculationSuccess,
    changeCalculationError,
    exportSuccess,
    exportError,
    clearError,
    openReticle,
    closeReticle,
    fetchAdminReticlesRequest,
    fetchAdminReticlesSuccess,
    fetchAdminReticlesError,
    setIsFetchingAdmin,
    setSkipAddAdmin,
    setSkipRemoveAdmin,
    resaveAdminReticleInfoRequest,
    resaveAdminReticleInfoSuccess,
    resaveAdminReticleInfoError,
    fetchPublicFoldersRequest,
    fetchPublicFoldersSuccess,
    fetchPublicFoldersError,
    clearPublicFolders,
    publishReticleRequest,
    publishReticleSuccess,
    publishReticlesError,
    fetchFolderReticlesRequest,
    fetchFolderReticlesSuccess,
    fetchFolderReticlesError,
    // setIsFetchingFolderReticles,
    // setSkipAddFolderReticles,
    // setSkipRemoveFolderReticles,
    fetchShareReticleRequest,
    fetchShareReticleSuccess,
    fetchShareReticleError,
    fetchDownloadReticleRequest,
    fetchDownloadReticleSuccess,
    fetchDownloadReticleError,
    clearDownloadReticle,

} from './reticles-actions';

import {
    addTab
} from './tabs-reducer';

const defaultUserReticlesState = {
    items: [],
    isFetching: true,
    count: 0,
    skip: 0,
};

const defaultPublicReticlesState = {
    items: [],
    isFetching: true,
    count: 0,
    skip: 0,
};

const defaultAdminReticlesState = {
    items: [],
    isFetching: true,
    count: 0,
    skip: 0,
};


const defaultCurrentReticleData = {
    status: '',
    id: '',
    publicOpenId: '',
    reticleUserEmail: ''
};

const defaultOpenReticleInfo = {
    status: '',
    id: '',
    name: '',
    description: '',
    tags: []
};

const defaultSavingPublicReticleInfo = {
    status: '',
    id: '',
};

const defaultPublicFolders = {
    folders: [],
    count: 0,
    activeFolderId: '',
    activeFolderName: '',
};

const defaultDownloadReticle = {
    reticle: ''
};



const userReticles = createReducer(defaultUserReticlesState, {
    [fetchReticlesSuccess]: (state, { payload }) => {
        return {
            ...state, 
            items: [...state.items, ...payload.reticles], 
            isFetching: false,
            count: payload.count,
        }
    },
    [setIsFetchingUser]: (state, { payload }) => {
        return {
            ...state,
            isFetching: payload
        }
    },
    [addReticlesSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: [payload, ...state.items],
        }
    },
    [resaveReticlesSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.map(item => {if(item.id === payload.id) { return payload;}return item;}),
        }
    },
    [copyReticleSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: [payload, ...state.items],
        }
    },
    [resaveReticleInfoSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.map(item => { if (item.id === payload.id) { return payload; } return item; }),
        }
    },
    [deleteReticlesSuccess]: (state, { payload }) => 
    {
        return {
            ...state,
            items: state.items.reduce((acc, item) => {
                if (!payload.includes(item.id)) acc.push(item);
                return acc;
            }, []),
            isFetching: false,
            count: state.count - payload.length
        }
    },
    [setSkipAdd]: (state, { payload }) => {
        return {
            ...state,
            skip: state.skip + payload,
        }
    },
    [setSkipRemove]: (state, { payload }) => {
        return {
            ...state,
            skip: payload,
            items: [],
        }
    },
    // [changeUserStatusSuccess]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         items: state.items.map(item => { if (item.id === payload.id) { return payload; } return item; }),
    //     }
    // },
        [changeStatusSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.map(item => { if (item.id === payload.id) { return payload; } return item; }),
        }
    },
});

const publicReticles = createReducer(defaultPublicReticlesState, {
    [fetchPublicReticlesSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: [...state.items, ...payload.reticles],
            isFetching: false,
            count: payload.count,
        }
    },
    [setIsFetchingPublic]: (state, { payload }) => {
        return {
            ...state,
            isFetching: payload,
        }
    },
    [setSkipAddPublic]: (state, { payload }) => {
        return {
            ...state,
            skip: state.skip + payload,
        }
    },
    [setSkipRemovePublic]: (state, { payload }) => {
        return {
            ...state,
            items: [],
            skip: payload,
            isFetching: false,
        }
    },
    [fetchFolderReticlesSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: [...state.items, ...payload.reticles],
            isFetching: false,
            count: payload.count,
        }
    },
    [changeUserStatusReticleInPublicSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.filter(({ id }) => id !== payload.id),
        }
    },
    [changeAdminStatusReticleInPublicSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.filter(({ id }) => id !== payload.id),
        }
    },
    // [setIsFetchingFolderReticles]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         isFetching: payload,
    //     }
    // },
    // [setSkipAddFolderReticles]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         skip: state.skip + payload,
    //     }
    // },
    // [setSkipRemoveFolderReticles]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         items: [],
    //         skip: payload,
    //         isFetching: false,
    //     }
    // },

    [deletePublicReticlesSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.filter(({ id }) => id !== payload.id),
            isFetching: false,
        }
    },
    [changeCalculationSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.map(item => { 
                if (item.id === payload.id) { 
                    return {
                        ...item,
                        sumDownloads: payload.num,
                    }
                } return item;
            }),
        }
    },

});

const adminReticles = createReducer(defaultAdminReticlesState, {
    [fetchAdminReticlesSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: [...state.items, ...payload.reticles],
            isFetching: false,
            count: payload.count,
        }
    },
    [setIsFetchingAdmin]: (state, { payload }) => {
        return {
            ...state,
            isFetching: payload,
        }
    },
    [setSkipAddAdmin]: (state, { payload }) => {
        return {
            ...state,
            skip: state.skip + payload,
        }
    },
    [setSkipRemoveAdmin]: (state, { payload }) => {
        return {
            ...state,
            items: [],
            skip: payload,
            isFetching: false,
        }
    },
    [resaveAdminReticleInfoSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.map(item => { if (item.id === payload.id) { return payload; } return item; }),
        }
    },
    [changeAdminStatusSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.filter(({ id }) => id !== payload.id),
            isFetching: false,
        }
    },
    [publishReticleSuccess]: (state, { payload }) => {
        return {
            ...state,
            items: state.items.filter(({ id }) => id !== payload.id),
            isFetching: false,
        }
    },
    

    // [deletePublicReticlesSuccess]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         items: state.items.filter(({ id }) => id !== payload.id),
    //         isFetching: false,
    //     }
    // },
    // [changeCalculationSuccess]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         items: state.items.map(item => {
    //             if (item.id === payload.id) {
    //                 return {
    //                     ...item,
    //                     sumDownloads: payload.num,
    //                 }
    //             } return item;
    //         }),
    //     }
    // },
});

const publicFolders = createReducer(defaultPublicFolders, {
    [fetchPublicFoldersSuccess]: (state, { payload }) => {
        return {
            ...state,
            folders: [...state.folders, ...payload.folders].sort((x, y) => x.name.localeCompare(y.name)),
            count: payload.count,
        }
    },
    [clearPublicFolders]: (_, __ ) => {
        return {
            folders: [],
            count: 0,
            activeFolderId: '',
            activeFolderName: '',
        }
    },
});

const currentReticleData = createReducer(defaultCurrentReticleData, {
    [addReticlesSuccess]: (_, { payload }) => {
        return {
            currentReticleStatus: payload.status,
            id: payload.id,
            publicOpenId: ''
        }
    },
    [addTab]: (_, __) => {
        return {
            currentReticleStatus: '',
            id: '',
            publicOpenId: ''
        }
    },
    [changeCurrentReticle]: (_, { payload }) => {
        return {
            currentReticleStatus: payload.status,
            id: payload.savingId,
            publicOpenId: payload.publicId,
            reticleUserEmail: payload.reticleUserEmail
        }
    },
    // [deleteReticlesSuccess]: (_, __ ) => {
    //     return {
    //         currentReticleStatus: '',
    //         id: '',
    //         publicOpenId: ''
    // }
    // },

    // [changeUserStatusSuccess]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         currentReticleStatus: payload.status
    //     }
    // },
    // [changeAdminShareStatusSuccess]: (state, { payload }) => {
    //     return {
    //         ...state,
    //         currentReticleStatus: payload.status
    //     }
    // },
});

const openReticleInfo = createReducer(defaultOpenReticleInfo, {
    [changeStatusSuccess]: (state, { payload }) => {
        return {
            ...state,
            status: payload.status
        }
    },
    [changeUserStatusReticleInPublicSuccess]: (state, { payload }) => {
        return {
            ...state,
            status: payload.status
        }
    },
    [changeAdminStatusReticleInPublicSuccess]: (state, { payload }) => {
        return {
            ...state,
            status: payload.status
        }
    },
    [openReticle]: (_, { payload }) => {
        return {
            id: payload.id,
            status: payload.status,
            name: payload.name,
            description: payload.description,
            tags: payload.tags,
            reason: payload.reason ? payload.reason : ''
        }
    },
    [closeReticle]: (_, __) => {
        return {
            id: '',
            status: '',
            name: '',
            description: '',
            tags: []
        }
    },
    [resaveReticleInfoSuccess]: (state, { payload }) => {
        return {
            ...state,
            name: payload.name,
            description: payload.description,
            tags: payload.tags
        }
    },
    [resaveAdminReticleInfoSuccess]: (state, { payload }) => {
        return {
            ...state,
            name: payload.name,
            description: payload.description,
            tags: payload.tags
        }
    },
    [fetchShareReticleSuccess]: (_, { payload }) => {
        return {
            ...payload
        }
    },
});

const downloadReticle = createReducer(defaultDownloadReticle, {
    [fetchDownloadReticleSuccess]: (_, { payload }) => {
        return {
            reticle: payload
        }
    },
    [clearDownloadReticle]: (_, __) => {
        return {
            reticle: ''
        }
    },
});

const savingPublicReticleInfo = createReducer(defaultSavingPublicReticleInfo, {
    [savePublicReticleSuccess]: (_, { payload }) => {
        return {
            status: payload.status,
            id: payload.id
        }
    },
});

const loading = createReducer(false, {
    // [fetchReticlesRequest]: () => true,
    // [fetchReticlesSuccess]: () => false,
    // [fetchReticlesError]: () => false,

    // [fetchPublicReticlesRequest]: () => true,
    // [fetchPublicReticlesSuccess]: () => false,
    // [fetchPublicReticlesError]: () => false,

    [addReticlesRequest]: () => true,
    [addReticlesSuccess]: () => false,
    [addReticlesError]: () => false,

    [resaveReticlesRequest]: () => true,
    [resaveReticlesSuccess]: () => false,
    [resaveReticlesError]: () => false,

    [savePublicReticleRequest]: () => true,
    [savePublicReticleSuccess]: () => false,
    [savePublicReticleError]: () => false,

    [deleteReticlesRequest]: () => true,
    [deleteReticlesSuccess]: () => false,
    [deleteReticlesError]: () => false,

    [deletePublicReticlesRequest]: () => true,
    [deletePublicReticlesSuccess]: () => false,
    [deletePublicReticlesError]: () => false,

    // [changeUserStatusRequest]: () => true,
    // [changeUserStatusSuccess]: () => false,
    // [changeUserStatusError]: () => false,

    [changeUserStatusReticleInPublicRequest]: () => true,
    [changeUserStatusReticleInPublicSuccess]: () => false,
    [changeUserStatusReticleInPublicError]: () => false,

    [changeStatusRequest]: () => true,
    [changeStatusSuccess]: () => false,
    [changeStatusError]: () => false,

    [changeCalculationRequest]: () => true,
    [changeCalculationSuccess]: () => false,
    [changeCalculationError]: () => false,
});

const fetchReticlesLoading = createReducer(false, {
    [fetchReticlesRequest]: () => true,
    [fetchReticlesSuccess]: () => false,
    [fetchReticlesError]: () => false,

    [fetchPublicReticlesRequest]: () => true,
    [fetchPublicReticlesSuccess]: () => false,
    [fetchPublicReticlesError]: () => false,

    [fetchFolderReticlesRequest]: () => true,
    [fetchFolderReticlesSuccess]: () => false,
    [fetchFolderReticlesError]: () => false,

    [fetchAdminReticlesRequest]: () => true,
    [fetchAdminReticlesSuccess]: () => false,
    [fetchAdminReticlesError]: () => false,
});

const error = createReducer(null, {
    [addReticlesError]: (_, { payload }) => payload,
    [addReticlesSuccess]: () => null,

    [resaveReticlesError]: (_, { payload }) => payload,
    [resaveReticlesSuccess]: () => null,

    [deleteReticlesError]: (_, { payload }) => payload,
    [deleteReticlesSuccess]: () => null,

    [savePublicReticleError]: (_, { payload }) => payload,
    [savePublicReticleSuccess]: () => null,

    [deletePublicReticlesError]: (_, { payload }) => payload,
    [deletePublicReticlesSuccess]: () => null,

    // [changeUserStatusError]: (_, { payload }) => payload,
    // [changeUserStatusSuccess]: () => null,

    [changeUserStatusReticleInPublicError]: (_, { payload }) => payload,
    [changeUserStatusReticleInPublicSuccess]: () => null,

    [changeStatusError]: (_, { payload }) => payload,
    [changeStatusSuccess]: () => null,

    [changeCalculationError]: (_, { payload }) => payload,
    [changeCalculationSuccess]: () => null,

    [fetchDownloadReticleError]: (_, { payload }) => payload,
    [fetchDownloadReticleSuccess]: () => null,

    [clearError]: () => null,
});

const fetchError = createReducer(null, {
    [fetchReticlesError]: (_, { payload }) => payload,
    [fetchReticlesSuccess]: () => null,
    [fetchPublicReticlesError]: (_, { payload }) => payload,
    [fetchPublicReticlesSuccess]: () => null,

    [fetchFolderReticlesError]: (_, { payload }) => payload,
    [fetchFolderReticlesSuccess]: () => null,

    [fetchAdminReticlesError]: (_, { payload }) => payload,
    [fetchAdminReticlesSuccess]: () => null,
});

const publishError = createReducer(null, {
    [publishReticlesError]: (_, { payload }) => payload,
    [publishReticleSuccess]: () => null,
});


export default combineReducers({
    userReticles,
    publicReticles,
    adminReticles,
    currentReticleData,
    openReticleInfo,
    savingPublicReticleInfo,
    loading,
    error,
    fetchError,
    publicFolders,
    publishError,
    downloadReticle,
    fetchReticlesLoading
});
