import React from 'react';
import './undo.scss';

export default function Undo(props) {

  return (
    <section className='undo-section'>
      <button id='undoBtn' className='undo-btn last-action_btn'
        onClick={(e) => {props.undo()}}
      >
        <span className='button_text'>Undo</span>
      </button>
      <button id='redoBtn' className='redo-btn last-action_btn'
        onClick={(e) => {props.redo()}}
      >
        <span className='button_text'>Redo</span>
      </button>
    </section>
  );
}
