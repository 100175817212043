import React from 'react';
import './clear.scss';

export default function Clear(props) {

  return (
    <section className='clear-section'>
      <button id='clearBtn' className='delete-btn' onClick={(e) => { props.toggleDeleteShape() }}>
        <span className='clear_text'>Delete</span>
      </button>

      <button id='clearBtn' className='clearAll-btn' onClick={(e) => { props.clear() }}>
        <span className='clear_text'>Clear All</span>
      </button>
    </section>
  );
}
