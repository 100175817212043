import { createAction } from '@reduxjs/toolkit';

export const registerRequest = createAction('auth/registerRequest');
export const registerSuccess = createAction('auth/registerSuccess');
export const registerError = createAction('auth/registerError');

export const confirmEmailRequest = createAction('auth/confirmEmailRequest');
export const confirmEmailSuccess = createAction('auth/confirmEmailSuccess');
export const confirmEmailError = createAction('auth/confirmEmailError');

export const loginRequest = createAction('auth/loginRequest');
export const loginSuccess = createAction('auth/loginSuccess');
export const loginError = createAction('auth/loginError');

export const googleLoginRequest = createAction('auth/googleLoginRequest');
export const googleLoginSuccess = createAction('auth/googleLoginSuccess');
export const googleLoginError = createAction('auth/googleLoginError');

export const logoutRequest = createAction('auth/logoutRequest');
export const logoutSuccess = createAction('auth/logoutSuccess');
export const logoutError = createAction('auth/logoutError');

export const getCurrentUserRequest = createAction('auth/getCurrentUserRequest');
export const getCurrentUserSuccess = createAction('auth/getCurrentUserSuccess');
export const getCurrentUserError = createAction('auth/getCurrentUserError');

export const updateUserRequest = createAction('auth/updateUserRequest');
export const updateUserSuccess = createAction('auth/updateUserSuccess');
export const updateUserError = createAction('auth/updateUserError');

export const resetPasswordRequest = createAction('auth/resetPasswordRequest');
export const resetPasswordSuccess = createAction('auth/resetPasswordSuccess');
export const resetPasswordError = createAction('auth/resetPasswordError');

export const resetPasswordCheckOtpRequest = createAction('auth/resetPasswordCheckOtpRequest');
export const resetPasswordCheckOtpSuccess = createAction('auth/resetPasswordCheckOtpSuccess');
export const resetPasswordCheckOtpError = createAction('auth/resetPasswordCheckOtpError');

export const resetPasswordConfirmRequest = createAction('auth/resetPasswordConfirmRequest');
export const resetPasswordConfirmSuccess = createAction('auth/resetPasswordConfirmSuccess');
export const resetPasswordConfirmError = createAction('auth/resetPasswordConfirmError');

export const resendConfirmationCodeRequest = createAction('auth/resendConfirmationCodeRequest');
export const resendConfirmationCodeSuccess = createAction('auth/resendConfirmationCodeSuccess');
export const resendConfirmationCodeError = createAction('auth/resendConfirmationCodeError');
