import './sort.scss';

const Filters = ({ handlerSetSort, activeSort }) => {

    const posts = [
        { id: 1, title: 'Type', content: ['FFP behavior', 'SFP behavior'] },
        { id: 2, title: 'Range', content: ['Close range', 'Mid-long range', 'Standard'] },
        { id: 3, title: 'Measurements', content: ['Ballistic', 'Non-ballistic', 'With angular measurements'] },
        { id: 4, title: 'Rangefinder', content: ['With stadiametric rangefinder', 'Without rangefinding capability'] },
        { id: 5, title: 'Sort by', content: ['Upload date', 'Download count'] }
    ];


    const content = posts.map((post) =>
        <div className="sort__content" key={post.id}>
            <h3 className="sort__title">{post.title}</h3>
            {post.content.map((item) =>
                <button key={item} className={`sort__button ${activeSort === item ? 'sort__button-active' : ''}`} onClick={(e) => { handlerSetSort(post.title, activeSort === item? '' : e.target.textContent)}}>{item}</button>)}
        </div>
    );

    return(
        <div className="sort__block">
            {content}
        </div>
    )
}

export default Filters;
