import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LayoutAuth from '../authentication/LayoutAuth';
import MyLib from './MyLib';
import PublicLib from './PublicLib';
import AdminLib from './AdminLib';

import EditorNotifications from '../canvas_section/EditorNotifications';

import { getIsAuthenticated, getUserRole, getAuthLoading } from '../../../../redux/auth/auth-selectors';
import { fetchPublicFolders } from '../../../../redux/reticles/reticles-operations';
import { closeReticle, clearPublicFolders } from '../../../../redux/reticles/reticles-actions';
import { getError, getAllPublicFolders } from '../../../../redux/reticles/reticles-selectors';
import Modal from '../../../components/Modal/Modal';


import './libraries.scss';

const Libraries = ({ 
    activeTabId, 
    focus, 
    blur, 
    onExportingTool, 
    handlerActivateButton, 
    activeLink, 
    handleToggleFilterModal, 
    showFilterModal, 
    openHeaderButton, 
    headerSearchValue,
    clearHeaderFilter,
    handleUseFilters,
    isUseFilters,
    onChangeHeaderSearchValue,
    handleReticleCardOpen,
    isReticleCardOpen,
    handlerAddTab,
    deviceWidth,
    handleOpenCard, 
    reticle,
    openCard,
    closeOpenCard,
    handleCloseCard,
    handleActiveLib,
    handleActiveLibWithChangeFolder,
    activeButton,
    activeLib,
    burgerButtonName,
    handleClearActiveLib,
    handleChangeSelectedCardStatus,
    isSelectedCard,
    checkBoxChange,
    selectedReticles,
 }) => {

    const dispatch = useDispatch();
    const loginStatus = useSelector(getIsAuthenticated);
    const userRole = useSelector(getUserRole);
    const publicFolders = useSelector(getAllPublicFolders);
    const authLoading = useSelector(getAuthLoading);

    const error = useSelector(getError);
    const [showModal, setShowModal] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [activeFolderId, setActiveFolderId] = useState('');

    useEffect(() => {
        if (isReticleCardOpen === false && firstRender) {
            closeOpenCard(false);
        }
    }, [isReticleCardOpen]);

    useEffect(() => {
        setFirstRender(true)
    }, []);

    useEffect(() => {
        if (loginStatus){
            dispatch(fetchPublicFolders());
        }
    }, [loginStatus]);


    useLayoutEffect(() => {
        return () => {
            dispatch(clearPublicFolders());
            handleClearActiveLib()
        }
    }, []);


    const handleOpenLib = (value) => {
        if(showModal){
            setShowModal(false)
        }

        handleActiveLib(value);

        setActiveFolderId('');
        closeOpenCard(false);
        handleReticleCardOpen(false);
        dispatch(closeReticle());

        if (headerSearchValue){
            clearHeaderFilter()
        }
    };

    const handleChangeFolder = (id, name) => {
        if (showModal) {
            setShowModal(false)
        }

        setActiveFolderId(id); 
        handleActiveLibWithChangeFolder(name);
        closeOpenCard(false);
        handleReticleCardOpen(false);
        dispatch(closeReticle());
    };

    const handleChangeModalFolder = (id, name) => {

        setActiveFolderId(id);
        handleActiveLibWithChangeFolder(name)
        setShowModal(false); 

    };

    const handleToggleModal = (value) => {
        setShowModal(!showModal)
    };

    const handleCloseModal = () => {
        setShowModal(false)
    };


    return (
        <>
            {error ? (
                <EditorNotifications activeLink={activeLink}/>
            ) : null}

            <section className='libraries_nav-block'>

                <div className='libraries_reticleInfo-backBlock' style={{ marginBottom: openCard ? 43 : 63, marginTop:24, marginLeft:28 }}>
                    {openCard ? (
                        <>
                            <button className='libraries_reticleInfo-backBtn' onClick={() => handleCloseCard()}></button><span className='libraries_reticleInfo-backText'> Back </span>
                        </>
                    ) : null}
                </div>

                <button key={'1'} className={`libraries_navButton libraries_navButton-primary button_myLibIcon ${activeButton === '1' ? 'libraries_navButton-active' : ''}`} onClick={() => handleOpenLib('1')}>My reticles</button>
                <button key={'2'} className={`libraries_navButton libraries_navButton-primary button_publicLibIcon ${activeButton === '2' ? 'libraries_navButton-active' : ''}`} onClick={() => handleOpenLib('2')}>Public reticles</button>
                            
                {publicFolders.length ? (
                    publicFolders.map(folder => (
                        <button key={folder.id} className={`libraries_navButton libraries_navButton-secondary secondaryButton_publicLibIcon  ${activeFolderId === folder.id ? 'libraries_navButton-active secondaryButton_openFolderIcon' : ''}`} onClick={() => handleChangeFolder(folder.id, folder.name) }>{folder.name}</button>
                    ))
                ): null}

                {(userRole === 'ADMIN' || userRole === 'MANAGER') && <button key={'3'} className={`libraries_navButton libraries_navButton-primary button_moderatorLibIcon ${activeButton === '3' ? 'libraries_navButton-active' : ''}`} onClick={() => handleOpenLib('3')}>In review</button>}
            
            </section>

            <section className={`libraries_card-section ${openCard || (openHeaderButton === 'userProfile' && deviceWidth < 1020) ? 'libraries_card-section-openCard' : 'libraries_card-section-closeCard'}`}>
                {activeLib === '1' && (
                    <MyLib 
                        activeButton={activeLib} 
                        activeTabId={activeTabId} 
                        focus={focus} blur={blur} 
                        openCard={openCard} 
                        handleOpenCard={handleOpenCard} 
                        reticle={reticle} 
                        onExportingTool={onExportingTool} 
                        handleCloseCard={handleCloseCard} 
                        handlerActivateButton={handlerActivateButton}  
                        showFilterModal={showFilterModal}
                        handleToggleFilterModal={handleToggleFilterModal}
                        openHeaderButton={openHeaderButton}
                        headerSearchValue={headerSearchValue}
                        clearHeaderFilter={clearHeaderFilter}
                        handleUseFilters={handleUseFilters}
                        isUseFilters={isUseFilters}
                        onChangeHeaderSearchValue={onChangeHeaderSearchValue}
                        handlerAddTab={handlerAddTab}
                        deviceWidth={deviceWidth}
                        handleActiveLib={handleActiveLib}
                        handleChangeSelectedCardStatus={handleChangeSelectedCardStatus}
                        isSelectedCard={isSelectedCard}
                        checkBoxChange={checkBoxChange}
                        selectedReticles={selectedReticles}
                    />
                )}
                {activeLib === '2' && (
                    <PublicLib 
                        activeButton={activeLib}
                        activeTabId={activeTabId} 
                        focus={focus} blur={blur} 
                        openCard={openCard} 
                        handleOpenCard={handleOpenCard} 
                        reticle={reticle} 
                        onExportingTool={onExportingTool} 
                        handlerActivateButton={handlerActivateButton} 
                        showFilterModal={showFilterModal}
                        handleToggleFilterModal={handleToggleFilterModal}
                        openHeaderButton={openHeaderButton}
                        headerSearchValue={headerSearchValue}
                        clearHeaderFilter={clearHeaderFilter}
                        handleUseFilters={handleUseFilters}
                        isUseFilters={isUseFilters}
                        onChangeHeaderSearchValue={onChangeHeaderSearchValue}
                        deviceWidth={deviceWidth}
                        activeFolderId={activeFolderId}
                        handleCloseCard={handleCloseCard}
                    />
                    )}
                    {activeLib === '3' && (userRole === 'ADMIN' || userRole === 'MANAGER') && (
                        <AdminLib
                            activeButton={activeButton}
                            activeTabId={activeTabId}
                            focus={focus} blur={blur}
                            openCard={openCard}
                            handleOpenCard={handleOpenCard}
                            reticle={reticle}
                            onExportingTool={onExportingTool}
                            handlerActivateButton={handlerActivateButton}
                            showFilterModal={showFilterModal}
                            handleToggleFilterModal={handleToggleFilterModal}
                            openHeaderButton={openHeaderButton}
                            headerSearchValue={headerSearchValue}
                            clearHeaderFilter={clearHeaderFilter}
                            handleUseFilters={handleUseFilters}
                            isUseFilters={isUseFilters}
                            onChangeHeaderSearchValue={onChangeHeaderSearchValue}
                            deviceWidth={deviceWidth}
                            handleCloseCard={handleCloseCard} 
                        />
                    )}

                    <button key={'1'} className={`libraries_burgerButton libraries_burgerIcon`} onClick={() => handleToggleModal()}>{burgerButtonName}</button>
            </section>

            {showModal && (
                <Modal toggleModal={handleToggleModal} width={'burger'}>
                    <div className='libraries_burger__modal-section'>
                        <button key={'1'} className={`libraries_navButton libraries_navButton-primary button_myLibIcon ${activeButton === '1' ? 'libraries_navButton-active' : ''}`} onClick={() => handleOpenLib('1')}>My reticles</button>
                        <button key={'2'} className={`libraries_navButton libraries_navButton-primary button_publicLibIcon ${activeButton === '2' ? 'libraries_navButton-active' : ''}`} onClick={() => handleOpenLib('2')}>Public reticles</button>
                            {publicFolders.length ? (
                                publicFolders.map(folder => (
                                            <button key={folder.id} className={`libraries_navButton libraries_navButton-secondary secondaryButton_publicLibIcon  ${activeFolderId === folder.id ? 'libraries_navButton-active secondaryButton_openFolderIcon' : ''}`} onClick={() => handleChangeModalFolder(folder.id, folder.name) }>{folder.name}</button>
                                ))
                            ) : null}
                        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                            <button key={'cancel'} className='libraries_modalDCancelButton' onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default Libraries;

