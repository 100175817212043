import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentShapeType: '',
    currentShapeLength: 0,
    currentShapeWidth: 0,
    currentShapeHeight: 0,
    currentShapeRadius: 0,
}

export const shapeSlice = createSlice({
    name: 'shape',
    initialState,
    reducers: {
        addShapeData: (state, shapeObj) => {
            state.currentShapeType = shapeObj.payload.type;
            state.currentShapeLength = shapeObj.payload.Length;
            state.currentShapeWidth = shapeObj.payload.width;
            state.currentShapeHeight = shapeObj.payload.height;
            state.currentShapeRadius = shapeObj.payload.radius;
        },
        editShapeData: (state, shapeObj) => {
            state.currentShapeType = shapeObj.payload.type;
            state.currentShapeLength = shapeObj.payload.Length;
            state.currentShapeWidth = shapeObj.payload.width;
            state.currentShapeHeight = shapeObj.payload.height;
            state.currentShapeRadius = shapeObj.payload.radius;
        },
        getShapeDimensions: state => state.shape,
    }
})

export const {
    addShapeData,
    editShapeData,
    getShapeDimensions,
} = shapeSlice.actions

export default shapeSlice.reducer;