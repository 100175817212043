import './sceneCard.scss';

const SceneCard = ({ previewImage, openScene }) => {

return (
    <li className='sceneCard'>
        <div className='sceneCard__body'> 
            <img src={previewImage.img} alt='reticle sceneCard preview' width='311' height='180'></img>
            <div className='sceneCard__body-actions'>                   
                <div className='sceneCard__body-actions-block'>
                    <button onClick={() => openScene(previewImage.img, previewImage.timeOfDay, previewImage.typeOfDevice)} className='sceneCard__body-btn sceneCard__body-btn-open'>Open</button>
                        {/* <button className='card__body-btn card__body-btn-open'>Import</button>
                        <button className='card__body-btn card__body-btn-delete'>Delete</button> */}
                    </div>
            </div>
            <div className='sceneCard__container'>
                <div className='sceneCard__info'>
                    <h3 className='sceneCard__title'>{previewImage.targetType}</h3>
                </div>
                <p className='sceneCard__description'>{previewImage.range} m</p>
            </div>
        </div>
    </li>
)
}



export default SceneCard;
