// import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import backBtn from '../../../img/back_btn.svg';
import { resetPassword } from '../../../../redux/auth/auth-operations';
import { useFormik } from "formik";
import * as Yup from 'yup';



import { createUseStyles } from "react-jss";

const useStales = createUseStyles({
    accountEmailForm: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    accountEmailInputs: {
        width: 240,
        textAlign: 'start',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    accountEmailInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#3F414B',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#337AA6',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',

        },
    },
    errorAccountEmailInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#B5463C',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#B5463C',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',
        },
    },
    accountEmailBtn: {
        width: 264,
        height: 40,
        backgroundColor: '#337AA6',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

        '&:hover': {
            backgroundColor: '#225A7C',
            border: '1px solid #225A7C',
        },

        '&:active': {
            backgroundColor: '#16405A',
            border: '1px solid #16405A'
        }
    },
    accountEmailText: {
        width: 273,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    backBlock: {
        marginTop: 50,
        marginLeft: 20,
    },
    backBtn: {
        backgroundImage: `url(${backBtn})`,
        backgroundSize: 'cover',
        border: 'none',
        outline: 'none',
        width: 21,
        height: 21,
        backgroundColor: 'inherit',
        backgroundPosition: 'center',
        cursor: 'pointer',
    },
    backText: {
        verticalAlign: 'top',
        fontSize: 20,
    },
    accountEmailError: {
        color: '#B5463C',
        margin: 0,
        fontSize: 13
    },

})

const AccountEmail = ({ handlePage }) => {
    const dispatch = useDispatch();
    const classes = useStales();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
        }),
        onSubmit: values => {
            const payload = {
                email: formik.values.email,
            };
            // console.log(payload)

            dispatch(resetPassword(payload));
            handlePage(4)
        },
    }); 

    // const googleLoginResponse = useGoogleLogin({
    //     onSuccess: tokenResponse => {
    //         // console.log('tokenResponse', tokenResponse)

    //         const response = tokenResponse.access_token
    //         googleLoginSubmit(response)
    //     },
    //     onError: () => console.log('Login Failed'),
    // });

    // const googleLoginSubmit = useCallback(token => {
    //     const payload = {
    //         refreshToken: token,
    //     };
    //     // console.log(payload)

    //     dispatch(googleLogin(payload));
    // }, [dispatch]
    // );

    return (
        <>
            <div>
                <div className={classes.backBlock}>
                    <button className={classes.backBtn} onClick={() => handlePage(0)}></button><span className={classes.backText}> Back </span>
                </div>

                <div className={classes.accountEmailForm}>
                    <h2>Account email</h2>
                    <p className={classes.accountEmailText}>Enter the email which was used for registering your ATN ID</p>
                    {formik.touched.email && formik.errors.email ? (<p className={classes.accountEmailError}>{formik.errors.email}</p>) : null}

                    <form onSubmit={formik.handleSubmit}>
                        <div className={classes.accountEmailInputs}>
                            <label htmlFor='Email'>Email</label>
                            <input id='Email' className={(formik.touched.email && formik.errors.email) ? classes.errorAccountEmailInput : classes.accountEmailInput}
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            />
                            <button type="submit" className={classes.accountEmailBtn}>Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}


export default AccountEmail;
