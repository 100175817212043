import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import backBtn from '../../../img/back_btn.svg';
import { getUserEmail } from '../../../../redux/auth/auth-selectors';
import { resetPasswordCheckOtp, resendConfirmationCode } from '../../../../redux/auth/auth-operations';

import { createUseStyles } from "react-jss";
import { useFormik } from "formik";
import * as Yup from 'yup';

const useStales = createUseStyles({
   codeVerificationForm: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    codeVerificationInputs: {
        width: 264,
        textAlign: 'start',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    codeVerificationInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#3F414B',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#337AA6',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',

        },
    },
    errorCodeVerificationInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#B5463C',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#B5463C',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',

        },
    },
    codeVerificationBtn: {
        width: 264,
        height: 40,
        backgroundColor: '#337AA6',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

        '&:hover': {
            backgroundColor: '#225A7C',
            border: '1px solid #225A7C',
        },

        '&:active': {
            backgroundColor: '#16405A',
            border: '1px solid #16405A'
        }
    },
    codeVerificationText: {
        width: 273,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    codeVerificationFtr: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 35,
    },
    codeVerificationSignUpBtn: {
        border: 'none',
        outline: 'none',
        backgroundColor: 'inherit',
        cursor: 'pointer',
    },
    backBlock: {
        marginTop: 50,
        marginLeft: 20,
    },
    backBtn: {
        backgroundImage: `url(${backBtn})`,
        backgroundSize: 'cover',
        border: 'none',
        outline: 'none',
        width: 21,
        height: 21,
        backgroundColor: 'inherit',
        backgroundPosition: 'center',
        cursor: 'pointer',
    },
    backText: {
        verticalAlign: 'top',
        fontSize: 20,
    },
    codeVerificationError: {
        color: '#B5463C',
        margin: 0,
        fontSize: 13
    },

})

const CodeVerificationStepOne = ({ handlePage, handleCodeVerification }) => {
    const dispatch = useDispatch();
    const authEmail = useSelector(getUserEmail);
    const [sendAnotherACode, setSendAnotherACode] = useState(false);
    const classes = useStales();

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: Yup.object({
            code: Yup.string().min(6, 'Code is too short - should be 6 symbols minimum.').max(6, 'Code is too long - should be 6 symbols maximum.').required('Code is required'),
        }),
        onSubmit: values => {
            const payload = {
                "email": authEmail,
                code: formik.values.code,
            };

            dispatch(resetPasswordCheckOtp(payload));
            handleCodeVerification(formik.values.code)

            handlePage(5)
        },
    }); 

    const handleSendAnotherCode = () => { 
        const payload = {
            "email": authEmail,
        };

        dispatch(resendConfirmationCode(payload));

        setSendAnotherACode(true);
        // console.log('button clicked');
        setTimeout(() => {
            setSendAnotherACode(false)
            // console.log('button active')
        }, 120000); 
    };

    return (
        <>
            <div>
                <div className={classes.backBlock}>
                    <button className={classes.backBtn} onClick={() => handlePage(3)}></button><span className={classes.backText}> Back </span>
                </div>

                <div className={classes.codeVerificationForm}>
                    <h2>Email verification</h2>
                    <p className={classes.codeVerificationText}>Check your inbox and enter the code we’ve sent to you</p>
                    {formik.touched.code && formik.errors.code ? (<p className={classes.codeVerificationError}>{formik.errors.code}</p>) : null}

                    <form onSubmit={formik.handleSubmit}>
                        <div className={classes.codeVerificationInputs}>
                            <label htmlFor='Code'>Code</label>
                            <input id='Code' className={(formik.touched.code && formik.errors.code) ? classes.errorCodeVerificationInput : classes.codeVerificationInput}
                                type="text"
                                name="code"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.code}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                            />
                            <button type="submit" className={classes.codeVerificationBtn}>Verify</button>
                        </div>
                    </form>

                        <div className={classes.codeVerificationFtr}>
                        {sendAnotherACode ? 
                            <p style={{ marginTop: 0 }}>New code in 2 min</p> : 
                            <>
                                <p style = {{ marginTop: 0 }}>Didn’t receive the code?</p>
                                <button type="button" className={classes.codeVerificationSignUpBtn} onClick={handleSendAnotherCode}>
                                <p style={{ color: '#337AA6', marginTop: 0 }}>Send another</p>
                                </button>
                            </>
                        }
                            
                        </div>
                </div>
            </div>
        </>
    )
}


export default CodeVerificationStepOne;
