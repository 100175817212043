import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gridData: [{ id: 'tab-0', gridType: 'moa', gridSize: 3 },],
  // currentGridType: 'moa',
  // currentGridSize: 3
}

export const gridSlice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    addGridData: (state, gridObj) => {
        state.gridData = [...state.gridData, {id: gridObj.payload.id, gridType: gridObj.payload.gridType, gridSize: gridObj.payload.gridSize}];
        // state.currentGridType = gridObj.payload.gridType;
        // state.currentGridSize = gridObj.payload.gridSize;
        sessionStorage.setItem('Grids', JSON.stringify(state.gridData));
    },
    deleteGridData: (state, id) => {
        // let newGridData = state.gridData.filter(el => el.id !== id);
        state.gridData = [...state.gridData.filter(el => el.id !== id.payload)];
        // state.currentGridType = state.newGridData[state.newGridData.length - 1].gridType;
        // state.currentGridSize = state.newGridData[state.newGridData.length - 1].gridSize;
        sessionStorage.setItem('Grids', JSON.stringify(state.gridData));
    },
    updateGridData: (state, gridObj) => {
        let updatedArr = state.gridData.map(gridEl => {
            if (gridEl.id === gridObj.payload.id) {
                gridEl.gridType = gridObj.payload.gridType
                gridEl.gridSize = gridObj.payload.gridSize
                return gridEl
            } else {
                return gridEl
            }
        })

        state.gridData = updatedArr;
        // state.currentGridType = gridObj.payload.gridType;
        // state.currentGridSize = gridObj.payload.gridSize;
        sessionStorage.setItem('Grids', JSON.stringify(updatedArr));
    },
    getGridData: state => state.grid,
    refreshGridData: (state, refreshObj) => {
      // const refreshData = JSON.parse(sessionStorage.getItem('Grids'));

      // state.gridData = refreshData;
      state.gridData = refreshObj.payload
    },
  },
});

export const {
  addGridData,
  deleteGridData,
  updateGridData,
  getGridData,
  refreshGridData,
} = gridSlice.actions

export default gridSlice.reducer;
