import React from 'react';
import './version.scss';

export default function Version({appVersion}) {
  return (
    <section className='version-section'>
        <p className='version_text'>{appVersion}</p>
    </section>
  );
}
