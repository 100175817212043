import React from 'react';
import Shapes from './create_section/create_tools';
import Measure from './create_section/Measure';
import Reticles from './create_section/Reticles';
import Duplicate from './create_section/Duplicate';
import Undo from './create_section/Undo';
import Clear from './create_section/Clear';
import Importing from './create_section/Importing';

import './Tools.scss'

class Tools extends React.Component {

    render() {
        return (
            <nav className='tools_section' style={{ justifyContent: this.props.isSelectedCard ? 'center' : ''}}>
                {this.props.isSelectedCard && this.props.activeLink === '2' && this.props.activeLib === '1' ? (
                    <section className='clear-section'>
                        <button id='clearBtn' className='selectedBackBtn' onClick={() => this.props.handleChangeSelectedCardStatus('back')}>
                            <span className='clear_text'>Back</span>
                        </button>

                        <button id='clearBtn' className='delete-btn' onClick={() => this.props.handleDeleteSelectedReticles()}>
                            <span className='clear_text'>Delete</span>
                        </button>
                    </section>
                ) : null}

                {this.props.activeLink === '2'? null : (
                <>
                    <Shapes
                        instruments={this.props.instruments}
                        onSelectMove={this.props.onSelectChangeMove}
                        onSelectCircle={this.props.onSelectChangeCircle}
                        onSelectLine={this.props.onSelectChangeLine}
                        onSelectSimpleLine={this.props.onSelectChangeSimpleLine}
                    />
                    <Reticles
                        handlerReticleRename={this.props.handlerReticleRename}
                        focus={this.props.focus}
                        blur={this.props.blur}
                        activeTab={this.props.activeTab}
                    />
                <section className='mesure_edit-section'>
                    <Measure
                        focusedInput={this.props.focusedInput}
                        onSelectRuler={this.props.onSelectRuler}
                        onSelectGrid={this.props.onSelectGrid}
                        onGetGridSize={this.props.onGetGridSize}
                        onExportPreviewData={this.props.onExportPreviewData}
                        gridSize={this.props.gridSize}
                        activeTab={this.props.activeTab}
                        focus={this.props.focus}
                        blur={this.props.blur}
                    />
                    <section className='command-btns_section'>
                        <Duplicate duplicate={this.props.duplicate} />
                        <Undo
                            undo={this.props.undo}
                            redo={this.props.redo}
                        />
                        <Clear clear={this.props.clear} toggleDeleteShape={this.props.toggleDeleteShape}/>
                    </section>
                    <Importing
                        onExportingTool={this.props.onExportingTool}
                        toggleModal={this.props.toggleModal}
                        getIsSavingId={this.props.getIsSavingId}
                        activeTab={this.props.activeTab}
                        handleImportReticleInfo={this.props.handleImportReticleInfo}
                        handlerAddTab={this.props.handlerAddTab}
                    />
                </section>
                </>
                )}
            </nav>
        )
    }
}

export default Tools;
