import React from 'react';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import './importing.scss'
import Tooltip from "../../tooltip/Tooltip";
import { createUseStyles } from "react-jss";
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogin } from '../../../../redux/auth/auth-operations';
import { getIsAuthenticated } from '../../../../redux/auth/auth-selectors';
import { addTab, changeReticlesID } from '../../../../redux/reticles/tabs-reducer';
import { addZoomData } from '../../../../redux/zoom/zoom-reducer';
import { changeCurrentReticle } from '../../../../redux/reticles/reticles-actions';
import { addGridData } from '../../../../redux/grid/grid-reducer';
import { addDeviceData } from '../../../../redux/devices/device-reducer';
import { addColorThemeData } from '../../../../redux/colorTheme/colorTheme-reducer';

import Share from './Share';

const useStales = createUseStyles({
    tooltipBtn: {
        width: 150,
        height: 40,
        backgroundColor: '#337AA6',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',

        '&:active': {
            backgroundColor: '#337AA6',
        }
    },
})


const Importing = ({ onExportingTool, getIsSavingId, activeTab, handleImportReticleInfo, handlerAddTab }) => {
    const dispatch = useDispatch();
    const classes = useStales('');
    const loginStatus = useSelector(getIsAuthenticated);
    const [importReticle, setImportReticle] = useState([]);
    const [importReticleName, setImportReticleName] = useState('');
    const [firstRender, setFirstRender] = useState(false);
    const tabs = useSelector((state) => state.tabs.tabs);

    const inputFile = useRef(null) 

    useEffect(() => {
        setFirstRender(true);
    }, []);

    const handleFileUpload = async(e) => {
        e.preventDefault();
        const reader = new FileReader();

        reader.onload = async (e) => {
            const text = e.target.result;
            const lines = String(text);

            setImportReticle(lines.replace(/data-reticleId="([^"]*)"/, '').replace(/data-reticleid="([^"]*)"/, ''));
        };
        reader.readAsText(e.target.files[0]);
        // console.log('name', e.target.files[0].name);
        setImportReticleName(e.target.files[0].name.slice(0, -4))
    };

    useEffect(() => {
        if (firstRender){
            handleImportReticleInfo(importReticle, importReticleName);
            handlerAddTab('event', importReticle, importReticleName)
        }
        
    }, [importReticle]);


    const onButtonClick = () => {
        inputFile.current.click();
    };

    const googleLoginResponse = useGoogleLogin({
        onSuccess: tokenResponse => {
            const response = tokenResponse.access_token
            googleLoginSubmit(response)
        },
        onError: () => console.log('Login Failed'),
    });

    const googleLoginSubmit = useCallback(token => {
        const payload = {
            refreshToken: token,
        };

        dispatch(googleLogin(payload));
    }, [dispatch]
    );

    const tooltipContent = () => {
        return (
            <div>
                <p>Sign in with your ATN ID</p>
                <p>or your Google account</p>
                <p>to access this feature</p>
                <button type="button" onClick={() => googleLoginResponse()} className={classes.tooltipBtn}>Sign in with Google</button>
            </div>
        )
    }

    const handleClick = () => {
        // const svg = "<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xmlns:svgjs=\"http://svgjs.dev/svgjs\" width=\"850\" height=\"600\" id=\"svgCanvas-tab-94723\" class=\"canvas-block\" viewBox=\"0 0 850 600\"><ellipse rx=\"0\" ry=\"0\" cx=\"500\" cy=\"375\" id=\"aimpoint\" class=\"aimPoint\"></ellipse></svg>";

        // tabs.forEach(tab => {
        //     if (tab.svg !== '' && tab.id !== atcTab) {
        //         let json = JSON.stringify({ id: tab.id, savingId: tab.savingId, svg: tab.svg, name: tab.name, isActiveTab: false, status: tab.status, publicId: tab.publicId });
        //         console.log('TAB ID !== ACTIVE ', json)
        //         sessionStorage.setItem(tab.id, json);
        //     } else if (tab.svg !== '' && tab.id === atcTab) {
        //         let json = JSON.stringify({ id: tab.id, savingId: tab.savingId, svg: tab.svg, name: tab.name, isActiveTab: true, status: tab.status, publicId: tab.publicId });
        //         sessionStorage.setItem(tab.id, json);
        //     } else if (tab.svg === '' && tab.id === atcTab){
        //         let json = JSON.stringify({ id: tab.id, savingId: tab.savingId, svg: svg, name: tab.name, isActiveTab: true, status: tab.status, publicId: tab.publicId });
        //         sessionStorage.setItem(tab.id, json);
        //     } else if (tab.svg === '' && tab.id !== atcTab) {
        //         let json = JSON.stringify({ id: tab.id, savingId: tab.savingId, svg: svg, name: tab.name, isActiveTab: false, status: tab.status, publicId: tab.publicId });
        //         sessionStorage.setItem(tab.id, json);
        //     }
        // });
    }

    return (
        <section className='importing_section'>
            {/* <Link to="/" target="_blank">
                    <button id='reticleBtn' className='reticle_button'>New reticle</button>
                </Link> */}
                {/* <button type='button' onClick={this.props.toggleModal} className='publish_btn'>Device</button> */}
                {loginStatus ? (
                    <Link to="/preview">
                        <button className='preview-btn' onClick={handleClick}>Preview</button>
                    </Link>
                ) : (<Tooltip
                        content={tooltipContent()}
                        direction="bottom"
                        dirStyle="authBottom"
                    >
                        <button disabled className='preview-btn'>Preview</button>
                    </Tooltip>)
                }

                {loginStatus ? (
                    // <button onClick={onSubmit} className='publish_btn'>Share</button>
                    <Share getIsSavingId={getIsSavingId} activeTab={activeTab}/>
                ) : (<Tooltip
                        content={tooltipContent()}
                        direction="bottom"
                        dirStyle="authBottom"
                    >
                        <button disabled className='publish_btn'>Share</button>
                    </Tooltip>)
                }

                {loginStatus ? (
                    <button className='export_btn' onClick={() => { onExportingTool() }}>Download</button> 
                ) : (<Tooltip
                    content={tooltipContent()}
                    direction="bottom"
                    dirStyle="authBottom"
                >
                    <button disabled className='export_btn' onClick={() => { onExportingTool() }}>Download</button> 
                    </Tooltip>)
                }

                {loginStatus ? (
                    <>
                    <input type='file' id='file' style={{ display: "none" }} accept=".svg, .atn" ref={inputFile} onChange={handleFileUpload} />
                    <button className='publish_btn' onClick={onButtonClick}>Import</button>
                    </>
                ) : (<Tooltip
                        content={tooltipContent()}
                        direction="bottom"
                        dirStyle="authBottom"
                    >
                    <button className='publish_btn'>Import</button>
                    </Tooltip>)
                }
            </section>
        )
}

export default Importing;
