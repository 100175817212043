import { useCallback, useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'
import ReticleInfo from './ReticleInfo';
import Search from './Search';
import LayoutAuth from '../authentication/LayoutAuth';
import ReticleCardNew from './ReticleCardNew';
import CardSkeleton from './CardSkeleton';

import { ReactComponent as EmptyCollectionIcon } from '../../../img/emptyCollectionIcon.svg';

import { getIsAuthenticated } from '../../../../redux/auth/auth-selectors';
import { fetchAdminReticles } from '../../../../redux/reticles/reticles-operations';
import { getAllAdminReticles, getSkipAdminReticles, getFetchingAdminReticles, getFetchError, getFetchReticlesLoading } from '../../../../redux/reticles/reticles-selectors';
import { setSkipAddAdmin, setSkipRemoveAdmin, setIsFetchingAdmin } from '../../../../redux/reticles/reticles-actions';


import './library.scss';

const AdminLib = ({ activeButton,
    activeTabId,
    focus,
    blur,
    handleOpenCard,
    openCard,
    reticle,
    onExportingTool,
    handlerActivateButton,
    openHeaderButton,
    headerSearchValue,
    isUseFilters,
    onChangeHeaderSearchValue,
    deviceWidth,
    handleCloseCard
}) => {
    const dispatch = useDispatch();
    const reticles = useSelector(getAllAdminReticles);
    const loginStatus = useSelector(getIsAuthenticated);
    const skip = useSelector(getSkipAdminReticles);
    const fetching = useSelector(getFetchingAdminReticles);
    const fetchingError = useSelector(getFetchError);
    const fetchReticleLoading = useSelector(getFetchReticlesLoading);

    const [value, setValue] = useState('');
    const [sortValue, setSortValue] = useState('');
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [activeSort, setActiveSort] = useState('');
    const [firstRender, setFirstRender] = useState(false);


    const handleClearFilter = () => {
        setValue('');
        setActiveSort('')
        setSortValue('')
        dispatch(setSkipRemoveAdmin(0));
        fetchReticles(0, `searchQuery=`);
    };

    useEffect(() => {
        if (headerSearchValue !== '' && !isUseFilters && firstRender) {
            setValue(headerSearchValue);
            setActiveSort('');
            setSortValue('');
            dispatch(setSkipRemoveAdmin(0));
            fetchReticles(0, `searchQuery=${headerSearchValue}`);
        } else if (headerSearchValue === '' && !isUseFilters && firstRender) {
            handleClearFilter()
        }
    }, [headerSearchValue]);

    useEffect(() => {
        setFirstRender(true)
    }, []);


    const fetchReticles = useCallback(
        _.debounce((skip, value) => dispatch(fetchAdminReticles(skip, value)), 600
        ), []);

    useEffect(() => {
        if (loginStatus && fetching) {
            if (value !== '') {
                fetchReticles(skip, `searchQuery=${value}`);

            } else if (value === '') {
                dispatch(fetchAdminReticles(skip, sortValue));
            }
        } else return
    }, [loginStatus, fetching]);

    useLayoutEffect(() => {
        return () => {
            dispatch(setSkipRemoveAdmin(0));
            setValue('')
            setSortValue('')
            dispatch(setIsFetchingAdmin(true));
        }
    }, []);

    useEffect(() => {
        if (loginStatus && !fetching) {
            let scrollBlock = document.querySelector('.libraries_card-block');
            scrollBlock.addEventListener('scroll', scrollHendler);

            return function () {
                scrollBlock.removeEventListener('scroll', scrollHendler);
            }
        }
    }, [loginStatus, fetching]);

    const scrollHendler = (e) => {
        if (e.target.scrollTop === 0) {
            return
        }

        if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
            dispatch(setSkipAddAdmin(16));
            dispatch(setIsFetchingAdmin(true));
        }
    };

    const onChange = useCallback(e => {
        setValue(e.target.value);
        onChangeHeaderSearchValue(e)
        setActiveSort('');
        setSortValue('');
        dispatch(setSkipRemoveAdmin(0));
        fetchReticles(skip, `searchQuery=${e.target.value}`);
    }, [dispatch]);



    return (
        <>
            {!loginStatus ?
                (
                    <div className='library'>
                        <LayoutAuth />
                    </div>
                )
                : (
                    <>
                        {openCard ? (
                            <div className='card_info-block'>
                                <ReticleInfo reticle={reticle} activeButton={activeButton} onExportingTool={onExportingTool} handlerActivateButton={handlerActivateButton} deviceWidth={deviceWidth} handleCloseCard={handleCloseCard} />
                            </div>
                        ) : null}



                        <div className='libraries__filters-block'>
                            <Search value={value} onChange={onChange} focus={focus} blur={blur} handleClearFilter={handleClearFilter} />
                        </div>


                        {fetchReticleLoading &&
                            <ul className='libraries_card-list'>
                                <CardSkeleton cards={16}
                                />
                            </ul>
                        }

                        <div className={`libraries_card-block ${isFiltersOpen ? 'libraries_card-block-openFilter' : 'libraries_card-block-closeFilter'}`}>
                            {(!reticles.length && fetchingError === 'RETICLE_NOT_FOUND' && value) ? (
                                <div className='libraries_collection-empty'>
                                    <EmptyCollectionIcon />
                                    <h2 className='libraries_collection-empty-title'>Reticle Not Found</h2>
                                    <p className='libraries_collection-empty-text'>Sorry, we couldn't find a matching reticle for your search.
                                        Please try a different term.</p>
                                </div>
                            ) : (
                                <ul className='libraries_card-list'>
                                    {reticles.map(reticle => (
                                        <ReticleCardNew key={reticle.id} reticle={reticle} activeButton={activeButton} activeTabId={activeTabId} handleOpenCard={handleOpenCard} />
                                    ))}
                                </ul>

                            )}
                        </div>
                    </>
                )}
        </>
    )
}

export default AdminLib;
