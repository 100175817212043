import React from 'react';
import { useState } from "react";
import Targets_section from './targets_section/targets_section.jsx';
import Preview_section from './preview_section/preview_section.jsx';
// import Icons_section from './icons_section/icons_section.jsx';
import './preview_main-section.scss';

export default function Main({ activeTabId }) {
  const [sceneUrl, setSceneUrl] = useState('');
  const [timeOfDay, setTimeOfDay] = useState('');
  const [activeLink, setActiveLink] = useState('1')

  // const [previewImages, setPreviewImages] = useState([
  //   {
  //     id: 1,
  //     name: 'img1',
  //     date: 'Last week',
  //     img: 'preview_bg_default.png',
  //   },
  //   {
  //     id: 2,
  //     name: 'img2',
  //     date: '2 month ago',
  //     img: 'https://www.pngall.com/wp-content/uploads/1/Forest-PNG-Image.png',
  //   },
  //   {
  //     id: 3,
  //     name: 'img3',
  //     date: '3 month ago',
  //     img: 'https://abrakadabra.fun/uploads/posts/2022-01/thumbs/1641924603_39-abrakadabra-fun-p-fon-priroda-pole-46.jpg',
  //   },
  // ])

  const openScene = (url, timeOfDay, typeOfDevice) => {
    setSceneUrl(url);
    if (typeOfDevice === "THERMAL" && timeOfDay === "NIGHT") {
      setTimeOfDay(typeOfDevice)
    } else setTimeOfDay(timeOfDay)
  };


  const handlerActivateButton = (num) => setActiveLink(() => num)

  return (
    <section className='preview_main-section'>
      <Targets_section openScene={openScene} handlerActivateButton={handlerActivateButton} activeLink={activeLink}/>
      <Preview_section sceneUrl={sceneUrl} activeTabId={activeTabId} timeOfDay={timeOfDay} activeLink={activeLink}/>
      {/* <Icons_section /> */}
    </section>
  );
}
