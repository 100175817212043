import React from 'react';

import './focal.scss';

class Focal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            focalPlane: 'off',
            checked: 'off',
            isSelectedShapeScaleble: false
        }
    }

    handlerFocalPlaneType = (e) => {
        if (e.target.checked) {
            this.setState({
                focalPlane: this.state.focalPlane = 'on',
                checked: this.state.checked = 'on'
            })
        } else {
            this.setState({
                focalPlane: this.state.focalPlane = 'off',
                checked: this.state.checked = 'off'
            })
        }

        this.props.getFocalPlanesData(this.state.focalPlane)

        if (this.state.focalPlane === 'on') {
            if (this.props.shapeData.selectedShapes.length) {
                this.props.shapeData.selectedShapes.forEach(element => {
                    if (element.type === 'g') {
                        element.each(function(i, children) {
                            children[i].each(function(j, gChildren) {
                                gChildren[j].remove();
                                children[i].add('<title>31</title>')
                            })
                        })
                    } else {
                        element.node.children[0].innerHTML = '31'
                    }
                });
            }
        } else {
            if (this.props.shapeData.selectedShapes.length) {
                this.props.shapeData.selectedShapes.forEach(element => {
                    if (element.type === 'g') {
                        element.each(function(i, children) {
                            children[i].each(function(j, gChildren) {
                                gChildren[j].remove();
                                children[i].add('<title>42</title>')
                            })
                        })
                    } else {
                        element.node.children[0].innerHTML = '42'
                    }
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.shapeData.isSelectedShapeScaleble !== this.state.isSelectedShapeScaleble) {
            this.setState({
                isSelectedShapeScaleble: this.state.isSelectedShapeScaleble = prevProps.shapeData.isSelectedShapeScaleble
            })

            if (this.state.isSelectedShapeScaleble) {
                this.setState({
                    focalPlane: this.state.focalPlane = 'on',
                    checked: this.state.checked = 'on'
                })
            } else {
                this.setState({
                    focalPlane: this.state.focalPlane = 'off',
                    checked: this.state.checked = 'off'
                })
            }
        } //else {
        //     if (!this.props.shapeData.isActiveFP && this.state.focalPlane === 'on') {
        //         this.setState({
        //             focalPlane: this.state.focalPlane = 'off',
        //             checked: this.state.checked = 'off'
        //         })
        //     }
        // }
    }

    render () {
        // const selectEl = this.props.shapeData.selectedShape && !this.props.shapeData.selectedShape.hasClass('mouseSelect') ? this.props.shapeData.selectedShape : '';
        const selectEl = (this.props.shapeData.selectedShape || this.props.shapeData.selectedShapes.length >= 1) ? this.props.shapeData.selectedShapes[0] : '';
        const currentTool = this.props.shapeData.activeShapeButton.name !== 'move' &&
            this.props.shapeData.activeShapeButton.name !== 'hand' &&
            this.props.shapeData.activeShapeButton.name !== 'number' &&
            this.props.shapeData.activeShapeButton.name !== 'arabic-number' &&
            this.props.shapeData.activeShapeButton.name !== '';

        return (
            <>
                {(selectEl || currentTool) &&
                    <>
                        <section className='expandable-block'>
                            <h2 className='expandable-title'>Expandable</h2>
                            <div className='focal-block status-block'>
                                <form className='focal-form'>
                                    <div className='focal-radio-wrapper'>
                                        <label htmlFor='scaleInput' className='checked_label'>{this.state.checked}</label>
                                        <label className="switch">
                                            <input id='scaleInput' type="checkbox"
                                                checked={this.state.checked === 'on'}
                                                // checked={this.state.isSelectedShapeScaleble ? true : false}
                                                onChange={this.handlerFocalPlaneType}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </section>
                        <p className='expandable-txt'>
                            When enabled this shape will move relative to center and scale while zooming
                        </p>
                    </>
                }
            </>
        )
    }
}

export default Focal;
