import { useState } from 'react';
import { createUseStyles } from "react-jss";
import imgVisible from '../../../img/input_btn-visibility.svg';
import imgNotVisible from '../../../img/input_btn-notVisible.svg';
import backBtn from '../../../img/back_btn.svg';
import { useDispatch } from 'react-redux';
import { register } from '../../../../redux/auth/auth-operations';
import { useFormik } from "formik";
import * as Yup from 'yup';


const useStales = createUseStyles({
    registerForm: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    registerInputs: {
        width: 264,
        textAlign: 'start',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    registerInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#AAAAAA',
            width: 1,
            style: 'solid'
        },

        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#337AA6',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',
        },
    },
    errorRegisterInput: {
        boxSizing: 'border-box',
        width: 264,
        marginBottom: 20,
        marginTop: 8,
        padding: '5px 16px',
        backgroundColor: 'transparent',
        border: {
            color: '#B5463C',
            width: 1,
            style: 'solid'
        },
        borderRadius: 8,
        color: '#fff',
        height: 40,
        '&:focus': {
            outline: 0,
            outlineOffset: 0,
            border: {
                color: '#B5463C',
                width: 1,
                style: 'solid'
            },
            backgroundColor: '#383844',
        },
    }, 
    registerBtn: {
        width: 264,
        height: 40,
        backgroundColor: '#337AA6',
        border: {
            color: '#337AA6',
            width: 1,
            style: 'solid'
        },
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#fff',
        cursor: 'pointer',
        marginTop: 20,

        '&:hover': {
            backgroundColor: '#225A7C',
            border: '1px solid #225A7C',
        },

        '&:active': {
            backgroundColor: '#16405A',
            border: '1px solid #16405A'
        }
    },
    showBtn: {
        right: 15,
        top: 25,
        position: 'absolute',
        backgroundSize: 'cover',
        border: 'none',
        outline: 'none',
        width: 15,
        height: 10,
        backgroundColor: 'inherit',
        backgroundPosition: 'center',
        cursor: 'pointer',
    },
    backBlock: {
        marginTop: 50,
        marginLeft: 20,
    },
    backBtn: {
        backgroundImage: `url(${backBtn})`,
        backgroundSize: 'cover',
        border: 'none',
        outline: 'none',
        width: 21,
        height: 21,
        backgroundColor: 'inherit',
        backgroundPosition: 'center',
        cursor: 'pointer',
    },
    backText: {
        verticalAlign: 'top',
        fontSize: 20,
    },
    registerError: {
        color: '#B5463C',
        margin: 0,
        fontSize: 13
    },
})

const RegisterForm = ({ handlePage }) => {
    const dispatch = useDispatch();
    const [showPassword, setShownPassword] = useState(false);
    const [showReEnterPassword, setShownReEnterPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            email: '',
            password: '',
            reEnterPassword:'',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().max(20, 'Must be 20 characters or less'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().min(4, 'Password is too short - should be 4 symbols minimum.').max(20, 'Password is too long - should be 20 symbols maximum.').required('Password is required'),
            reEnterPassword: Yup.string().min(4, 'Re-enter password is too short - should be 4 symbols minimum.').max(20, 'Re-enter password is too long - should be 20 symbols maximum.').required('Re-enter password is required'),
        }),
        onSubmit: values => {
            if (formik.values.password === formik.values.reEnterPassword) {
                const payload = {
                    firstName: formik.values.firstName,
                    email: formik.values.email,
                    password: formik.values.password,
                };
                // console.log(payload)

                handlePage(2)

                dispatch(register(payload));
            } else alert('Your passwords do not match')
        },
    }); 

    const classes = useStales()

    return (
        <>
            <div className={classes.backBlock}>
                <button className={classes.backBtn} onClick={() => handlePage(0)}></button><span className={classes.backText}> Back </span>
            </div>

            <div className={classes.registerForm}>
                <h2>Step 1: </h2>
                <h2>Choose email and password</h2>
                {formik.touched.firstName && formik.errors.firstName ? (<p className={classes.registerError}>{formik.errors.firstName}</p>) : null}
                {formik.touched.email && formik.errors.email ? (<p className={classes.registerError}>{formik.errors.email}</p>) : null}
                {formik.touched.password && formik.errors.password ? (<p className={classes.registerError}>{formik.errors.password}</p>) : null}
                {formik.touched.reEnterPassword && formik.errors.reEnterPassword ? (<p className={classes.registerError}>{formik.errors.reEnterPassword}</p>) : null}


                <form onSubmit={formik.handleSubmit}>
                    <div className={classes.registerInputs}>
                        <label htmlFor='RegisterName'>Name</label>
                        <input id='RegisterName' className={(formik.touched.firstName && formik.errors.firstName) ? classes.errorRegisterInput : classes.registerInput}
                            type="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                        // onFocus={this.props.focus}
                        // onBlur={this.props.blur}
                        />

                        <label htmlFor='RegisterEmail'>Email</label>
                        <input id='RegisterEmail' className={(formik.touched.email && formik.errors.email) ? classes.errorRegisterInput : classes.registerInput}
                            type="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            // onFocus={this.props.focus}
                            // onBlur={this.props.blur}
                        />

                        <label htmlFor='RegisterPassword'>Password</label>
                        <div style={{ position: 'relative' }}>
                            <input id='RegisterPassword' className={(formik.touched.password && formik.errors.password) ? classes.errorRegisterInput : classes.registerInput}
                                type={showPassword ? "text" : "password"}
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                // onFocus={this.props.focus}
                                // onBlur={this.props.blur}
                            />
                            <button type="button" className={classes.showBtn} onClick={() => setShownPassword(!showPassword)} style={{ backgroundImage: showPassword ? `url(${imgVisible})` : `url(${imgNotVisible})`}}></button>
                        </div>

                        <label htmlFor='ReEnterPassword'>Re-enter password</label>
                        <div style={{ position: 'relative' }}>
                            <input id='ReEnterPassword' className={(formik.touched.reEnterPassword && formik.errors.reEnterPassword) ? classes.errorRegisterInput : classes.registerInput} style={{ marginBottom: 0 }}
                                type={showReEnterPassword ? "text" : "password"}
                                name="reEnterPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.reEnterPassword}
                                // onFocus={this.props.focus}
                                // onBlur={this.props.blur}
                            />
                            <button type="button" className={classes.showBtn} onClick={() => setShownReEnterPassword(!showReEnterPassword)} style={{backgroundImage: showReEnterPassword ? `url(${imgVisible})` : `url(${imgNotVisible})`}}></button>
                        </div>
                    </div>

                    <button type="submit" className={classes.registerBtn}>Sign up</button>
                </form>
            </div>
        </>
    )
}


export default RegisterForm;
