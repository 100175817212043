import React from 'react';

export default function PreviewSensorList({deviceSensors, currentDeviceSensor, handlerSelectSensor}) {
    return (
        <form>
            {deviceSensors.map((sensor, i) => (
                <div className='devices_menu-block-item' key={i}>
                    <input type='radio' id={sensor} name={sensor} value={sensor} className='devices_menu-block-input'
                        checked={sensor === currentDeviceSensor ? true : false}
                        onChange={handlerSelectSensor}
                    />
                    <label htmlFor={sensor} className='devices_menu-block-label'>{sensor}</label>
                </div>
            ))}
        </form>
    )
}
