import React from 'react';
import ColorPicker from "../edit_section/ColorPicker";

import './fill.scss';

class Fill extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            color: '#ffffff',
            opacity: 0,
            selectedShape: '',
            shapeClassNumGroup: false,
            isAllShapesFill: false
        }

        this.handlerColorButton = (col) => {
            this.setState({
                color: this.state.color = col
            })
            this.props.fillShapeColor(this.state.color)
        }

        this.handlerEnterOpacity = (e) => {
            let opacityValue = e.target.value.replace(/[^\d]/g, '');
            // let opacityValue = e.target.value.replace(/[A-Za-zА-Яа-яЁё]/, '');
            // let opacityValue = !isNaN(e.target.value) ? e.target.value : ''
            if (opacityValue > 100) {
                opacityValue = 100
            }

            this.setState({
                opacity: this.state.opacity = opacityValue
            })

            this.props.fillShapeOpacity(this.state.opacity)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.shapeData !== this.state.selectedShape) {
            if (prevProps.shapeData.selectedShape !== prevState.selectedShape) {
                this.setState({
                    selectedShape: this.state.selectedShape = prevProps.shapeData,
                })
            }

            if (this.state.selectedShape && (this.state.selectedShape.hasClass('number-group') ||
                this.state.selectedShape.hasClass('num-line'))) {
                    this.setState({
                        shapeClassNumGroup: this.state.shapeClassNumGroup = true,
                    })
            } else {
                this.setState({
                    shapeClassNumGroup: this.state.shapeClassNumGroup = false,
                })
            }

            this.setState({
                // selectedShape: this.state.selectedShape = prevProps.shapeData,
                opacity: this.state.opacity = prevProps.opacity,
                color: this.state.color = prevProps.fillColor,
            })
        }

        if (this.state.isAllShapesFill !== this.props.isAllShapesFill) {
            this.setState({
                isAllShapesFill: this.props.isAllShapesFill
            })
        }

        if (this.props.selectedShapes && this.props.selectedShapes.length) {
            if (this.state.color !== this.props.selectedShapes[0].fill()) {
                this.setState({
                    color: this.state.color = this.props.selectedShapes[0].fill()
                })
            }

            if (this.state.opacity !== this.props.selectedShapes[0].attr('fill-opacity') * 100) {
                this.setState({
                    opacity: this.state.opacity = this.props.selectedShapes[0].attr('fill-opacity') * 100,
                })
            }
        }
    }

    render() {
        const buttonLine = this.props.activeShapeButton.name !== 'line';
        const buttonNumber = this.props.activeShapeButton.name !== 'number' && this.props.activeShapeButton.name !== 'arabic-number';
        const buttonSelect = this.props.activeShapeButton.name !== 'move';
        const buttonHand = this.props.activeShapeButton.name !== 'hand';
        const notSelectedTool = this.props.activeShapeButton.name !== '';
        // const selectShape = this.state.selectedShape.type === 'circle' || (this.state.selectedShape.type === 'rect' && !this.state.selectedShape.hasClass('mouseSelect')) || this.state.selectedShape.type === 'polygon';
        const selectShape = (this.props.selectedShapes.length >= 1 && this.props.selectedShapes[0].type !== 'line' && this.props.selectedShapes[0].type !== 'g');
        const isAllShapesFill = this.state.isAllShapesFill;

        return (
            <>
                {((buttonLine && buttonNumber && buttonHand && buttonSelect && notSelectedTool) ||
                    (buttonLine && buttonNumber && buttonHand && selectShape) || isAllShapesFill) &&
                    <section className='edit-section-block'>
                        <div className='edit-section_title-block'>
                            <h4 className='edit-section-title'>FILL</h4>
                        </div>
                        <div className='fill-block_wrapper '>
                            <div className='fill-block status-block'>
                                <ColorPicker handlerColorButton={this.handlerColorButton} color={this.state.color} selectedShapeType={this.state.selectedShape?.node?.classList[0]} />
                                    <div>
                                        <label>
                                            <span style={{ marginRight: 15 }}>Opacity</span>
                                            <input type='text' id='opacity' className='opacity-input fill-input'
                                                value={this.state.opacity}
                                                onChange={this.handlerEnterOpacity}
                                                onFocus={this.props.focus}
                                                onBlur={this.props.blur}
                                            />
                                            <span>%</span>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        )
    }
}

export default Fill;
