import React from 'react';
import Header from '../components/header/Header';
import Menu from '../components/preview/preview-menu';
import Main from '../components/preview/main/preview_main';
// import './preview.scss';

export default function Publish({ handlerReticleRename, handlerAddTab, handlerCloseTab, activeTab, reticles, activeTabId, handlerActiveTab, onExportingTool, focus, blur, isFocused }) {
  const handlerClick = (e) => {
    try {
      if (!e.target.className.includes('devices_menu-block')) {
        blur();
      }
    } catch {
      blur();
    }
  }

  return (
    <main className='preview_main' onClick={handlerClick}>
      <Header 
        handlerReticleRename={handlerReticleRename}
        handlerAddTab={handlerAddTab}
        handlerCloseTab={handlerCloseTab}
        activeTab={activeTab}
        reticles={reticles}
        activeTabId={activeTabId}
        handlerActiveTab={handlerActiveTab}
      />
      <Menu
        handlerReticleRename={handlerReticleRename}
        activeTab={activeTab}
        onExportingTool={onExportingTool}
        focus={focus}
        blur={blur}
        isFocused={isFocused}
      />
      <Main activeTabId={activeTabId}/>
    </main>
  );
}
