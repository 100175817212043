import React from 'react';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { updateZoomData } from '../../../../redux/zoom/zoom-reducer';
import './zoom.scss';

export default function Zoom(props) {
  const [zoomIndex, setZoomIndex] = useState(100);
  const zoomInput = document.querySelector('#zoomRange');
  const dispatch = useDispatch();
  const zoom = useSelector((state) => state.zoom.currentZoomIndex);

  let handlerZoomRangeChange = () => {
    dispatch(updateZoomData({id: props.activeTabId, index: +zoomInput.value}));
  }

  let handlerZoomIn = () => {
    if (zoom < 190) {
      dispatch(updateZoomData({id: props.activeTabId, index: zoomIndex + 5}));
    }
  }

  let handlerZoomOut = () => {
    if (zoom > 100) {
      dispatch(updateZoomData({id: props.activeTabId, index: zoomIndex - 5}));
    }
  }

  useEffect(() => {
    setZoomIndex(zoom)
  }, [zoom]);

  return (
    <section className='zoom-section' style={{ left: props.activeLink === '' ? '40%' : '50%' }}>
        <button id='zoomOutBtn' className='zoom-out_btn zoom-btn' onClick={handlerZoomOut}></button>
        <input type="range" id='zoomRange' className='zoom-range' value={zoomIndex} min={100} max={190} step={1}
          onChange={handlerZoomRangeChange}
        />
        <button id='zoomInBtn' className='zoom-in_btn zoom-btn' onClick={handlerZoomIn}></button>
    </section>
  );
}
