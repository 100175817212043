import { useState } from 'react';
import TargetsMenu from './targets_menu.jsx';
import TargetsList from './targets_list.jsx';
import Scene from './scene.jsx';
import UserAccount from '../../../main/authentication/UserAccount';


import './targets_section.scss';

export default function Target_section({ previewImages, openScene, handlerActivateButton, activeLink }) {


  return (
    <section className='targets_section'>

      <section className='targets_menu-section'>
        <TargetsMenu handlerActivateButton={handlerActivateButton} activeLink={activeLink}/>
      </section>

      {activeLink === '1' && (<Scene openScene={openScene}/>)}
      {/* {activeLink === '2' && (<TargetsList />)} */}
      {activeLink === '3' && (<section className='targets_user-section' style={{position: 'relative'}}><UserAccount /></section>)}

    </section>
  );
}
