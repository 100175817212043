export const getLoading = state => state.reticles.loading;

export const getFetchReticlesLoading = state => state.reticles.fetchReticlesLoading;

export const getError = state => state.reticles.error;

export const getFetchError = state => state.reticles.fetchError;

export const getAllUserReticles = state => state.reticles.userReticles.items;

export const userReticlesCount = state => state.reticles.userReticles.count;

export const getSkipUserReticles = state => state.reticles.userReticles.skip;

export const getFetchingUserReticles = state => state.reticles.userReticles.isFetching;

export const getIsSavingId = state => state.reticles.currentReticleData.id;

export const getCurrentReticleStatus = state => state.reticles.currentReticleData.currentReticleStatus;

export const getCurrentReticlePublicId = state => state.reticles.currentReticleData.publicOpenId;

export const getCurrentReticleUserEmail = state => state.reticles.currentReticleData.reticleUserEmail;


export const getAllPublicReticles = state => state.reticles.publicReticles.items;

export const getSkipPublicReticles = state => state.reticles.publicReticles.skip;

export const getFetchingPublicReticles = state => state.reticles.publicReticles.isFetching;


export const openReticleInfo = state => state.reticles.openReticleInfo;

export const openReticleInfoStatus = state => state.reticles.openReticleInfo.status;

export const openReticleInfoName = state => state.reticles.openReticleInfo.name;

export const openReticleInfoDescription = state => state.reticles.openReticleInfo.description;

export const openReticleInfoTags = state => state.reticles.openReticleInfo.tags;

export const openReticleInfoReason = state => state.reticles.openReticleInfo.reason;

export const savingPublicReticleInfoID = state => state.reticles.savingPublicReticleInfo.id;

export const savingPublicReticleInfoStatus = state => state.reticles.savingPublicReticleInfo.status;



export const getAllAdminReticles = state => state.reticles.adminReticles.items;

export const getSkipAdminReticles = state => state.reticles.adminReticles.skip;

export const getFetchingAdminReticles = state => state.reticles.adminReticles.isFetching;


export const getAllPublicFolders = state => state.reticles.publicFolders.folders;

export const publishError = state => state.reticles.publishError;

export const getDownloadReticle = state => state.reticles.downloadReticle.reticle;
