const editPolygonCoords = (poly, x, y, size, step, direction, markerClass) => {
    let triangleCoords = [...poly];

    switch(direction) {
        case 'top':
            if (size === 'left') {
                if (markerClass) {
                    if (y >= (triangleCoords[0][1] + step) && x <= (triangleCoords[1][0] - step * 2)) {
                        triangleCoords[1][1] =  y;
                        triangleCoords[2][1] =  y;
                        triangleCoords[2][0] =  x;
                        let moveStep = triangleCoords[0][0] - triangleCoords[2][0];
            
                        triangleCoords[1][0] = (triangleCoords[0][0] + moveStep);
                    }
                } else {
                    if (y <= (triangleCoords[2][1] - step) && x <= (triangleCoords[1][0] - step * 2)) {
                        triangleCoords[0][1] =  y;
                        triangleCoords[2][0] =  x;
                        let moveStep = triangleCoords[0][0] - triangleCoords[2][0];
            
                        triangleCoords[1][0] = (triangleCoords[0][0] + moveStep);
                    }
                }
            }

            if (size === 'right') {
                if (markerClass) {
                    if (y <= (triangleCoords[1][1] - step) && x >= (triangleCoords[2][0] + step * 2)) {
                        triangleCoords[0][1] =  y;
                        triangleCoords[1][0] =  x;
                        let moveStep = triangleCoords[1][0] - triangleCoords[0][0];
            
                        triangleCoords[2][0] = (triangleCoords[0][0] - moveStep);
                    }
                } else {
                    if (y >= (triangleCoords[0][1] + step) && x >= (triangleCoords[2][0] + step * 2)) {
                        triangleCoords[1][1] =  y;
                        triangleCoords[2][1] =  y;
                        triangleCoords[1][0] =  x;
                        let moveStep = triangleCoords[1][0] - triangleCoords[0][0];
            
                        triangleCoords[2][0] = (triangleCoords[0][0] - moveStep);
                    }
                }
            }

            break;
        case 'bottom':
            if (size === 'left') {
                if (markerClass) {
                    if (y >= (triangleCoords[1][1] + step) && x <= (triangleCoords[2][0] - step * 2)) {
                        triangleCoords[0][1] =  y;
                        triangleCoords[1][0] =  x;
                        let moveStep = triangleCoords[0][0] - triangleCoords[1][0];
            
                        triangleCoords[2][0] = (triangleCoords[0][0] + moveStep);
                    }
                } else {
                    if (y <= (triangleCoords[0][1] - step) && x <= (triangleCoords[2][0] - step * 2)) {
                        triangleCoords[1][1] =  y;
                        triangleCoords[2][1] =  y;
                        triangleCoords[1][0] =  x;
                        let moveStep = triangleCoords[0][0] - triangleCoords[1][0];
            
                        triangleCoords[2][0] = (triangleCoords[0][0] + moveStep);
                    }
                }
            }

            if (size === 'right') {
                if (markerClass) {
                    if (y <= (triangleCoords[0][1] - step) && x >= (triangleCoords[1][0] + step * 2)) {
                        triangleCoords[1][1] =  y;
                        triangleCoords[2][1] =  y;
                        triangleCoords[2][0] =  x;
                        let moveStep = triangleCoords[2][0] - triangleCoords[0][0];
            
                        triangleCoords[1][0] = (triangleCoords[0][0] - moveStep);
                    }
                } else {
                    if (y >= (triangleCoords[2][1] + step) && x >= (triangleCoords[1][0] + step * 2)) {
                        triangleCoords[0][1] =  y;
                        triangleCoords[2][0] =  x;
                        let moveStep = triangleCoords[2][0] - triangleCoords[0][0];
            
                        triangleCoords[1][0] = (triangleCoords[0][0] - moveStep);
                    }
                }
            }

            break;
        case 'right':
            if (size === 'left') {
                if (markerClass) {
                    if (y >= (triangleCoords[2][1] + step * 2) && x <= (triangleCoords[0][0] - step)) {
                        triangleCoords[1][0] =  x;
                        triangleCoords[2][0] =  x;
                        triangleCoords[1][1] =  y;
                        let moveStep = triangleCoords[1][1] - triangleCoords[0][1];
            
                        triangleCoords[2][1] = (triangleCoords[0][1] - moveStep);
                    }
                } else {
                    if (y <= (triangleCoords[1][1] - step * 2) && x <= (triangleCoords[0][0] - step)) {
                        triangleCoords[1][0] =  x;
                        triangleCoords[2][0] =  x;
                        triangleCoords[2][1] =  y;
                        let moveStep = triangleCoords[0][1] - triangleCoords[2][1];
            
                        triangleCoords[1][1] = (triangleCoords[0][1] + moveStep);
                    }
                }
            }

            if (size === 'right') {
                if (markerClass) {
                    if (y <= (triangleCoords[1][1] - step * 2) && x >= (triangleCoords[2][0] + step)) {
                        triangleCoords[0][0] =  x;
                        triangleCoords[2][1] =  y;
                        let moveStep = triangleCoords[0][1] - triangleCoords[2][1];
            
                        triangleCoords[1][1] = (triangleCoords[0][1] + moveStep);
                    }
                } else {
                    if (y >= (triangleCoords[2][1] + step * 2) && x >= (triangleCoords[1][0] + step)) {
                        triangleCoords[0][0] =  x;
                        triangleCoords[1][1] =  y;
                        let moveStep = triangleCoords[1][1] - triangleCoords[0][1];
            
                        triangleCoords[2][1] = (triangleCoords[0][1] - moveStep);
                    }
                }
            }

            break;
        case 'left':
            if (size === 'left') {
                if (markerClass) {
                    if (y >= (triangleCoords[1][1] + step * 2) && x <= (triangleCoords[2][0] - step)) {
                        triangleCoords[0][0] =  x;
                        triangleCoords[2][1] =  y;
                        let moveStep = triangleCoords[2][1] - triangleCoords[0][1];
            
                        triangleCoords[1][1] = (triangleCoords[0][1] - moveStep);
                    }
                } else {
                    if (y <= (triangleCoords[2][1] - step * 2) && x <= (triangleCoords[1][0] - step)) {
                        triangleCoords[0][0] =  x;
                        triangleCoords[1][1] =  y;
                        let moveStep = triangleCoords[0][1] - triangleCoords[1][1];
            
                        triangleCoords[2][1] = (triangleCoords[0][1] + moveStep);
                    }
                }
            }

            if (size === 'right') {
                if (markerClass) {
                    if (y <= (triangleCoords[2][1] - step * 2) && x >= (triangleCoords[0][0] + step)) {
                        triangleCoords[1][0] =  x;
                        triangleCoords[2][0] =  x;
                        triangleCoords[1][1] =  y;
                        let moveStep = triangleCoords[0][1] - triangleCoords[1][1];
            
                        triangleCoords[2][1] = (triangleCoords[0][1] + moveStep);
                    }
                } else {
                    if (y >= (triangleCoords[1][1] + step * 2) && x >= (triangleCoords[0][0] + step)) {
                        triangleCoords[1][0] =  x;
                        triangleCoords[2][0] =  x;
                        triangleCoords[2][1] =  y;
                        let moveStep = triangleCoords[2][1] - triangleCoords[0][1];
            
                        triangleCoords[1][1] = (triangleCoords[0][1] - moveStep);
                    }
                }
            }

            break;
        default:
    }

    return triangleCoords;
}

export {editPolygonCoords}