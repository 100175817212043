import React from 'react';
import { useState, useEffect } from "react";
import './shapesButtons.scss';

export default function ShapesButtons({ setActiveShapeButton, activeLink, selectedShapeProps }) {
    const [active, setActive] = useState("");
    const [moreActive, setMoreActive] = useState("");
    let [selectedShape, setSelectedShape] = useState("");

    const handleClick = (event) => {
        if (active !== 'triangle' || active !== 'numbers') {
            setMoreActive('')
        }
        setActive(event.target.id);
    }

    const handleMoreButtonClick = (event) => {
        setMoreActive(event.target.id);
    }

    useEffect(() => {
        if (sessionStorage.getItem('activeTool')) {
            const shape = JSON.parse(sessionStorage.getItem('activeTool'));
            
            setActive(shape.name);
            setMoreActive(shape.direction);
            setActiveShapeButton(shape);
        }
    }, []);

    useEffect(() => {
        const shape = {
            name: active,
            direction: moreActive,
        }

        if (active) {
            let shapeStr = JSON.stringify(shape);
            sessionStorage.setItem('activeTool', shapeStr);
        }

        setActiveShapeButton(shape)
    }, [active, moreActive, setActiveShapeButton]);

    useEffect(() => {
        if (selectedShape !== selectedShapeProps) {
            setSelectedShape(selectedShapeProps);

            // if (active !== 'move') {
            //     setActive('move');
            //     setMoreActive('');
            // }
        }
    }, [selectedShape, active, selectedShapeProps])

    return (
        <section className='shapesButtonsSection' style={{ left: activeLink === '' ?  80 : 414.5 }}>
            <button key={1} id={"move"} className={`shapesButton buttonMove ${active === "move" ? "shapesButtonActive" : ''}`} onClick={handleClick}></button>
            <button key={2} id={"hand"} className={`shapesButton buttonHand ${active === "hand" ? "shapesButtonActive" : ''}`} onClick={handleClick}></button>
            <button key={3} id={"line"} className={`shapesButton buttonLine ${active === "line" ? "shapesButtonActive" : ''}`} onClick={handleClick}></button>
            <button key={4} id={"circle"} className={`shapesButton buttonCircle ${active === "circle" ? "shapesButtonActive" : ''}`} onClick={handleClick}></button>
            <button key={5} id={"square"} className={`shapesButton buttonRectangle ${active === "square" ? "shapesButtonActive" : ''}`} onClick={handleClick}></button>
            <button key={6} id={"triangle"} className={`shapesButton buttonPolygon buttonAfter ${active === "triangle" ? "shapesButtonActive buttonAfterActive" : ''}`} onClick={handleClick}></button>
            <button key={7} id={"rectangle"} className={`shapesButton buttonRectangle2 ${active === "rectangle" ? "shapesButtonActive" : ''}`} onClick={handleClick}></button>
            <button key={8} id={"number"} className={`shapesButton buttonIV buttonAfter ${active === "number" ? "shapesButtonActive buttonAfterActive" : ''}`} onClick={handleClick}></button>
            <button key={9} id={"arabic-number"} className={`shapesButton buttonArabicNumberFive buttonAfter ${active === "arabic-number" ? "shapesButtonActive buttonAfterActive" : ''}`} onClick={handleClick}></button>

            {/*
            {active === 'move'?
                (<div className='shapesMoreMoveButtons'>
                <button key={1} id={"moveArrow"} className={`shapesButton buttonMoveArrow ${moreActive === "moveArrow" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                <button key={2} id={"hand"} className={`shapesButton buttonHand ${moreActive === "hand" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                </div>) : null
            }
            */}

            {active === 'triangle'? 
                (<div className='shapesMoreTriangleButtons'>
                    <button key={1} id={"top"} className={`shapesButton buttonPolygonTop ${moreActive === "top" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={2} id={"right"} className={`shapesButton buttonPolygonRight ${moreActive === "right" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={3} id={"left"} className={`shapesButton buttonPolygonLeft ${moreActive === "left" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={4} id={"bottom"} className={`shapesButton buttonPolygonBottom ${moreActive === "bottom" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                </div>) : null}

            {active === 'number' ?
                (<div className='shapesMoreNumberButtons'>
                    <button key={1} id={"1"} className={`shapesButton buttonNumberOne ${moreActive === "1" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={2} id={"2"} className={`shapesButton buttonNumberTwo ${moreActive === "2" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={3} id={"3"} className={`shapesButton buttonNumberThree ${moreActive === "3" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={4} id={"4"} className={`shapesButton buttonNumberFour ${moreActive === "4" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={5} id={"5"} className={`shapesButton buttonNumberFive ${moreActive === "5" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={6} id={"6"} className={`shapesButton buttonNumberSix ${moreActive === "6" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={7} id={"7"} className={`shapesButton buttonNumberSeven ${moreActive === "7" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={8} id={"8"} className={`shapesButton buttonNumberEight ${moreActive === "8" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={9} id={"9"} className={`shapesButton buttonNumberNine ${moreActive === "9" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={10} id={"10"} className={`shapesButton buttonNumberTen ${moreActive === "10" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                </div>) : null}

            {active === 'arabic-number' ?
                (<div className='shapesMoreNumberButtons'>
                    <button key={1} id={"1"} className={`shapesButton buttonArabicNumberOne ${moreActive === "1" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={2} id={"2"} className={`shapesButton buttonArabicNumberTwo ${moreActive === "2" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={3} id={"3"} className={`shapesButton buttonArabicNumberThree ${moreActive === "3" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={4} id={"4"} className={`shapesButton buttonArabicNumberFour ${moreActive === "4" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={5} id={"5"} className={`shapesButton buttonArabicNumberFive ${moreActive === "5" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={6} id={"6"} className={`shapesButton buttonArabicNumberSix ${moreActive === "6" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={7} id={"7"} className={`shapesButton buttonArabicNumberSeven ${moreActive === "7" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={8} id={"8"} className={`shapesButton buttonArabicNumberEight ${moreActive === "8" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={9} id={"9"} className={`shapesButton buttonArabicNumberNine ${moreActive === "9" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                    <button key={0} id={"0"} className={`shapesButton buttonArabicNumberZero ${moreActive === "0" ? "shapesButtonActive" : ''}`} onClick={handleMoreButtonClick}></button>
                </div>) : null}
        </section>
    );
}
